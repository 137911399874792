import React, { useEffect } from 'react'

export default function Spinner(props) {
  
  return (
    <React.Fragment>
    <div className="simpleflex">
    <div className="spinner" style={{width:parseInt(props.size),height:parseInt(props.size),borderColor:props.borderColor,borderStyle: 'solid',borderWidth:4}}></div>
    </div>
    </React.Fragment>
  )
}
