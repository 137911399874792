import React, { useState, useEffect } from "react";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import app from "../../firebaseConfig";
import Navbar from "../Navbar";
import Footer from "../Footer";
import Alert from "../Alert";
import Spinner from "../Spinner";
import { Helmet } from "react-helmet";

export default function ForgotPassword() {
  const auth = getAuth(app);
  const context = useContext(AppContext);
  const { sendDataToApp, fpUser, setFpUser, alertValue, showAlert, logError } =
    context;
  const navigate = useNavigate();
  const [resendTime, setResendTime] = useState("30");
  const [validateCodeError, setValidateCodeError] = useState(false);
  const [verificatioAttempt, setVerificationAttempt] = useState(3);
  const [resendState, setResendState] = useState(false);
  const [fpLoader, setFPLoader] = useState(false);
  const [verificationLoader, setVerificationLoader] = useState(false);
  const [fpFields, setFPFields] = useState({
    mobile: "",
    verification: "",
  });
  const [fpError, setFPError] = useState({
    mobileErr: false,
    mobileLen: false,
    verification: false,
  });
  const [pageStage, setPageStage] = useState(1);
  const [fpConfirmRes, setFPConfirmRes] = useState(null);
  const [tempUser, setTempUser] = useState(null);
  // fp stage 1
  const onchange = (e) => {
    if (e.target.id == "mobile") {
      if (e.target.value.length <= 10) {
        setFPFields((stat) => {
          return { ...stat, mobile: e.target.value };
        });
      }
    } else {
      setFPFields((stat) => {
        return { ...stat, [e.target.id]: e.target.value };
      });
    }
  };
  const searchMobile = async () => {
    setFPLoader(true);
    setFPError({
      mobileLen: false,
      mobileErr: false,
      verification: false,
    });

    let mobileErr = false;

    // validating mobile field
    if (fpFields.mobile.length < 10 || fpFields.mobile.length > 10) {
      // enter a valid mobile
      setFPError((stat) => {
        return { ...stat, mobileLen: true };
      });
      mobileErr = true;
    } else {
      mobileErr = false;
    }

    if (!mobileErr) {
      let mobile = fpFields.mobile;

      try {
        let sqlQuery = `SELECT mobile,userid from users where mobile = ${mobile}`;
        let dbUrl = "https://expressv2.shortwash.com/getters/getsingle.php";
        let response = await fetch(dbUrl, {
          method: "POST",
          headers: {
            "Content-Type": "applicaiton/json",
          },
          body: JSON.stringify({
            query: sqlQuery,
          }),
        });
        let responseData = await response.json();

        if (responseData.response == "success") {
          setTempUser(responseData.data);
          setPageStage("loading");
          signInWithPhoneNumber(auth, `+91${mobile}`, window.recaptchaVerifier)
            .then((confirmationResult) => {
              setFPConfirmRes(confirmationResult);
              setTimeout(() => {
                showAlert(
                  "green",
                  `SMS sent successfully on +91${mobile}`,
                  3000
                );
                setPageStage(2);
                setFPLoader(false);
              }, 1000);
            })
            .catch((error) => {
              showAlert(
                "red",
                `Something went wrong while sending SMS on +91${mobile}, please try again`,
                5000
              );
              console.log("sms not sent - " + JSON.stringify(error.message));
              logError(error, {
                cause:
                  "searchmobile function signinwithphonenumber - forgotpassword web",
                data: fpFields.mobile,
              });
              pageReset();
              // we had to reset recaptcha to use it again
            });
        } else {
          setTempUser(null);
          setFPError((stats) => {
            return { ...stats, mobileErr: true };
          });
        }
      } catch (error) {
        logError(error, {
          cause: "searchmobile function trycatch  - forgotpassword web",
          data: fpFields.mobile,
        });
      }
    }
    setFPLoader(false);
  };
  const pageReset = async () => {
    // setting confirm response to null
    setFPConfirmRes(null);
    // getting back to stage 1
    setPageStage(1);
    // resetting fields from stage 1
    setFPFields({
      mobile: "",
      verification: "",
    });
    setFPError({
      mobileLen: false,
      mobileErr: false,
      verification: false,
    });
    window.recaptchaVerifier.render().then(function (widgetId) {
      window.grecaptcha.reset(widgetId);
    });
  };

  // verification stage 2
  const cancelValidation = async () => {
    setPageStage("loading");
    restartTimer();
    setVerificationAttempt(3);
    setTimeout(() => {
      pageReset();
    }, 1000);
  };
  const restartTimer = () => {
    setResendTime("30");
    setResendState(false);
  };
  const validateCode = async () => {
    try {
      let res = await fpConfirmRes.confirm(fpFields.verification);
      return res;
    } catch (error) {
      if (error.message.includes("auth/code-expired")) {
        setValidateCodeError(true);
        cancelValidation();
        showAlert("red", "Out of attempts, try again later", 3000);
      }
      logError(error, { cause: "validatecode function - forgotpassword web" });
      return null;
    }
  };
  const validateFunc = async () => {
    setVerificationLoader(true);
    setFPError((stats) => {
      return { ...stats, verification: false };
    });

    let verificationErr = false;

    // validating verification field
    if (fpFields.verification.length < 6) {
      // enter a valid name
      setFPError((stat) => {
        return { ...stat, verification: true };
      });
      verificationErr = true;
    } else {
      verificationErr = false;
    }
    if (!verificationErr && verificatioAttempt != 0) {
      setVerificationAttempt((stat) => {
        return stat - 1;
      });
      let userCred = await validateCode();

      if (userCred) {
        setPageStage("loading");
        setFpUser(tempUser);
        setTimeout(() => {
          navigate("/auth/newpassword");
          setPageStage(2);
        }, 1500);
        console.log("code verified");
      } else {
        setFpUser(null);
        setFPError((stat) => {
          return { ...stat, verification: true };
        });
      }
    }
    setVerificationLoader(false);
  };
  const resendOTP = async () => {
    setVerificationLoader(true);

    window.recaptchaVerifier.render().then(function (widgetId) {
      window.grecaptcha.reset(widgetId);
    });
    signInWithPhoneNumber(
      auth,
      `+91${fpFields.mobile}`,
      window.recaptchaVerifier
    )
      .then((confirmationResult) => {
        setFPConfirmRes(confirmationResult);
        setVerificationLoader(false);
        restartTimer();
        showAlert(
          "green",
          `SMS sent successfully on +91${fpFields.mobile}`,
          5000
        );
        setVerificationAttempt(3);
      })
      .catch((error) => {
        restartTimer();
        showAlert(
          "red",
          `Something went wrong while sending SMS on +91${fpFields.mobile}, resend again`,
          5000
        );
        setVerificationLoader(false);
        logError(error, {
          data: fpFields.mobile,
          cause:
            "resendotp functions signinwithphonenumber - forgotpassword web",
        });
      });
  };

  useEffect(() => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "forgot-password-recaptcha",
        {
          size: "invisible",
          callback: (response) => {},
          "expired-callback": (res) => {
            showAlert(
              "red",
              "This is a development error, complaint it at complaint@shortwash.com or restart the app",
              5000
            );
            console.log("recaptch expired");
          },
        },
        auth
      );
    } catch (error) {
      showAlert(
        "red",
        "This is a development error, complaint it at complaint@shortwash.com or restart the app",
        3000
      );
      console.log(JSON.stringify(error));
      logError(error, {
        cause: "useEffect recaptcha trycatch - forgotpassword web",
      });
      // console.log('recaptch error - ' + JSON.stringify(error.message));
    }
  }, []);
  // verification useeffects
  useEffect(() => {
    if (!resendState) {
      const timer = setInterval(() => {
        if (resendTime <= 10) {
          setResendTime((stat) => {
            return `0${stat - 1}`;
          });
        } else {
          setResendTime((stat) => {
            return stat - 1;
          });
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [resendTime]);
  useEffect(() => {
    if (resendTime <= 0) {
      setResendState(true);
    }
  }, [resendTime]);
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <Helmet>
          <title>Shortwash - Forgot your password</title>
        </Helmet>
        <Navbar page="auth" />
        {alertValue && (
          <Alert theme={alertValue.theme} message={alertValue.message} />
        )}
        <div className="forgot-wrapper">
          {/*forgot password screen*/}
          {pageStage == 1 && (
            <div
              className="container simpleflex forgot-inner-wrapper"
              style={{ flexDirection: "column" }}
            >
              <div className="heading-container simpleflex w-100">
                <span
                  className="display-3"
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                    fontSize: 32,
                    marginBottom: 30,
                    fontWeight: "300",
                  }}
                >
                  Forgot password
                </span>
              </div>
              <div style={{ position: "relative" }} className="w-75">
                <span
                  style={{
                    fontSize: 15,
                    color: "grey",
                    position: "absolute",
                    backgroundColor: "white",
                    paddingLeft: 5,
                    paddingRight: 5,
                    zIndex: 1,
                    left: 12,
                    top: -10,
                    color:
                      fpError.mobileErr || fpError.mobileLen ? "red" : "grey",
                  }}
                >
                  Mobile
                </span>
                <input
                  style={{
                    borderColor: "rgb(225,225,225)",
                    borderWidth: 1,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 15,
                    paddingBottom: 15,
                    color: "black",
                    fontSize: 16,
                    borderColor:
                      fpError.mobileErr || fpError.mobileLen
                        ? "red"
                        : "rgb(225,225,225)",
                    width: "100%",
                  }}
                  placeholder="Your mobile number"
                  value={fpFields.mobile}
                  id="mobile"
                  type="number"
                  className="form-control"
                  onChange={onchange}
                />
              </div>
              {/* errors*/}
              <div
                style={{
                  visibility:
                    fpError.mobileLen || fpError.mobileErr
                      ? "visible"
                      : "hidden",
                  flexDirection: "row",
                  color: "red",
                  marginTop: 5,
                  alignItems: "center",
                }}
                className="d-flex"
              >
                <img
                  style={{
                    height: 12,
                    width: 12,
                    marginRight: 5,
                    marginTop: 5,
                    marginBottom: 4,
                  }}
                  src={require("../../assets/signup/info_red.png")}
                />
                {fpError.mobileLen && (
                  <span style={{ color: "red", fontSize: 14 }}>
                    Mobile number should be of 10 digits
                  </span>
                )}
                {fpError.mobileErr && (
                  <span style={{ color: "red", fontSize: 14 }}>
                    No user found, you should signup
                  </span>
                )}
              </div>

              <div className="btn-container w-75" style={{ marginTop: 30 }}>
                <button
                  className="btn w-100"
                  style={{ backgroundColor: "red", color: "white" }}
                  onClick={searchMobile}
                >
                  {!fpLoader ? (
                    "Proceed"
                  ) : (
                    <Spinner borderColor="#ffffff" size="24px" />
                  )}
                </button>
                <div
                  className="text-center"
                  style={{ marginTop: 15, marginBottom: 15 }}
                >
                  <span className="text-muted">or</span>
                </div>
                <button
                  className="btn btn-outline-dark w-100"
                  onClick={() => {
                    navigate("/auth/login");
                  }}
                >
                  Login
                </button>
              </div>
            </div>
          )}

          {/*loading screen*/}
          {pageStage == "loading" && (
            <div className="simpleflex forgot-loading-screen">
              <Spinner borderColor="#000000" size="28px" />
            </div>
          )}

          {/*verification screen*/}
          {pageStage == 2 && (
            <div
              className="container simpleflex forgot-verification"
              style={{ flexDirection: "column" }}
            >
              <div className="text-center" style={{ marginBottom: 25 }}>
                <span style={{ fontSize: 23 }}>Enter verification code</span>
              </div>
              <div
                style={{ position: "relative", marginTop: 20 }}
                className="w-75"
              >
                <span
                  style={{
                    fontSize: 15,
                    color: fpError.verification ? "red" : "grey",
                    position: "absolute",
                    backgroundColor: "white",
                    paddingLeft: 5,
                    paddingRight: 5,
                    zIndex: 1,
                    left: 12,
                    top: -4,
                  }}
                >
                  Verification Code
                </span>
                <input
                  style={{
                    borderColor: "rgb(225,225,225)",
                    borderWidth: 1,
                    marginTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 15,
                    paddingBottom: 15,
                    width: "100%",
                    color: "black",
                    fontSize: 16,
                    borderColor: fpError.verification
                      ? "red"
                      : "rgb(225,225,225)",
                  }}
                  placeholder="One time password"
                  className="form-control"
                  type="number"
                  id="verification"
                  value={fpFields.verification}
                  onChange={onchange}
                />
                <div
                  style={{
                    visibility: fpError.verification ? "visible" : "hidden",
                    flexDirection: "row",
                    color: "red",
                    marginTop: 5,
                    alignItems: "center",
                  }}
                  className="d-flex"
                >
                  <img
                    style={{
                      height: 12,
                      width: 12,
                      marginRight: 5,
                    }}
                    src={require("../../assets/signup/info_red.png")}
                  />
                  <span style={{ color: "red", fontSize: 14 }}>
                    Verification OTP is invalid
                  </span>
                </div>
                {!resendState && (
                  <div
                    className="d-flex justify-content-end"
                    style={{ marginTop: 8 }}
                  >
                    <span style={{ fontSize: 14 }}>
                      resend otp in{" "}
                      <span style={{ color: "red" }}>00:{resendTime}</span>
                    </span>
                  </div>
                )}
                {resendState && (
                  <div
                    className="d-flex justify-content-end"
                    style={{ marginTop: 8 }}
                  >
                    <span style={{ fontSize: 14 }} onClick={resendOTP}>
                      resend otp?
                    </span>
                  </div>
                )}
              </div>
              {/*error*/}

              <div className="w-75" style={{ marginTop: 25 }}>
                <button
                  className="btn w-100"
                  style={{ backgroundColor: "red", color: "white" }}
                  onClick={validateFunc}
                >
                  {verificationLoader ? (
                    <Spinner borderColor="#ffffff" size="24px" />
                  ) : (
                    `Validate (${verificatioAttempt} Attempts)`
                  )}
                </button>
                <button
                  className="btn btn-outline-dark w-100 mt-3"
                  onClick={cancelValidation}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          <div id="forgot-password-recaptcha"></div>
        </div>
        <Footer/>
      </div>
    </React.Fragment>
  );
}
