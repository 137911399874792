import React, { useEffect, useState } from 'react'


export default function OrderStage({order}) {
    const [stage,setStage] = useState(0);
    useEffect(()=>{
        if (order.status == 'pickup') {
            setStage(0);
        }
        if (order.status == 'queue') {
            setStage(25);
        }
        if (order.status == 'washing') {
            setStage(50);
        }
        if (order.status == 'delivery') {
            setStage(75);
        }
        if (order.status == 'delivered') {
            setStage(100);
        }
    },[order])
    return (
    <div style={{ marginTop:20,marginBottom:20,paddingLeft:15,paddingRight:15 }}>
        
        
        <div style={{ position: "relative" }}>
        <div style={{ flexDirection: "row", justifyContent: "space-between",display:'flex' }}>
          <div
            style={{
              height: 40,
              width: 40,
              borderColor: "#e1e1e1",
              borderWidth: 1,
              borderStyle:"solid",
              borderRadius: 100,
              justifyContent: "center",
              alignItems: "center",
              zIndex:2,
              backgroundColor: order.status == 'pickup' ? "white":"#e1e1e1",
            }}
            className="simpleflex"
          >
            <img
              src={order.status == 'pickup' ? require("../../../assets/orders/pickup.png"): require("../../../assets/orders/pickup_white.png")}
            />
          </div>
          <div
            style={{
              height: 40,
              width: 40,
              borderColor: "#E1E1E1",
              borderStyle:"solid",
              borderWidth: 1,
              borderRadius: 100,
              zIndex:2,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: order.status == 'queue' ?'white' : "#E1E1E1",
            }}
            className="simpleflex"

          >
            <img
              src={order.status == 'queue' ? require("../../../assets/orders/queue.png"): require("../../../assets/orders/queue_white.png")}
            />
          </div>
          <div
            style={{
              height: 40,
              width: 40,
              borderColor: "#E1E1E1",
              borderStyle:"solid",
              borderWidth: 1,
              zIndex:2,
              borderRadius: 100,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: order.status == 'washing' ? 'white' : "#E1E1E1",
            }}
            className="simpleflex"

          >
            <img
              src={order.status == 'washing' ? require("../../../assets/orders/washing.png"): require("../../../assets/orders/washing_white.png")}
            />
          </div>
          <div
            style={{
              height: 40,
              width: 40,
              borderColor: "#E1E1E1",
              borderStyle:"solid",
              borderWidth: 1,
              zIndex:2,
              borderRadius: 100,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: order.status == 'delivery' ? 'white' : "#E1E1E1",
            }}
            className="simpleflex"

          >
            <img
              src={order.status == 'delivery' ? require("../../../assets/orders/delivery.png") : require("../../../assets/orders/delivery_white.png")}
            />
          </div>
          <div
            style={{
              height: 40,
              width: 40,
              borderColor: "#E1E1E1",
              borderStyle:"solid",
              zIndex:2,
              borderWidth: 1,
              borderRadius: 100,
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: order.status == 'delivered' ? "green" : "#e1e1e1",
            }}
            className="simpleflex"

          >
            <img
              src={require("../../../assets/orders/delivered_white.png")}
            />
          </div>
        </div>
        <div
          style={{
            height: 10,
            width: "100%",
            borderColor: "#E1E1E1",
            borderStyle:"solid",
            borderWidth: 1,
            position: "absolute",
            top: 15,
            zIndex: 0,
          }}
        >
          <div
            style={{
              backgroundColor: "#E1E1E1",
              height: "100%",
              width: `${stage}%`,
            }}
          ></div>
        </div>
      </div>

        <div
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            display:'flex'
          }}
        >
          <div style={{alignItems: "center",visibility : order.status == "pickup" ? 'visible' : 'hidden',width:40 }} className="simpleflex">
            <span style={{ fontSize: 10 }} className=" h-100 simpleflex">Pickup Pending</span>
          </div>
          <div style={{alignItems: "center",visibility : order.status == "queue" ? 'visible' : 'hidden',width:40 }} className="simpleflex">
            <span style={{ fontSize: 10 }} className=" h-100 simpleflex">In-Queue</span>
          </div>
          <div style={{alignItems: "center",visibility : order.status == "washing" ? 'visible' : 'hidden',width:40 }} className="simpleflex">
            <span style={{ fontSize: 10 }} className=" h-100 simpleflex">Washing/Drying</span>
          </div>
          <div style={{alignItems: "center",visibility : order.status == "delivery" ? 'visible' : 'hidden',width:40 }} className="simpleflex">
            <span style={{ fontSize: 10 }} className=" h-100 simpleflex">Out for delivery</span>
          </div>
          <div style={{alignItems: "center",visibility : order.status == "delivered" ? 'visible' : 'hidden',width:40 }} className="simpleflex">
            <span style={{ fontSize: 10 }} className=" h-100 simpleflex">Delivered</span>
          </div>
        </div>
      </div>
  );
}
