import React, { useEffect, useRef, useState } from "react";
import { isAndroid, isIOS, isWindows } from "react-device-detect";
import Navbar from "./Navbar";
import Spinner from "./Spinner";

export default function DownloadOurApplication(props) {
  const [platform, setPlatform] = useState(null);
  const [androidLink, setAndroidLink] = useState(
    "https://play.google.com/store/apps/details?id=com.devilx7.shortwash"
  );
  const [webLink, setWebLink] = useState("https://shortwash.com");
  const [iosLink, setIOSLink] = useState(
    "https://apps.apple.com/in/app/shortwash-laundry-wale/id6446392272"
  );
  const [pageError, setPageError] = useState(null);
  const [successRefer, setSuccessRefer] = useState(false);
  const invisibleAndroidLink = useRef();

  useEffect(() => {
    if (isAndroid) {
      setPlatform("android");
      invisibleAndroidLink.current.click();
      setSuccessRefer(true);
      return;
    }
    if (isWindows) {
      setPlatform("web");
      window.location.href = `${webLink}`;
      setSuccessRefer(true);
      return;
    }
    if (isIOS) {
      setPlatform("ios");
      setTimeout(function () {
        document.location.href = `${iosLink}`;
        setSuccessRefer(true);
      }, 500);
      return;
    }
    setPageError(true);
  }, []);
  // hello buddy whatsapp
  return (
    <React.Fragment>
      <div style={{ width: "100%", height: "100%" }}>
        <Navbar />
        <div
          style={{
            backgroundColor: "white",
          }}
        >
          {!pageError && !successRefer && (
            <div
              className="simpleflex"
              style={{ height: "calc(100vh - 86px)" }}
            >
              <Spinner borderColor="#000000" size="28px" />
            </div>
          )}

          {pageError && (
            <div
              className="d-flex justify-content-center align-items-center "
              style={{ height: "calc(100vh - 86px)", flexDirection: "column" }}
            >
              <span className="text-center">
                Something went wrong while redirecting you to Application Store
              </span>
              <span className="text-center">
                Just click below according to your platform
              </span>
              <span style={{ marginTop: 5 }}>
                Android -{" "}
                <a
                  style={{ color: "#007bff", cursor: "pointer" }}
                  href={androidLink}
                >
                  Google Play Store
                </a>
              </span>
              <span style={{ marginTop: 5 }}>
                iOS -{" "}
                <span
                  style={{ color: "#007bff", cursor: "pointer" }}
                  onClick={() => {
                    setTimeout(() => {
                      document.location.href = `${iosLink}`;
                    }, 500);
                  }}
                >
                  Apple App Store
                </span>
              </span>
              <span style={{ marginTop: 5 }}>
                Website -{" "}
                <span
                  style={{ color: "#007bff", cursor: "pointer" }}
                  onClick={() => {
                    window.location.href = `${webLink}`;
                  }}
                >
                  Website
                </span>
              </span>
            </div>
          )}
          {successRefer && !pageError && (
            <div
              style={{ height: "calc(100vh - 86px)", flexDirection: "column" }}
              className="simpleflex px-2"
            >
              <span className="display-3" style={{ textAlign: "center" }}>
                Thank you for downloading Shortwash
              </span>
              <span style={{ marginTop: 10, textAlign: "center" }}>
                if you didn't able to get download link,{" "}
                <span
                  style={{
                    textDecoration: "none",
                    color: "#007bff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setPageError(true);
                  }}
                >
                  click here
                </span>
              </span>
            </div>
          )}
          <div className="invisible-box" style={{ visibility: "hidden" }}>
            <a href={androidLink} ref={invisibleAndroidLink}></a>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
