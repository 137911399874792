import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AppContext from "../../context/AppContext";
import Alert from "../Alert"
import Spinner from "../Spinner"
import { Helmet } from "react-helmet";
export default function Login(props) {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const {
    loginUser,
    setUserData,
    userdata,
    showAlert,
    alertValue,
    logError
  } = context;
  // login's state
  let [loginUserDetails, setLoginUserDetails] = useState({
    mobile: "",
    password: "",
  });
  let [errorValue, setErrorsValue] = useState({
    mobileLen: false,
    mobileNum: false,
    passwordWrong: false,
  });
  let [loading,setLoading] = useState(false);
  // functions for login
  
  const onSubmit = async () => {
    setLoading(true);
    setErrorsValue(function (state) {
      return {
        ...state,
        mobileLen: false,
        mobileNum: false,
        passwordWrong: false,
      };
    });

    let mobileErr = false;
    let passwordErr = false;
    if (
      loginUserDetails.mobile.length > 10 ||
      loginUserDetails.mobile.length < 10
    ) {
      setErrorsValue(function (val) {
        return { ...val, mobileLen: true };
      });
      mobileErr = true;
    } else {
      setErrorsValue(function (val) {
        return { ...val, mobileLen: false };
      });
      mobileErr = false;
    }

    if (loginUserDetails.password.length < 6) {
      setErrorsValue(function (val) {
        return { ...val, passwordWrong: true };
      });
      passwordErr = true;
    } else {
      setErrorsValue(function (val) {
        return { ...val, passwordWrong: false };
      });
      passwordErr = false;
    }

    if (!mobileErr && !passwordErr) {
      const response = await loginUser(
        loginUserDetails.mobile,
        loginUserDetails.password
      );

      if (response.response == "success") {
        if (response.message == "Account loggin successfully") {
          try {
            let name = response.data.name;
            setUserData({
              loggedin: true,
              email: response.data.email,
              mobile: response.data.mobile,
              name: response.data.name,
              userid: response.data.userid
            });
            let jsonUserData = JSON.stringify({
              loggedin: true,
              email: response.data.email,
              mobile: response.data.mobile,
              name: response.data.name,
              userid: response.data.userid
            });
            window.sessionStorage.setItem('userdata',jsonUserData);
            showAlert(
              "green",
              `Welcome back ${name}, what would you like to do today?`,
              3000
            );
            navigate('/');
          } catch (error) {
            logError(error,{data:response.data,cause:"onsubmit function - login web"});
            showAlert(
              "light",
              "Something went wrong, please complaint at complaint@shortwash.com",
              3000
            );
          }
        }
        setLoginUserDetails({
          mobile: "",
          password: "",
        });
      }
      if (response.response == "error") {
        if (response.message == "user not found") {
          setErrorsValue((stat) => {
            return { ...stat, mobileNum: true };
          });
        }
        if (response.message == "wrong password") {
          setErrorsValue((stat) => {
            return { ...stat, passwordWrong: true };
          });
        }
      }
    }
    setLoading(false);
  };
  const onchange=(e)=>{
    if (e.target.id == 'mobile') {
      if (e.target.value.length <= 10) {
        setLoginUserDetails((stat)=>{
          return {...stat,[e.target.id] : e.target.value};
        })  
      }
    }else{
      setLoginUserDetails((stat)=>{
        return {...stat,[e.target.id] : e.target.value};
      })
    }
  }
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
      <Helmet>
      <title>Shortwash - Login in your account</title>
      </Helmet>
        <Navbar page="auth" />
        {alertValue && <Alert theme={alertValue.theme} message={alertValue.message}/>}
        <div className="login-wrapper d-flex justify-content-end">
        <div className="container login-inner-wrapper text-center" style={{paddingBottom:40}}>
          <div
            className="login-header simpleflex"
            style={{ flexDirection: "column" }}
          >
            <span
              style={{
                fontSize: 32,
                fontWeight: "300",
                textAlign: "center",
                color: "black",
                marginTop: 20,
              }}
            >
              Login to continue
            </span>
            <span style={{ marginTop: 5, color: "grey", textAlign: "center" }}>
              you can reach us anytime at help@shortwash.com
            </span>
          </div>
          <div className="loginbg"
            style={{
              width: "100%",
              alignItems: "center",
              justifyContent:'center',
              marginTop: 5,
              marginBottom: 5,
            }}
          >
            <img
              style={{ width: "70%", height: 200 }}
              src={require("../../assets/login/loginbg.png")}
            />
          </div>
          <div className="login-form-wrapper"
            style={{
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            <div style={{ position: "relative" }}>
              <label
                style={{
                  fontSize: 16,
                  position: "absolute",
                  backgroundColor: "white",
                  zIndex: 1,
                  top: -11,
                  left: 12,
                  paddingLeft:6,
                  paddingRight:6,
                  color:
                    errorValue.mobileLen || errorValue.mobileNum
                      ? "red"
                      : "grey",
                }}
              >
                Mobile Number
              </label>
              <input className="form-control login-mobile-inp" 
              style={{
                  borderColor:
                    errorValue.mobileLen || errorValue.mobileNum
                      ? "red"
                      : "rgb(225,225,225)",
                  borderWidth: 1,
                  paddingTop: 15,
                  paddingBottom: 15,
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: "black",
                  fontSize: 16,
                  borderRadius: 6,
                }}
                id="mobile"
                placeholder="Your mobile number here"
                type="number"
                value={loginUserDetails.mobile}
                onChange={onchange}
              />
            </div>
            {/*error*/}
            <div
              style={{
                color: "red",
                marginTop: 5,
                visibility: `${errorValue.mobileLen || errorValue.mobileNum ? "visible" : "hidden"}`,
                alignItems: "center",
                flexDirection: "row",
              }}
              className='d-flex'
            >
              <img
                style={{ height: 12, width: 12, marginRight: 5,marginTop:5,marginBottom:7 }}
                src={require("../../assets/login/info_red.png")}
              />
              {errorValue.mobileLen && <span style={{ color: "red" }}>Invalid mobile number</span>}
              {errorValue.mobileNum && <span style={{ color: "red" }}>User doesn't exists</span>}
              </div>

            <div style={{ position: "relative", marginTop: 25 }}>
              <label
                style={{
                  fontSize: 16,
                  position: "absolute",
                  backgroundColor: "white",
                  zIndex: 1,
                  top: -11,
                  left: 12,
                  paddingLeft:6,
                  paddingRight:6,
                  color: errorValue.passwordWrong ? "red" : "grey",
                }}
              >
                Password
              </label>
              <input className="form-control login-password-inp"
                style={{
                  borderColor: `${
                    errorValue.passwordWrong ? "red" : "rgb(225,225,225)"
                  }`,
                  borderWidth: 1,
                  paddingTop: 15,
                  paddingBottom: 15,
                  paddingLeft: 10,
                  paddingRight: 10,
                  color: "black",
                  fontSize: 16,
                  borderRadius: 6,
                }}
                id="password"
                placeholder="Your password here"
                type="password"
                value={loginUserDetails.password}
                onChange={onchange}
              />
            </div>

            {/*error*/}
            <div
              style={{
                marginTop: 5,
                visibility: `${errorValue.passwordWrong ? "visible" : "hidden"}`,
                alignItems: "center",
                flexDirection: "row",
              }}
              className="d-flex"
            >
              <img
                style={{ height: 12, width: 12, marginRight: 5 }}
                src={require("../../assets/login/info_red.png")}
              />
              <span style={{ color: "red" }}>Wrong password</span>
            </div>

            <div className="login-forgot-password-wrapper d-flex justify-content-end">
            <span
              style={{ textAlign: "right", color: "black", marginTop: 5,cursor:'pointer' }}
              onClick={() => {
                navigate("/auth/forgotpassword");
              }}
            >
              Forgot Password?
            </span>
            </div>
            
            <div className="login-btn-wrapper simpleflex" style={{flexDirection:'column'}}>
            <button
              style={{
                backgroundColor: "red",
                marginTop: 20,
                paddingVertical: 8,
                borderRadius: 5,
              }}
              className="btn w-100"
              onClick={() => {
                onSubmit();
              }}
            >
              <span
                style={{
                  color: "white",
                  textAlign: "center",
                  fontSize: 17,
                }}
              >
                {!loading ? 'Login' : <Spinner size='25' borderColor="#ffffff"/>}

              </span>
            </button>
            <span
              style={{
                color: "grey",
                textAlign: "center",
                marginTop: 15,
                marginBottom: 15,
              }}
            >
              or
            </span>
            <button
              style={{
                paddingVertical: 8,
                borderRadius: 5,
              }}
              className="btn btn-outline-dark w-100"
              onClick={() => {
                navigate("/auth/signup");
              }}
            >
              <span
                style={{
                  textAlign: "center",
                  fontSize: 17,
                }}
              >
                Create a account
              </span>
            </button>
            </div>
            
          </div>
        </div>
        </div>
        <Footer/>
      </div>
    </React.Fragment>
  );
}
