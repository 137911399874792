import React, { useState } from "react";
import app from "../../firebaseConfig";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useEffect } from "react";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Alert from "../Alert";
import Spinner from "../Spinner";
import { Helmet } from "react-helmet";

export default function Verification() {
  const auth = getAuth(app);
  const context = useContext(AppContext);
  const {
    setSignupFieldDetails,
    signupconfirmRes,
    setSignupConfirmRes,
    signupFieldDetails,
    signupErrorDetails,
    setSignupErrors,
    signupUser,
    showAlert,
    alertValue,
    logError,
  } = context;
  const [pageStage, setPageStage] = useState(1);
  const [validateCodeError, setValidateCodeError] = useState(false);
  const [verificatioAttempt, setVerificationAttempt] = useState(3);
  const [stageTwoLoader, setStageTwoLoader] = useState(false);
  const [resendTime, setResendTime] = useState("30");
  const [resendState, setResendState] = useState(false);
  const navigate = useNavigate();
  function onchange(e) {
    if (e.target.id == "mobile") {
      if (signupconfirmRes) {
        setSignupConfirmRes(null);
      }
      if (e.target.value.length <= 10) {
        setSignupFieldDetails({
          ...signupFieldDetails,
          [e.target.id]: e.target.value,
        });
      }
    } else {
      setSignupFieldDetails({
        ...signupFieldDetails,
        [e.target.id]: e.target.value,
      });
    }
  }

  const cancelValidation = async () => {
    setPageStage("loading");
    setSignupFieldDetails({
      name: "",
      mobile: "",
      email: "",
      password: "",
      referral: "",
      verification: "",
    });
    setSignupConfirmRes(null);
    setSignupErrors({
      password: false,
      name: false,
      mobileLen: false,
      mobileExists: false,
      email: false,
      verification: false,
    });
    setTimeout(() => {
      navigate("/auth/signup");
      setSignupFieldDetails({ ...signupFieldDetails, verification: "" });
      setPageStage(1);
    }, 1000);
  };
  const validateCode = async () => {
    try {
      let res = await signupconfirmRes.confirm(signupFieldDetails.verification);
      return res;
    } catch (error) {
      if (error.message.includes("auth/code-expired")) {
        setValidateCodeError(true);
        cancelValidation();
        showAlert("red", "Out of attempts, try again later", 3000);
      }
      logError(error, { cause: "validatecode function - verification web" });
      return null;
    }
  };

  const validateFunc = async () => {
    setStageTwoLoader(true);
    setSignupErrors((stats) => {
      return { ...stats, verification: false };
    });

    let verificationErr = false;

    // validating verification field
    if (signupFieldDetails.verification.length < 6) {
      // enter a valid name
      setSignupErrors((stat) => {
        return { ...stat, verification: true };
      });
      verificationErr = true;
    } else {
      verificationErr = false;
    }
    if (!verificationErr && verificatioAttempt != 0) {
      setVerificationAttempt((stat) => {
        return stat - 1;
      });
      let userCred = await validateCode();

      if (userCred) {
        let mobile = signupFieldDetails.mobile;
        let name = signupFieldDetails.name;
        let password = signupFieldDetails.password;
        let referral = signupFieldDetails.referral;
        let signupData = {};
        if (referral != "") {
          signupData = { name, mobile, password, referral };
        } else {
          signupData = { name, mobile, password };
        }

        const response = await signupUser(signupData);

        if (response.response == "success") {

 

          
          // referral transaction and wallet updation will goes here
          if (signupFieldDetails.referredMob) {
            let transUrl =
              "https://expressv2.shortwash.com/refer/referfriendbonus.php";
            let transResponse = await fetch(transUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                mob: signupFieldDetails.referredMob,
                newuser: mobile,
              }),
            });
            let transResponseData = await transResponse.json();
          } else if (referral != "") {
            let transUrl =
              "https://expressv2.shortwash.com/refer/referfriendbonus.php";
            let transResponse = await fetch(transUrl, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                mob: referral,
                newuser: mobile,
              }),
            });
            let transResponseData = await transResponse.json();
            console.log('referral');
          }




          showAlert(
            "green",
            `Congratulations ${name}, enjoy our premium laundry service`,
            5000
          );
          navigate("/auth/login");
          setSignupFieldDetails({ ...signupFieldDetails, verification: "" });
        }
        if (response.response == "error") {
          showAlert(
            "red",
            `Something went wrong while creating an account, complaint it at complaint@shortwash.com`,
            5000
          );
        }
      } else {
        setSignupErrors((stat) => {
          return { ...stat, verification: true };
        });
      }
    }
    setStageTwoLoader(false);
  };
  const resendOTP = async () => {
    setStageTwoLoader(true);

    window.recaptchaVerifier.render().then(function (widgetId) {
      window.grecaptcha.reset(widgetId);
    });
    signInWithPhoneNumber(
      auth,
      `+91${signupFieldDetails.mobile}`,
      window.recaptchaVerifier
    )
      .then((confirmationResult) => {
        setSignupConfirmRes(confirmationResult);
        setStageTwoLoader(false);
        restartTimer();
        showAlert(
          "green",
          `SMS sent successfully on +91${signupFieldDetails.mobile}`,
          3000
        );
        setVerificationAttempt(3);
      })
      .catch((error) => {
        restartTimer();
        showAlert(
          "red",
          `Something went wrong while sending SMS on +91${signupFieldDetails.mobile}, resend again`,
          3000
        );
        setStageTwoLoader(false);
        logError(error, {
          data: signupFieldDetails.mobile,
          cause: "resendotp function signinwithphonenumber - verification web",
        });
      });
  };
  useEffect(() => {
    if (!resendState) {
      const timer = setInterval(() => {
        if (resendTime <= 10) {
          setResendTime((stat) => {
            return `0${stat - 1}`;
          });
        } else {
          setResendTime((stat) => {
            return stat - 1;
          });
        }
      }, 1000);

      return () => clearInterval(timer);
    }
  }, [resendTime]);
  const restartTimer = () => {
    setResendTime("30");
    setResendState(false);
  };
  useEffect(() => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "resendotp",
        {
          size: "invisible",
          callback: (response) => {},
          "expired-callback": (res) => {
            showAlert("red", "Please refresh the page and try again", 3000);
          },
        },
        auth
      );
    } catch (error) {
      showAlert(
        "red",
        "This is a development error, complaint it at complaint@shortwash.com or restart the app",
        3000
      );
      logError(error, { cause: "useEffect recaptcha - verification web" });
    }
  }, []);

  useEffect(() => {
    if (!signupconfirmRes) {
      cancelValidation();
    }
  }, [signupconfirmRes]);
  useEffect(() => {
    if (resendTime <= 0) {
      setResendState(true);
    }
  }, [resendTime]);
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <Helmet>
          <title>Shortwash - Verification</title>
        </Helmet>
        <Navbar page="auth" />
        {alertValue && (
          <Alert theme={alertValue.theme} message={alertValue.message} />
        )}
        <div className="verification-outer-wrapper">
          {pageStage == 1 && (
            <div
              className="container simpleflex verification-wrapper"
              style={{ flexDirection: "column" }}
            >
              <div className="text-center" style={{ marginBottom: 25 }}>
                <span style={{ fontSize: 23 }}>Enter verification code</span>
              </div>
              <div
                style={{ position: "relative", marginTop: 20 }}
                className="w-75"
              >
                <span
                  style={{
                    fontSize: 15,
                    color: signupErrorDetails.verification ? "red" : "grey",
                    position: "absolute",
                    backgroundColor: "white",
                    paddingLeft: 5,
                    paddingRight: 5,
                    zIndex: 1,
                    left: 12,
                    top: -4,
                  }}
                >
                  Verification Code
                </span>
                <input
                  style={{
                    borderColor: "rgb(225,225,225)",
                    borderWidth: 1,
                    marginTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 15,
                    paddingBottom: 15,
                    width: "100%",
                    color: "black",
                    fontSize: 16,
                    borderColor: signupErrorDetails.verification
                      ? "red"
                      : "rgb(225,225,225)",
                  }}
                  placeholder="One time password"
                  className="form-control"
                  type="number"
                  id="verification"
                  value={signupFieldDetails.verification}
                  onChange={onchange}
                />
                <div
                  style={{
                    visibility: signupErrorDetails.verification
                      ? "visible"
                      : "hidden",
                    flexDirection: "row",
                    color: "red",
                    marginTop: 5,
                    alignItems: "center",
                  }}
                  className="d-flex"
                >
                  <img
                    style={{
                      height: 12,
                      width: 12,
                      marginRight: 5,
                    }}
                    src={require("../../assets/signup/info_red.png")}
                  />
                  <span style={{ color: "red", fontSize: 14 }}>
                    Verification OTP is invalid
                  </span>
                </div>
                {!resendState && (
                  <div
                    className="d-flex justify-content-end"
                    style={{ marginTop: 8 }}
                  >
                    <span style={{ fontSize: 14 }}>
                      resend otp in{" "}
                      <span style={{ color: "red" }}>00:{resendTime}</span>
                    </span>
                  </div>
                )}
                {resendState && (
                  <div
                    className="d-flex justify-content-end"
                    style={{ marginTop: 8 }}
                  >
                    <span style={{ fontSize: 14 }} onClick={resendOTP}>
                      resend otp?
                    </span>
                  </div>
                )}
              </div>
              {/*error*/}
              <div className="w-75" style={{ marginTop: 25 }}>
                <button
                  className="btn w-100"
                  style={{ backgroundColor: "red", color: "white" }}
                  onClick={validateFunc}
                >
                  {stageTwoLoader && (
                    <Spinner borderColor="#ffffff" size="24px" />
                  )}

                  {!stageTwoLoader &&
                    `Validate (${verificatioAttempt} Attempts)`}
                </button>
                <button
                  className="btn btn-outline-dark w-100 mt-3"
                  onClick={cancelValidation}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          {pageStage == "loading" && (
            <div className="simpleflex verification-loading-screen">
              <Spinner borderColor="#000000" size="28px" />
            </div>
          )}
          <div id="resendotp"></div>
        </div>
      </div>
    </React.Fragment>
  );
}
