import React,{useRef,useState} from 'react'
import { useContext } from 'react';
import AppContext from '../../context/AppContext';

export default function TimeslotAccordian() {
    const context = useContext(AppContext)
    const {timeslot,setTimeSlot} = context;
    const timeslot_btn = useRef();
    const timeslot_body = useRef();

    function timeSlotFunc(ts) {
        setTimeSlot(ts);
    
        timeslot_btn.current.classList.add("collapsed");
        timeslot_btn.current.setAttribute("aria-expanded", "false");
        timeslot_body.current.classList.remove("show");
      }
  return (
    <div className="accordion-item time-slot-accordion">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  ref={timeslot_btn}
                  style={{fontSize : 14}}
                >
                  Time Slot :
                  <span style={{ fontWeight: "600", marginLeft: 3,fontSize : 14 }}>
                    {timeslot ? timeslot : ""}
                  </span>
                  <span style={{ marginLeft: 3,fontSize : 14 }}>
                    {" "}
                    {timeslot ? "" : "Select a pickup time slot"}
                  </span>
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
                ref={timeslot_body}
              >
                <div className="accordion-body py-0">
                  <div>
                    <div className="simpleflex">

                    </div>
                    <div className="time-slot-wrapper d-flex py-3">
                      <div className="simpleflex" style={{ flex: 1 }}>
                        <span className="text-muted" style={{ fontSize: 14 }}>
                          Pickup slot -{" "}
                        </span>
                      </div>
                      <div style={{ flex: 1 }}>
                        <div
                          className={`time-slot border simpleflex py-1 my-1 ${
                            timeslot === "05:00 - 09:00 PM"
                              ? "border-primary"
                              : ""
                          }`}
                          onClick={() => {
                            timeSlotFunc("05:00 - 09:00 PM");
                          }}
                          style={{cursor : 'pointer',borderRadius:6}}  
                        >
                          <span style={{ fontSize: 14 }}>05:00 - 09:00 PM</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
  )
}
