import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import AppContext from "../../context/AppContext";
import Alert from "../Alert";
import Spinner from "../Spinner";
import TransactionItem from "./WalletComponent/TransactionItem";
import { Helmet } from "react-helmet";

export default function Wallet(props) {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const { ComponentCss, userdata, alertValue, getUser,showAlert,logError } = context;
  const [windowSize, setWindowSize] = useState({ size: {} });
  const [transactionHeight, setTransactionHeight] = useState(0);
  const [headingHeight, setHeadingHeight] = useState(0);
  const [walletHeight, setWalletHeight] = useState(0);
  const [pageLoading, setPageLoading] = useState(true);
  const [walletAmount, setWalletAmount] = useState(null);
  const [alltransactions, setAllTransactions] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [loadmoretransaction, setLoadmoretransaction] = useState(false);

  useEffect(() => {
    fetchUser();
    getTransactions();
    setPageNo(1);
  }, []);
  const fetchUser = async () => {
    setPageLoading(true);
    let url = "https://expressv2.shortwash.com/login/getuserdetails.php";
    try {
      if (userdata.userid) {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ token: userdata.userid }),
        });
        const responseData = await response.json();
        if (responseData.response == "success") {
          if (responseData.message == "User Data") {
            setWalletAmount(responseData.data.wallet);
          }
        }
      } else {
        let user = getUser();
        if (user) {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ token: user.userid }),
          });
          const responseData = await response.json();
          if (responseData.response == "success") {
            if (responseData.message == "User Data") {
              setWalletAmount(responseData.data.wallet);
            }
          }
        }
      }
    } catch (error) {
      logError(error,{data:userdata,cause:"fetchuser function - wallet web"});
      
    }
  };
   
  const getTransactions = async () => {
    const url = "https://expressv2.shortwash.com/transactions/gettransaction.php";
    try {
      if (userdata.loggedin) {
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ userid: userdata.userid }),
        });
        const responseData = await response.json();
  
        if (responseData.response == "success") {
          if (responseData.message == "Transactions fetched successfully") {
            setAllTransactions(responseData.data);
          }
        }
      } else {
        let user = getUser();
        if (user) {
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({ userid: user.userid }),
          });
          const responseData = await response.json();
  
          if (responseData.response == "success") {
            if (responseData.message == "Transactions fetched successfully") {
              setAllTransactions(responseData.data);
            }
          }
        }
      }
    } catch (error) {
      logError(error,{data:userdata,cause:"gettransactions function - wallet web"});
    }

    setPageLoading(false);
  };

  const fetchMoreTransaction = async () => {
    setLoadmoretransaction(true);
    try {
      if (userdata.userid) {
        const url =
          "https://expressv2.shortwash.com/transactions/getmoretransaction.php";
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userid: userdata.userid,
            offset: pageNo * 10,
          }),
        });
        const responseData = await response.json();
        if (responseData.response == "success") {
          if (responseData.data.length > 0) {
            setAllTransactions(alltransactions.concat(responseData.data));
            setPageNo(pageNo + 1);
          }
        }
      } else {
        let user = getUser();
        if (user) {
          const url =
            "https://expressv2.shortwash.com/transactions/getmoretransaction.php";
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userid: user.userid,
              offset: pageNo * 10,
            }),
          });
          const responseData = await response.json();
          if (responseData.response == "success") {
            if (responseData.data.length > 0) {
              setAllTransactions(alltransactions.concat(responseData.data));
              setPageNo(pageNo + 1);
            }
          }
        }
      }
    } catch (error) {
      logError(error,{userdata,pageNo,cause:"fetchmoretransaction function - wallet web"})
    }
    setLoadmoretransaction(false);
  };
  useEffect(()=>{
    if (!userdata.loggedin) {
      let getUserData = getUser();
      if (!getUserData) {
        setPageLoading(true);
        setTimeout(() => {
          navigate('/auth/login');
        }, 3000);
      }
    }
  },[userdata])
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
      <Helmet>
      <title>Shortwash - Wallet</title>
      </Helmet>
        <Navbar page="auth" />
        {alertValue && (
          <Alert theme={alertValue.theme} message={alertValue.message} />
        )}
        <div className="simpleflex">
          <div className="wallet-outer-wrapper" style={{minheight: "calc(100vh - 86px)"}}>
          {pageLoading && (
            <div
              className="simpleflex"
              style={{ height: "calc(100vh - 86px)" }}
            >
              <Spinner borderColor="#000000" size="28px" />
            </div>
          )}
          {!pageLoading && <div
            style={{
              backgroundColor: "white",
            }}
          >
            
            
            <div>
              <div
                style={{
                  fontSize: 32,
                  fontWeight: "300",
                  textAlign: "center",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                Wallet
              </div>
            </div>

            <div className="amount-container" style={{ width: "100%" }}>
              <div
                style={{
                  borderRadius: 15,
                  backgroundColor: "black",
                  width: "94%",
                  height: 100,
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection:'column',
                  display: "flex",
                  marginLeft: "3%",
                  marginRight: "3%",
                }}
              >
                <div style={{ color: "white", fontSize: 15 }}>
                  Total Balance
                </div>
                <div style={{ color: "white", fontSize: 18, marginTop: 5 }}>
                  {" "}
                  &#8377; {walletAmount ? walletAmount : 0}
                </div>
              </div>
            </div>

            <div
              className="transaction-outer-container"
              style={{ marginTop: 20,paddingBottom:40 }}
            >
              <div
                style={{
                  borderRadius: 15,
                  width: "94%",
                  marginLeft: "3%",
                  marginRight: "3%",
                }}
              >
                <div className="transactionHeading" style={{ padding: 10 }}>
                  <div style={{ fontWeight: "500", fontSize: 18 }}>
                    Recent Transactions
                  </div>
                </div>
                <div className="transaction-container" style={{ padding: 10 }}>
                  {alltransactions.map((tem) => {
                    return <TransactionItem item={tem} key={tem.tid} />;
                  })}
                  {alltransactions.length == 0 && !pageLoading && (
                    <div>
                      <div style={{ textAlign: "center" }}>No transactions</div>
                    </div>
                  )}
                  {alltransactions.length != 0 &&
                    alltransactions.length > 10 && (
                      <div
                        style={{
                          justifyContent: "center",
                          alignItems: "center",
                          width: "100%",
                          display: "flex",
                          marginTop: 5,
                          marginBottom: 5,
                          paddingTop: 15,
                          paddingBottom: 15,
                        }}
                        onClick={() => {
                          fetchMoreTransaction();
                        }}
                      >
                        {loadmoretransaction && (
                          <div
                            className="simpleflex py-2"
                            style={{ height: "calc(100vh - 125px)" }}
                          >
                            <Spinner borderColor="#000000" size="28px" />
                          </div>
                        )}
                        <div
                          style={{
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                            display: "flex",
                          }}
                        >
                          <div>Load more transactions</div>
                          <img
                            style={{ width: 16, height: 16 }}
                            src={require("../../assets/wallet/next.png")}
                          />
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>}
          </div>
        </div>

      </div>
    </React.Fragment>
  );
}
