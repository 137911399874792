import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import AppContext from "../../context/AppContext";
import Alert from "../Alert";
import Spinner from "../Spinner";
import MainAddress from "./AddressComponent/MainAddress";
import { Helmet } from "react-helmet";

export default function Address(props) {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const [refreshing, setRefreshing] = useState(false);
  const [navLoader, setNavLoader] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const { allAddress, showAlert, alertValue,userdata,getUser,setAllAddress,logError } = context;

  useEffect(() => {
    fetchAddress();
  }, []);
  
  const deleteAddress = async(data)=>{
    setPageLoading(true);
    try {
      if (userdata.userid) {
        let url = "https://expressv2.shortwash.com/address/deleteaddress.php";
        let reqData = {userid : userdata.userid,addressid : data};
  
        const response = await fetch(url,{
          method : "POST",
          headers : {
            'Content-Type' : 'application/json',
          },
          body : JSON.stringify(reqData)
        });
  
        const responseData = await response.json();
        if(responseData.response == 'success'){
          showAlert('green','Address deleted successfully',5000);
          fetchAddress(); 
        }
        if(responseData.response == 'error'){
          showAlert('red','Something went wrong while deleting address',5000);
          fetchAddress();
        }
      }else{
        let user = getUser();
        if (user) {
          let url = "https://expressv2.shortwash.com/address/deleteaddress.php";
          let reqData = {userid : user.userid,addressid : data};
  
          const response = await fetch(url,{
            method : "POST",
            headers : {
              'Content-Type' : 'application/json',
            },
            body : JSON.stringify(reqData)
          });
  
          const responseData = await response.json();
          if(responseData.response == 'success'){
            showAlert('green','Address deleted successfully',5000);
            fetchAddress();
          }
          if(responseData.response == 'error'){
            showAlert('red','Something went wrong while deleting address',5000);
            fetchAddress();
          }
        }
      }
    } catch (error) {
      logError(error,{cause:"deleteaddress functions - address web",userdata});
    }
    setPageLoading(false);
  }
  const getAllAddress = async () => {
    try {
      if (userdata.loggedin !== false) {
        if (userdata.userid) {
          let url = "https://expressv2.shortwash.com/address/getaddress.php";
          let reqData = { userid: userdata.userid };
  
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(reqData),
          });
          const responseData = await response.json();
          return responseData;
        }
      }else{
        let user = getUser();
        if (user) {
            let url = "https://expressv2.shortwash.com/address/getaddress.php";
            let reqData = { userid: user.userid };
    
            const response = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(reqData),
            });
            const responseData = await response.json();
            return responseData;
        }else{
          setAllAddress([]);
        }
      }
    } catch (error) {
      logError(error,{cause:"getalladdress function - address web",userdata});
    }
  };
  const fetchAddress = async () => {
    setPageLoading(true);
    if (userdata.loggedin !== false) {
      let reqData = await getAllAddress();
      if (reqData.response === "success") {
        setAllAddress(reqData.data);
      }
      if (reqData.response === "error") {
        setAllAddress([]);
      }
    }else{
      let user = getUser();
      if (user) {
        let reqData = await getAllAddress();
        if (reqData.response === "success") {
          setAllAddress(reqData.data);
        }
        if (reqData.response === "error") {
          setAllAddress([]);
        }
      }else{
        setAllAddress([]);
      }
    }
    setPageLoading(false);
  };
  useEffect(()=>{
    if (!userdata.loggedin) {
      let getUserData = getUser();
      if (!getUserData) {
        setNavLoader(true);
        setTimeout(() => {
          navigate('/auth/login');
        }, 3000);
      }
    }
  },[userdata])
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
      <Helmet>
      <title>Shortwash - Address</title>
      </Helmet>
        <Navbar page="auth" />
        {alertValue && (
          <Alert theme={alertValue.theme} message={alertValue.message} />
        )}

        {navLoader && <div className="simpleflex" style={{height: "calc(100vh - 86px)"}}>
        <Spinner borderColor="#000000" size="28px"/>
        </div>}

        {!navLoader && <div style={{ width: "100%", minHeight: "100%" }} className="simpleflex">
          <div className="address-outer-wrapper" style={{ backgroundColor: "white" }}>
            
            <div className="simpleflex">
              <span
                style={{
                  fontSize: 32,
                  fontWeight: "300",
                  textAlign: "center",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                Address
              </span>
            </div>
            <div
              style={{ paddingBottom: 20 }}
              className="addressoutercontainer"
            >
              <div
                style={{ marginTop: 20, marginBottom: 20 }}
                className="newaddressbtncontainer"
              >
                <div className="simpleflex"
                  onClick={() => {
                    navigate("/auth/address/addnewaddress");
                  }}
                >
                  <span
                    style={{
                      color: "#007BFF",
                      fontSize: 16,
                      cursor:'pointer'
                    }}
                  >
                    + Add New Address
                  </span>
                </div>
              </div>
              <div className="addresscontainer">
                {pageLoading && (
                  <div
                    className="simpleflex"
                    style={{ height: "calc(100vh - 125px - 152px)" }}
                  >
                    <Spinner borderColor="#000000" size="28px" />
                  </div>
                )}
                {!pageLoading &&
                  allAddress.map((val) => {
                    return (
                      <MainAddress
                        address={val}
                        key={val.addressid}
                        deleteAddress={deleteAddress}
                      />
                    );
                  })}
                {(allAddress != null && !pageLoading) && allAddress.length == 0 && (
                  <div
                    style={{ marginTop: 50, textAlign: "center", fontSize: 16 }}
                  >
                    <span>
                    You haven't added any address yet!
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>}

      </div>
    </React.Fragment>
  );
}
