import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

export default function PgNavbar() {
    const navigate = useNavigate();
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark"
      style={{ backgroundColor: "black" }}
    >
      <div className="container-fluid d-flex justify-content-center">
        <img
          src={require("../assets/logo.png")}
          alt="Shortwash Logo"
          height="70px"
          style={{ cursor: "pointer" }}
          onClick={() => {
            navigate("/");
          }}
        />
      </div>
    </nav>
  );
}
