import React, { useContext } from "react";

import AppContext from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Navbar from "../component/Navbar"
import Alert from "./Alert";
import { Helmet } from "react-helmet";

export default function NotFound() {
  const context = useContext(AppContext);
  const {
    alertValue,
    showAlert
  } = context; 
  const navigate = useNavigate();

  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
    <div style={{minHeight : '100vh',position : 'relative'}}>
    <Helmet>
    <title>Shortwash - 404 not found</title>
    </Helmet>
    <Navbar />
    {alertValue && <Alert theme={alertValue.theme} message={alertValue.message}/>}
      <div className="simpleflex" style={{height:'calc(100vh - 86px)',flexDirection:'column'}}>
        <span style={{fontSize:32,fontWeight:'300'}}>Page not found</span>
        <img src={require('../assets/error/error.jpg')} alt="Error" className="notfound-error-img"/>
      </div>
      </div>
    </React.Fragment>
  );
}
