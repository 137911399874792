import React, { useContext, useRef, useEffect } from "react";
import AppContext from "../context/AppContext";

import { useNavigate } from "react-router-dom";
import { useState } from "react";
import WashprogramAccordian from "./stagescomponents/WashprogramAccordian";
import TimeslotAccordian from "./stagescomponents/TimeslotAccordian";
import PickupaddressAccordian from "./stagescomponents/PickupaddressAccordian";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import Alert from "./Alert";
import { Helmet } from "react-helmet";

export default function Stages() {
  const context = useContext(AppContext);
  const {
    washprogram,
    timeslot,
    pickupAdd,
    setUserData,
    guestMode,
    fetchAddress,
    userdata,
    alertValue,
  } = context;

  const [stageErros, setStageErrors] = useState({
    washprogramError: false,
    pickuptimeslotError: false,
    deliveryaddressError: false,
  });

  const confirm_btn = useRef();

  const navigate = useNavigate();

  // componentdidmount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    fetchAddress();
  }, [userdata]);
  // go to overview
  function finalStage() {
    // validation
    if (washprogram === null) {
      setStageErrors((state) => {
        return { ...state, washprogramError: true };
      });
    } else {
      setStageErrors((state) => {
        return { ...state, washprogramError: false };
      });
    }
    if (timeslot === null) {
      setStageErrors((state) => {
        return { ...state, pickuptimeslotError: true };
      });
    } else {
      setStageErrors((state) => {
        return { ...state, pickuptimeslotError: false };
      });
    }
    if (pickupAdd === null && guestMode === false) {
      setStageErrors((state) => {
        return { ...state, deliveryaddressError: true };
      });
    } else {
      setStageErrors((state) => {
        return { ...state, deliveryaddressError: false };
      });
    }
    if (washprogram && timeslot && (pickupAdd || guestMode)) {
      navigate("/overview");
    }
  }
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <Helmet>
          <title>
            Shortwash - Select your wash program, timeslot and pickup address
          </title>
        </Helmet>
        <Navbar page="home" />
        {alertValue && (
          <Alert theme={alertValue.theme} message={alertValue.message} />
        )}
        <div className="container stages-wrapper">
          
          <div className="text-center py-4">
            <span style={{ fontSize: 23 }} className="px-3">
              You are just few steps ahead to place your order
            </span>
          </div>
          <div className="simpleflex text-center pb-3 ">
            <span className="text-muted">
              Do you know an average family person who washes clothes spends 7.5
              years of his lifespan just cleaning clothes?
            </span>
          </div>
          <div
            className="stages-wrapper simpleflex"
            style={{ flexDirection: "column", paddingBottom: 70 }}
          >
            <div
              className="accordion stage-accordian-wrapper"
              id="accordionExample"
            >
              <WashprogramAccordian />

              <TimeslotAccordian />

              <PickupaddressAccordian />
            </div>
            <div className="button-wrapper w-100 my-4">
              <div
                className="d-flex text-center my-3"
                style={{
                  flexDirection: "column",
                }}
              >
                {stageErros.washprogramError && (
                  <span style={{ fontSize: 14, color: "red" }}>
                    * Please select wash program
                  </span>
                )}
                {stageErros.pickuptimeslotError && (
                  <span style={{ fontSize: 14, color: "red" }}>
                    * Please select a pickup timeslot
                  </span>
                )}
                {stageErros.deliveryaddressError && (
                  <span style={{ fontSize: 14, color: "red" }}>
                    * Please select a pickup & delivery address or checkout as
                    guest
                  </span>
                )}
              </div>
              <div className="d-flex justify-content-center stage-btn-container">
                <button
                  className="btn btn-outline-dark stage-confirm-btn"
                  style={{ fontSize: 14, marginRight: 20 }}
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Cancel Order
                </button>
                <button
                  className="btn text-light stage-cancel-btn"
                  ref={confirm_btn}
                  style={{ backgroundColor: "red", fontSize: 14 }}
                  onClick={() => {
                    finalStage();
                  }}
                >
                  Confirm Order
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
