import React, { useState } from "react";
import app from "../../../firebaseConfig";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useEffect } from "react";
import { useContext } from "react";
import AppContext from "../../../context/AppContext";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Spinner";

export default function FLSignup(props) {
  const auth = getAuth(app);
  const context = useContext(AppContext);
  const {
    sendDataToApp,
    setSignupFieldDetails,
    signupFieldDetails,
    signupErrorDetails,
    setSignupErrors,
    signupUser,
    signupconfirmRes,
    setSignupConfirmRes,
  } = context;
  const [pageStage, setPageStage] = useState(1);
  const [stageOneLoader, setStageOneLoader] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "sign-in-buttons",
        {
          size: "invisible",
          callback: (response) => {},
          "expired-callback": (res) => {
            sendDataToApp({
              alert: {
                type: "red",
                message:
                  "This is a development error, complaint it at complaint@shortwash.com or restart the app",
                duration: 5000,
              },
            });
            console.log("recaptch expired");
          },
        },
        auth
      );
    } catch (error) {
      sendDataToApp({
        alert: {
          type: "red",
          message:
            "This is a development error, complaint it at complaint@shortwash.com or restart the app",
          duration: 5000,
        },
      });
      logError(error, { cause: "useEffect recaptcha - wsignup webview" });
      console.log("recaptch error - " + JSON.stringify(error.message));
    }
  }, []);

  function onchange(e) {
    if (e.target.id == "mobile") {
      if (signupconfirmRes) {
        setSignupConfirmRes(null);
      }
      if (e.target.value.length <= 10) {
        setSignupFieldDetails({
          ...signupFieldDetails,
          [e.target.id]: e.target.value,
        });
      }
    } else {
      setSignupFieldDetails({
        ...signupFieldDetails,
        [e.target.id]: e.target.value,
      });
    }
  }

  const stageOne = async () => {
    try {
      setSignupErrors({
        password: false,
        name: false,
        mobileLen: false,
        mobileExists: false,
      });

      let mobileErr = false;
      let nameErr = false;
      let passwordErr = false;

      // validating name field
      if (signupFieldDetails.name.length <= 1) {
        // enter a valid name
        setSignupErrors((stat) => {
          return { ...stat, name: true };
        });
        nameErr = true;
      } else {
        nameErr = false;
      }
      // validating mobile field
      if (
        signupFieldDetails.mobile.length < 10 ||
        signupFieldDetails.mobile.length > 10
      ) {
        // enter a valid mobile
        setSignupErrors((stat) => {
          return { ...stat, mobileLen: true };
        });
        mobileErr = true;
      } else {
        mobileErr = false;
      }
      // validating password field
      if (signupFieldDetails.password.length < 6) {
        // enter a valid name
        setSignupErrors((stat) => {
          return { ...stat, password: true };
        });
        passwordErr = true;
      } else {
        passwordErr = false;
      }

      if (!nameErr && !mobileErr && !passwordErr) {
        setStageOneLoader(true);
        let mobile = signupFieldDetails.mobile;

        let sqlQuery = `SELECT mobile from users where mobile = ${mobile}`;
        let dbUrl = "https://express.shortwash.com/getters/getsingle.php";
        let response = await fetch(dbUrl, {
          method: "POST",
          headers: {
            "Content-Type": "applicaiton/json",
          },
          body: JSON.stringify({
            query: sqlQuery,
          }),
        });
        let responseData = await response.json();

        if (responseData.response == "success") {
          setStageOneLoader(false);
          setSignupErrors((stats) => {
            return { ...stats, mobileExists: true };
          });
        } else {
          setStageOneLoader(false);
          setPageStage("loading");
          signInWithPhoneNumber(auth, `+91${mobile}`, window.recaptchaVerifier)
            .then((confirmationResult) => {
              // user in with confirmationResult.confirm(code).
              setSignupConfirmRes(confirmationResult);
              setTimeout(() => {
                sendDataToApp({
                  alert: {
                    type: "green",
                    message: `SMS sent successfully on +91${mobile}`,
                    duration: 5000,
                  },
                });
                navigate("/webview/flverification");
              }, 1000);
            })
            .catch((error) => {
              sendDataToApp({
                alert: {
                  type: "red",
                  message: `Something went wrong while sending SMS on +91${mobile}, please try again`,
                  duration: 5000,
                },
              });
              console.log("sms not sent - " + JSON.stringify(error.message));
              logError(error, {
                data: mobile,
                cause:
                  "stageone functions signinwithphonenumber - wsignup webview",
              });
              pageReset();
              // we had to reset recaptcha to use it again
            });
        }
      }
    } catch (error) {
      logError(error, {
        data: signupFieldDetails.mobile,
        cause: "stageone functions - wsignup webview",
      });
    }
  };
  async function logError(error, data = null) {
    if (error.message != "Failed to fetch") {
      let jsonData = JSON.stringify({
        message: error.message,
        stack: error.stack,
        dependencies: { data },
      });
      const response = await fetch(
        "https://express.shortwash.com/error/adderror.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ error: jsonData }),
        }
      );
    }
  }
  const pageReset = async () => {
    // setting confirm response to null
    setSignupConfirmRes(null);
    // getting back to stage 1
    setPageStage(1);
    // resetting fields from stage 1
    setSignupFieldDetails({
      name: "",
      mobile: "",
      email: "",
      password: "",
      referral: "",
      verification: "",
    });
    window.recaptchaVerifier.render().then(function (widgetId) {
      window.grecaptcha.reset(widgetId);
    });
  };

  return (
    <React.Fragment>
      <div
        style={{ width: "100%", height: "100vh", backgroundColor: "black" }}
        className="container"
      >
        {pageStage != "loading" && (
          <div
            style={{
              backgroundColor: "black",
            }}
          >
            <div className="text-center py-4">
              <span
                style={{
                  marginTop: 20,
                  marginLeft: 20,
                  marginRight: 20,
                  fontSize: 32,
                  fontWeight: "300",
                }}
                className="text-center text-white"
              >
                Create an account and enjoy our premium laundry service
              </span>
            </div>
            <div
              style={{
                marginHorizontal: 20,
                marginTop: 5,
                marginBottom: 30,
              }}
            >
              <div style={{ position: "relative" }}>
                <span
                  style={{
                    fontSize: 15,
                    color: "grey",
                    position: "absolute",
                    backgroundColor: "black",
                    paddingLeft: 5,
                    paddingRight: 5,
                    zIndex: 1,
                    left: 12,
                    top: -10,
                    color: signupErrorDetails.name ? "red" : "white",
                  }}
                >
                  Name
                </span>
                <input
                  style={{
                    borderWidth: 1,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 15,
                    paddingBottom: 15,
                    color: "white",
                    fontSize: 16,
                    borderColor: signupErrorDetails.name ? "red" : "white",
                    width: "100%",
                    backgroundColor: "black",
                  }}
                  placeholder="Your sweet name"
                  value={signupFieldDetails.name}
                  id="name"
                  className="form-control"
                  onChange={onchange}
                />
              </div>
              {/*error*/}
              <div
                style={{
                  visibility: signupErrorDetails.name ? "visible" : "hidden",
                  flexDirection: "row",
                  color: "red",
                  marginTop: 5,
                  alignItems: "center",
                  height: 21,
                }}
                className="d-flex"
              >
                <img
                  style={{
                    height: 12,
                    width: 12,
                    marginRight: 5,
                  }}
                  src={require("../../../assets/signup/info_red.png")}
                />
                <span style={{ color: "red", fontSize: 14 }}>Invalid name</span>
              </div>
              <div style={{ position: "relative", marginTop: 20 }}>
                <span
                  style={{
                    fontSize: 15,
                    color: "grey",
                    position: "absolute",
                    backgroundColor: "black",
                    paddingLeft: 5,
                    paddingRight: 5,
                    zIndex: 1,
                    left: 12,
                    top: -10,
                    color:
                      signupErrorDetails.mobileLen ||
                      signupErrorDetails.mobileExists
                        ? "red"
                        : "white",
                  }}
                >
                  Mobile
                </span>
                <input
                  style={{
                    borderColor: "rgb(225,225,225)",
                    borderWidth: 1,
                    marginTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 15,
                    paddingBottom: 15,
                    color: "white",
                    width: "100%",
                    fontSize: 16,
                    borderColor:
                      signupErrorDetails.mobileLen ||
                      signupErrorDetails.mobileExists
                        ? "red"
                        : "white",
                    backgroundColor: "black",
                  }}
                  placeholder="Your primary number"
                  id="mobile"
                  type="number"
                  className="form-control"
                  value={signupFieldDetails.mobile}
                  onChange={onchange}
                />
              </div>

              {/*error*/}
              <div
                style={{
                  visibility:
                    signupErrorDetails.mobileLen ||
                    signupErrorDetails.mobileExists
                      ? "visible"
                      : "hidden",
                  flexDirection: "row",
                  color: "red",
                  marginTop: 5,
                  alignItems: "center",
                }}
                className="d-flex"
              >
                <img
                  style={{
                    height: 12,
                    width: 12,
                    marginRight: 5,
                    marginTop: 5,
                    marginBottom: 4,
                  }}
                  src={require("../../../assets/signup/info_red.png")}
                />
                {signupErrorDetails.mobileLen && (
                  <span style={{ color: "red", fontSize: 14 }}>
                    Mobile number should be of 10 digits
                  </span>
                )}
              </div>

              <div style={{ position: "relative", marginTop: 20 }}>
                <span
                  style={{
                    fontSize: 15,
                    color: "grey",
                    position: "absolute",
                    backgroundColor: "black",
                    paddingLeft: 5,
                    paddingRight: 5,
                    zIndex: 1,
                    left: 12,
                    top: -10,
                    color: signupErrorDetails.password ? "red" : "white",
                  }}
                >
                  Password
                </span>
                <input
                  style={{
                    borderColor: "rgb(225,225,225)",
                    borderWidth: 1,
                    marginTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 15,
                    paddingBottom: 15,
                    color: "white",
                    fontSize: 16,
                    width: "100%",
                    borderColor: signupErrorDetails.password ? "red" : "white",
                    backgroundColor: "black",
                  }}
                  placeholder="Your dangerous password"
                  id="password"
                  type="password"
                  className="form-control"
                  value={signupFieldDetails.password}
                  onChange={onchange}
                />
                <div
                  style={{
                    visibility: signupErrorDetails.password
                      ? "visible"
                      : "hidden",
                    flexDirection: "row",
                    color: "red",
                    marginTop: 5,
                    alignItems: "center",
                  }}
                  className="d-flex"
                >
                  <img
                    style={{
                      height: 12,
                      width: 12,
                      marginRight: 5,
                    }}
                    src={require("../../../assets/signup/info_red.png")}
                  />
                  <span style={{ color: "red", fontSize: 14 }}>
                    Password must be of atleast 6 characters
                  </span>
                </div>
              </div>

              <div style={{ position: "relative", marginTop: 20 }}>
                <span
                  style={{
                    fontSize: 15,
                    color: "white",
                    position: "absolute",
                    backgroundColor: "black",
                    paddingLeft: 5,
                    paddingRight: 5,
                    zIndex: 1,
                    left: 12,
                    top: -10,
                  }}
                >
                  Referral Code (Optional)
                </span>
                <input
                  style={{
                    borderColor: "white",
                    borderWidth: 1,
                    marginTop: 6,
                    paddingLeft: 10,
                    paddingRight: 10,
                    paddingTop: 15,
                    paddingBottom: 15,
                    width: "100%",
                    color: "white",
                    fontSize: 16,
                    backgroundColor : 'black'
                  }}
                  placeholder="Your friend's referral code"
                  className="form-control"
                  id="referral"
                  value={signupFieldDetails.referral}
                  onChange={onchange}
                />
              </div>
              <button
                className="btn w-100"
                style={{
                  backgroundColor: "red",
                  color: "white",
                  marginTop: 30,
                  borderRadius: 5,
                }}
                onClick={stageOne}
              >
                {!stageOneLoader && "Sign up"}
                {stageOneLoader && (
                  <Spinner borderColor="#ffffff" size="24px" />
                )}
              </button>

              <button
                className="btn btn-light w-100 mt-4"
                onClick={() => {
                  sendDataToApp({
                    login: true,
                  });
                }}
              >
                Already have account?
              </button>
            </div>
          </div>
        )}

        {pageStage == "loading" && (
          <div className="simpleflex" style={{ height: "100vh" }}>
            <Spinner borderColor="#000000" size="28px" />
          </div>
        )}
        <div id="sign-in-buttons"></div>
      </div>
    </React.Fragment>
  );
}
