import React from "react";
import { useState, useContext, useEffect } from "react";

import Navbar from "../../Navbar";
import AppContext from "../../../context/AppContext";
import Alert from "../../Alert";
import { useNavigate, useParams } from "react-router-dom";
import Spinner from "../../Spinner";
import { Helmet } from "react-helmet";

export default function EditAddress({ navigation }) {
  const context = useContext(AppContext);
  const {
    showAlert,
    alertValue,
    fetchAddress,
    getUser,
    userdata,
    logError
  } = context;
  const { addressid } = useParams();
  const [pageLoading,setPageLoading] = useState(true);
  const [submitLoading,setSubmitLoading] = useState(false);
  const [newaddress, setAddress] = useState({
    name: "",
    mobile: "",
    address1: "",
    address2: "",
    landmark: "",
    pincode: "122052",
    city: "Gurugram",
    state: "Haryana",
  });
  const [errorVal, setError] = useState({
    name: false,
    mobile: false,
    address1: false,
    address2: false,
    landmark: false,
    pincode: false,
    city: false,
    state: false,
  });
  const navigate = useNavigate();
  // functions
  const submit = async () => {
    // resetting errors
    setError({
      name: false,
      mobile: false,
      address1: false,
      address2: false,
      landmark: false,
      pincode: false,
      city: false,
      state: false,
    });

    let nameErr = false;
    let mobileErr = false;
    let address1Err = false;
    let address2Err = false;
    let landmarkErr = false;
    let pincodeErr = false;
    let cityErr = false;
    let stateErr = false;

    // validations
    if (newaddress.name == "" || newaddress.name.length < 3) {
      setError(function (state) {
        return { ...state, name: true };
      });
      nameErr = true;
    } else {
      nameErr = false;
    }
    if (
      newaddress.mobile == "" ||
      newaddress.mobile.length < 10 ||
      newaddress.mobile.length > 10
    ) {
      mobileErr = true;
      setError(function (state) {
        return { ...state, mobile: true };
      });
    } else {
      mobileErr = false;
    }
    if (newaddress.address1 == "" || newaddress.address1.length < 3) {
      address1Err = true;
      setError(function (state) {
        return { ...state, address1: true };
      });
    } else {
      address1Err = false;
    }
    if (newaddress.address2 == "" || newaddress.address2.length < 3) {
      address2Err = true;
      setError(function (state) {
        return { ...state, address2: true };
      });
    } else {
      address2Err = false;
    }
    if (newaddress.landmark == "" || newaddress.landmark.length < 3) {
      landmarkErr = true;
      setError(function (state) {
        return { ...state, landmark: true };
      });
    } else {
      landmarkErr = false;
    }
    if (
      newaddress.pincode == "" ||
      newaddress.pincode.length < 6 ||
      newaddress.pincode.length > 6
    ) {
      pincodeErr = true;
      setError(function (state) {
        return { ...state, pincode: true };
      });
    } else {
      pincodeErr = false;
    }
    if (newaddress.city == "" || newaddress.city.length < 3) {
      cityErr = true;
      setError(function (state) {
        return { ...state, city: true };
      });
    } else {
      cityErr = false;
    }
    if (newaddress.state == "" || newaddress.state.length < 3) {
      stateErr = true;
      setError(function (val) {
        return { ...val, state: true };
      });
    } else {
      stateErr = false;
    }

    if (
      !nameErr &&
      !mobileErr &&
      !address1Err &&
      !address2Err &&
      !landmarkErr &&
      !pincodeErr &&
      !cityErr &&
      !stateErr
    ) {
      let res = await editAddress();
      
    }
  };
  const onchange = (e) => {
    setAddress((stat) => {
      return { ...stat, [e.target.id]: e.target.value };
    });
  };

  const getspecificAddress = async (id) => {
    setPageLoading(true);
    try {
      if (userdata.userid) {
        let url = "https://expressv2.shortwash.com/address/getspecificaddress.php";
        let reqData = { userid: userdata.userid, addressid };
  
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reqData),
        });
  
        const responseData = await response.json();
        if (responseData.response == "success") {
          setAddress(responseData.data[0]);
        }
      } else {
        let user = getUser();
        if (user) {
          let url =
            "https://expressv2.shortwash.com/address/getspecificaddress.php";
          let reqData = { userid: user.userid, addressid };
  
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(reqData),
          });
  
          const responseData = await response.json();
          if (responseData.response == "success") {
            setAddress(responseData.data[0]);
          }
  
        }
      }  
    } catch (error) {
      logError(error,{cause : "getspecificaddress func - EditAddress Website",userdata});
    }
    
    setPageLoading(false);
  };
  const editAddress = async () => {
    setSubmitLoading(true);
    try {
      if (userdata.userid) {
        let url = "https://expressv2.shortwash.com/address/editaddress.php";
        let reqData = { ...newaddress, userid: userdata.userid };
  
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reqData),
        });
  
        const responseData = await response.json();
        if (responseData.response == "success") {
          fetchAddress();
          showAlert("green","Address updated successfully",5000);
          navigate('/auth/address');
        }
      } else {
        let user = getUser();
        if (user) {
          let url = "https://expressv2.shortwash.com/address/editaddress.php";
          let reqData = { ...newaddress, userid: user.userid };
  
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(reqData),
          });
  
          const responseData = await response.json();
          if (responseData.response == "success") {
            fetchAddress();
          showAlert("green","Address updated successfully",5000);
          navigate('/auth/address');
  
          }
        }
      }
    } catch (error) {
      logError(error,{newaddress,userdata,cause : "editaddress function - editaddress web"});
    }
    
    setSubmitLoading(false);
  };
  useEffect(()=>{
    getspecificAddress(addressid);
  },[]);
  return (
    <React.Fragment>
      <div style={{ width: "100%", height: "100%" }}>
      <Helmet>
      <title>Shortwash - Edit your address</title>
      </Helmet>
        <Navbar />
        {alertValue && (
          <Alert theme={alertValue.theme} message={alertValue.message} />
        )}
        <div className="d-flex justify-content-end edit-address-wrapper">
          <div className="edit-address-outer-wrapper">
          {pageLoading && <div className="simpleflex" style={{height: "calc(100vh - 125px)"}}>
          <Spinner borderColor="#000000" size="28px"/>
          </div>}
          {!pageLoading && <div style={{ width: "100%", height: "100%" }}>
            <div
              style={{
                backgroundColor: "white",
              }}
            >
              <div
                className="text-center"
                style={{
                  marginVertical: 20,
                  fontSize: 32,
                  fontWeight: "300",
                  marginTop: 20,
                  marginBottom: 20,
                }}
              >
                <span>Edit address</span>
              </div>
              <div
                style={{ width: "100%", alignItems: "center" }}
                className="simpleflex edit-address-img-wrapper"
              >
                <img
                  style={{ width: "70%", height: 200 }}
                  src={require("../../../assets/address/address.png")}
                />
              </div>
              <div style={{ paddingTop: 45, paddingBottom: 45 }}>
                <div
                  style={{
                    position: "relative",
                    paddingTop: 7,
                    paddingBottom: 7,
                    paddingLeft: 30,
                    paddingRight: 30,
                  }}
                >
                  <input
                    className="form-control"
                    style={{
                      borderColor: errorVal.name ? "red" : "grey",
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 10,
                      paddingRight: 10,
                      fontSize: 16,
                      borderRadius: 6,
                    }}
                    placeholder="Type your full name"
                    value={newaddress.name}
                    id="name"
                    onChange={onchange}
                  />
                  <span
                    style={{
                      position: "absolute",
                      left: 35,
                      color: errorVal.name ? "red" : "grey",
                      top: -5,
                      fontSize: 17,
                      backgroundColor: "white",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Name
                  </span>
                  <span
                    style={{
                      color: "red",
                      paddingTop: 3,
                      paddingLeft: 5,
                      paddingRight: 5,
                      visibility: errorVal.name ? "visible" : "hidden",
                    }}
                  >
                    Name value is invalid!
                  </span>
                </div>
                <div
                  style={{
                    position: "relative",
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 7,
                    paddingBottom: 7,
                  }}
                >
                  <input
                    className="form-control"
                    style={{
                      borderColor: errorVal.mobile ? "red" : "grey",
                      borderWidth: 1,
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 10,
                      paddingRight: 10,
                      fontSize: 16,
                      borderRadius: 6,
                    }}
                    type="number"
                    id="mobile"
                    placeholder="Your mobile number"
                    value={newaddress.mobile}
                    onChange={onchange}
                  />
                  <span
                    style={{
                      position: "absolute",
                      left: 35,
                      color: errorVal.mobile ? "red" : "grey",
                      top: -5,
                      fontSize: 17,
                      backgroundColor: "white",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Mobile
                  </span>
                  <span
                    style={{
                      color: "red",
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 3,
                      visibility: errorVal.mobile ? "visible" : "hidden",
                    }}
                  >
                    Mobile number is invalid
                  </span>
                </div>
                <div
                  style={{
                    position: "relative",
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 7,
                    paddingBottom: 7,
                  }}
                >
                  <input
                    className="form-control"
                    style={{
                      borderColor: errorVal.address1 ? "red" : "grey",
                      borderWidth: 1,
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 10,
                      paddingRight: 10,
                      fontSize: 16,
                      borderRadius: 6,
                    }}
                    id="address1"
                    placeholder="House No./Flat No./Tower No."
                    value={newaddress.address1}
                    onChange={onchange}
                  />
                  <span
                    style={{
                      position: "absolute",
                      left: 35,
                      color: errorVal.address1 ? "red" : "grey",
                      top: -5,
                      fontSize: 17,
                      backgroundColor: "white",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Address 1
                  </span>
                  <span
                    style={{
                      color: "red",
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 3,
                      visibility: errorVal.address1 ? "visible" : "hidden",
                    }}
                  >
                    Address 1 value is invalid!
                  </span>
                </div>
                <div
                  style={{
                    position: "relative",
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 7,
                    paddingBottom: 7,
                  }}
                >
                  <input
                    className="form-control"
                    style={{
                      borderColor: errorVal.address2 ? "red" : "grey",
                      borderWidth: 1,
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 10,
                      paddingRight: 10,
                      fontSize: 16,
                      borderRadius: 6,
                    }}
                    id="address2"
                    placeholder="Society Name, Sector Name, Village Name"
                    value={newaddress.address2}
                    onChange={onchange}
                  />
                  <span
                    style={{
                      position: "absolute",
                      left: 35,
                      color: errorVal.address2 ? "red" : "grey",
                      top: -5,
                      fontSize: 17,
                      backgroundColor: "white",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Address 2
                  </span>
                  <span
                    style={{
                      color: "red",
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 3,
                      visibility: errorVal.address2 ? "visible" : "hidden",
                    }}
                  >
                    Address 2 value is invalid!
                  </span>
                </div>
                <div
                  style={{
                    position: "relative",
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 7,
                    paddingBottom: 7,
                  }}
                >
                  <input
                    className="form-control"
                    style={{
                      borderColor: errorVal.landmark ? "red" : "grey",
                      borderWidth: 1,
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 10,
                      paddingRight: 10,
                      fontSize: 16,
                      borderRadius: 6,
                    }}
                    id="landmark"
                    placeholder="Famous place near you"
                    value={newaddress.landmark}
                    onChange={onchange}
                  />
                  <span
                    style={{
                      position: "absolute",
                      left: 35,
                      color: errorVal.landmark ? "red" : "grey",
                      top: -5,
                      fontSize: 17,
                      backgroundColor: "white",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Landmark
                  </span>
                  <span
                    style={{
                      color: "red",
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 3,
                      visibility: errorVal.landmark ? "visible" : "hidden",
                    }}
                  >
                    Landmark value is invalid!
                  </span>
                </div>
                <div className="simpleflex">
                  <span className="text-muted">
                    You can't change pincode, city & state
                  </span>
                </div>
                <div
                  style={{
                    position: "relative",
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 7,
                    paddingBottom: 7,
                  }}
                >
                  <input
                    className="form-control"
                    style={{
                      borderColor: errorVal.pincode ? "red" : "grey",
                      borderWidth: 1,
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 10,
                      paddingRight: 10,
                      fontSize: 16,
                      borderRadius: 6,
                    }}
                    type="number"
                    value="122052"
                    disabled={true}
                    id="pincode"
                    onChange={onchange}
                  />
                  <span
                    style={{
                      position: "absolute",
                      left: 35,
                      color: errorVal.pincode ? "red" : "grey",
                      top: -5,
                      fontSize: 17,
                      backgroundColor: "white",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    Pincode
                  </span>
                  <span
                    style={{
                      color: "red",
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 3,
                      visibility: errorVal.pincode ? "visible" : "hidden",
                    }}
                  >
                    Pincode value is invalid!
                  </span>
                </div>
                <div
                  style={{
                    position: "relative",
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 7,
                    paddingBottom: 7,
                  }}
                >
                  <input
                    className="form-control"
                    style={{
                      borderColor: errorVal.city ? "red" : "grey",
                      borderWidth: 1,
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 10,
                      paddingRight: 10,
                      fontSize: 16,
                      borderRadius: 6,
                    }}
                    value="Gurugram"
                    disabled={true}
                    id="city"
                    onChange={onchange}
                  />
                  <span
                    style={{
                      position: "absolute",
                      left: 35,
                      color: errorVal.city ? "red" : "grey",
                      top: -5,
                      fontSize: 17,
                      backgroundColor: "white",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    City
                  </span>
                  <span
                    style={{
                      color: "red",
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 3,
                      visibility: errorVal.city ? "visible" : "hidden",
                    }}
                  >
                    City value is invalid!
                  </span>
                </div>
                <div
                  style={{
                    position: "relative",
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 7,
                    paddingBottom: 7,
                  }}
                >
                  <input
                    className="form-control"
                    style={{
                      borderColor: errorVal.state ? "red" : "grey",
                      borderWidth: 1,
                      paddingTop: 14,
                      paddingBottom: 14,
                      paddingLeft: 10,
                      paddingRight: 10,
                      fontSize: 16,
                      borderRadius: 6,
                    }}
                    value="Haryana"
                    disabled={true}
                    id="state"
                    onChange={onchange}
                  />
                  <span
                    style={{
                      position: "absolute",
                      left: 35,
                      color: errorVal.state ? "red" : "grey",
                      top: -5,
                      fontSize: 17,
                      backgroundColor: "white",
                      paddingLeft: 10,
                      paddingRight: 10,
                    }}
                  >
                    State
                  </span>
                  <span
                    style={{
                      color: "red",
                      paddingLeft: 5,
                      paddingRight: 5,
                      paddingTop: 3,
                      visibility: errorVal.state ? "visible" : "hidden",
                    }}
                  >
                    State value is invalid!
                  </span>
                </div>
                <div
                  style={{
                    paddingLeft: 30,
                    paddingRight: 30,
                    paddingTop: 7,
                    paddingBottom: 7,
                  }}
                >
                  <div>
                    <button
                      style={{
                        backgroundColor: "red",
                      }}
                      className="btn w-100"
                      onClick={() => {
                        submit();
                      }}
                    >
                      {!submitLoading && <span style={{ color: "white", textAlign: "center" }}>
                        Save
                      </span>}
                      {submitLoading && <Spinner borderColor="#ffffff" size="24px"/>}
                    </button>
                  </div>
                  <div style={{ marginTop: 20 }}>
                    <button
                      className="btn btn-outline-dark w-100"
                      onClick={() => {
                        navigate("/auth/address");
                      }}
                    >
                      <span style={{ textAlign: "center" }}>Cancel</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
