import React from "react";
import { useContext } from "react";
import FacebookIcon from "../../assets/social/facebook.png";
import InstagramIcon from "../../assets/social/instagram.png";
import TwitterIcon from "../../assets/social/twitter.png";
import WhatsappIcon from "../../assets/social/whatsapp.png";
import AppContext from "../../context/AppContext";

export default function SocialMedia() {
  const context = useContext(AppContext);

  return (
    <div className="social-handles-inner-wrapper w-100 d-flex justify-content-center align-items-center">
      <a
        href="https://www.facebook.com/people/ShortWash/100089193737874/"
        target="_blank"
        style={{ marginRight: 45 }}
      >
        <img
          src={FacebookIcon}
          alt="Facebook Icon"
        />
      </a>
      <a href="https://www.instagram.com/official_shortwash/" target="_blank" style={{ marginRight: 45 }}>
        <img
          src={InstagramIcon}
          alt="Instagram Icon"
        />
      </a>
      <a href="https://wa.me/917838106525" target="_blank" style={{ marginRight: 45 }}>
        <img src={WhatsappIcon} alt="Whatsapp Icon" />
      </a>
    </div>
  );
}
