import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import React from 'react';
import Home from "./component/Home"
import Stages from "./component/Stages"
import Overview from "./component/Overview"
import Confirm from "./component/Confirm"
import AppState from "./context/AppState"
import Reviews from "./component/Reviews"
import Payment from './component/Payment';
import SepReview from "./component/SepReview"
import Signup from "./component/auth/Signup"
import Verification from "./component/auth/Verification"
import ForgotPassword from "./component/auth/ForgotPassword"
import Newpassword from "./component/auth/Newpassword"
import Login from './component/auth/Login';
import Profile from './component/auth/Profile';
import Wallet from './component/auth/Wallet';
import Orders from './component/auth/Orders';
import ViewOrder from './component/auth/OrdersComponent/ViewOrder';
import Address from './component/auth/Address';
import PaymentPage from './component/auth/OrdersComponent/PaymentPage';
import AddnewAddress from './component/auth/AddressComponent/AddnewAddress';
import EditAddress from './component/auth/AddressComponent/EditAddress';
import Refer from './component/Refer';
import Referral from './component/referral/Referral';
import Temp from './component/Temp';
import NotFound from './component/NotFound';
import WForgotPassword from './component/webview/WForgotPassword';
import WNewpassword from './component/webview/WNewpassword';
import WVerification from './component/webview/WVerification';
import WSignup from './component/webview/WSignup';
import Wrefer from './component/webview/Wrefer';
import Contact from './component/Contact';
import DownloadOurApplication from './component/DownloadOurApplication';
import FLForgotPassword from './component/webview/FirstLogin/FLForgotPassword';
import FLSignup from './component/webview/FirstLogin/FLSignup';
import FLVerification from './component/webview/FirstLogin/FLVerification';
import FLNewPassword from "./component/webview/FirstLogin/FLNewPassword"

function App() {
  const router = createBrowserRouter([
    { 
      path: "/",
      element: <Home/>,
    },
    {
      path: "*",
      element : <NotFound/>
    },
    {
      path: "/home",
      element: <Home/>,
    },
    {
      path: "/stages",
      element: <Stages/> ,
    },
    {
      path: "/overview",
      element: <Overview/>,
    },
    {
      path: "/confirm",
      element: <Confirm/>,
    },
    {
      path: "/reviews",
      element: <Reviews/>,
    },
    {
      path: "/refer",
      element: <Refer/>,
    },
    {
      path: "/contact",
      element: <Contact/>,
    },
    {
      path: "/downloadourapplication",
      element: <DownloadOurApplication/>,
    },
    {
      path: "/:referralcode",
      element: <Referral/>,
    },
    {
      path: "/auth/signup",
      element: <Signup/>
    },
    {
      path: "/auth/verification",
      element: <Verification/>
    },
    {
      path: "/auth/forgotpassword",
      element: <ForgotPassword/>
    },
    {
      path: "/auth/newpassword",
      element: <Newpassword/>
    }
    ,
    {
      path: "/auth/login",
      element: <Login/>
    },
    {
      path: "/auth/profile",
      element: <Profile/>
    },
    {
      path: "/auth/orders",
      element: <Orders/>
    },
    {
      path: "/auth/orders/vieworder/:id",
      element: <ViewOrder/>
    },
    {
      path: "/auth/orders/payment/:id",
      element: <PaymentPage/>
    },
    {
      path: "/auth/wallet",
      element: <Wallet/>
    },
    {
      path: "/auth/address",
      element: <Address/>
    },
    {
      path: "/auth/address/addnewaddress",
      element: <AddnewAddress/>
    },
    {
      path: "/auth/address/editaddress/:addressid",
      element: <EditAddress/>
    },
    {
      path: "/:code/:tid/:amount",
      element: <Payment/>
    },
    {
      path: "/webview/forgotpassword",
      element: <WForgotPassword/>
    },
    {
      path: "/webview/newpassword",
      element: <WNewpassword/>
    },
    {
      path: "/webview/verification",
      element: <WVerification/>
    },
    {
      path: "/webview/signup",
      element: <WSignup/>
    },
    {
      path: "/webview/refer",
      element: <Wrefer/>
    },
    {
      path: "/webview/flforgotpassword",
      element: <FLForgotPassword/>
    },
    {
      path: "/webview/flnewpassword",
      element: <FLNewPassword/>
    },{
      path: "/webview/flverification",
      element: <FLVerification/>
    },
    {
      path: "/webview/flsignup",
      element: <FLSignup/>
    }

  ]);
  return (
    <AppState>
    <React.Fragment>
    <RouterProvider router={router} />
    </React.Fragment>
    </AppState>
  );
}

export default App;
