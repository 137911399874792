import React from "react";
import { useState, useContext, useEffect } from "react";


import Navbar from "../../Navbar";
import AppContext from "../../../context/AppContext";
import Alert from "../../Alert";
import { useNavigate } from "react-router-dom";
import Spinner from "../../Spinner";
import { Helmet } from "react-helmet";
export default function AddnewAddress(props) {
  const context = useContext(AppContext);
  const {
    addAddress,
    userdata,
    showAlert,
    alertValue,
  } = context;
  const [newaddress, setAddress] = useState({
    name: "",
    mobile: "",
    address1: "",
    address2: "",
    landmark: "",
    pincode: "122052",
    city: "Gurugram",
    state: "Haryana",
  });
  const [errorVal, setError] = useState({
    name: false,
    mobile: false,
    address1: false,
    address2: false,
    landmark: false,
    pincode: false,
    city: false,
    state: false,
  });
  const [pageLoading,setPageLoading] = useState(false);
  const navigate = useNavigate();
  // functions
  const submit = async () => {
    setPageLoading(true);
    // resetting errors
    setError({
      name: false,
      mobile: false,
      address1: false,
      address2: false,
      landmark: false,
      pincode: false,
      city: false,
      state: false,
    });

    let nameErr = false;
    let mobileErr = false;
    let address1Err = false;
    let address2Err = false;
    let landmarkErr = false;
    let pincodeErr = false;
    let cityErr = false;
    let stateErr = false;

    // validations
    if (newaddress.name == "" || newaddress.name.length < 3) {
      setError(function (state) {
        return { ...state, name: true };
      });
      nameErr = true;
    } else {
      nameErr = false;
    }
    if (
      newaddress.mobile == "" ||
      newaddress.mobile.length < 10 ||
      newaddress.mobile.length > 10
    ) {
      mobileErr = true;
      setError(function (state) {
        return { ...state, mobile: true };
      });
    } else {
      mobileErr = false;
    }
    if (newaddress.address1 == "" || newaddress.address1.length < 3) {
      address1Err = true;
      setError(function (state) {
        return { ...state, address1: true };
      });
    } else {
      address1Err = false;
    }
    if (newaddress.address2 == "" || newaddress.address2.length < 3) {
      address2Err = true;
      setError(function (state) {
        return { ...state, address2: true };
      });
    } else {
      address2Err = false;
    }
    if (newaddress.landmark == "" || newaddress.landmark.length < 3) {
      landmarkErr = true;
      setError(function (state) {
        return { ...state, landmark: true };
      });
    } else {
      landmarkErr = false;
    }
    if (
      newaddress.pincode == "" ||
      newaddress.pincode.length < 6 ||
      newaddress.pincode.length > 6
    ) {
      pincodeErr = true;
      setError(function (state) {
        return { ...state, pincode: true };
      });
    } else {
      pincodeErr = false;
    }
    if (newaddress.city == "" || newaddress.city.length < 3) {
      cityErr = true;
      setError(function (state) {
        return { ...state, city: true };
      });
    } else {
      cityErr = false;
    }
    if (newaddress.state == "" || newaddress.state.length < 3) {
      stateErr = true;
      setError(function (val) {
        return { ...val, state: true };
      });
    } else {
      stateErr = false;
    }

    if (
      !nameErr &&
      !mobileErr &&
      !address1Err &&
      !address2Err &&
      !landmarkErr &&
      !pincodeErr &&
      !cityErr &&
      !stateErr
    ) {
      let res = await addAddress(newaddress);
      if (res.response == "success") {
        showAlert("green", "Address added successfully", 5000);
        navigate('/auth/address');
      }
      if (res.response == "failed") {
        showAlert("red", "Something went wrong, try again later", 5000);
      }
    }
    setPageLoading(false);
  };
  
  const onchange = (e)=>{
    if (e.target.id == 'mobile') {
      if (e.target.value.length <= 10) {
        setAddress((stat)=>{
          return {...stat,[e.target.id] : e.target.value};
        }) 
      }
    }else{

      setAddress((stat)=>{
        return {...stat,[e.target.id] : e.target.value};
      })
    }
  }
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
      <Helmet>
      <title>Shortwash - Add new address</title>
      </Helmet>
        <Navbar page="auth" />
        {alertValue && (
          <Alert theme={alertValue.theme} message={alertValue.message} />
        )}
        <div style={{ width: "100%", height: "100%" }} className='d-flex justify-content-end add-new-address-wrapper'>
          
          <div className="add-new-address-outer-wrapper"
            style={{

              backgroundColor: "white",
            }}
          >
              <div className="text-center" style={
                { marginVertical: 20,fontSize:32,fontWeight:'300',marginTop:20,marginBottom:20 }}>
                <span
                  
                >
                  Add new address
                </span>
              </div>
              <div style={{ width: "100%", alignItems: "center" }} className="simpleflex add-new-address-img-wrapper">
                <img
                  style={{ width: "70%", height: 200 }}
                  src={require("../../../assets/address/address.png")}
                />
              </div>
              <div style={{ paddingTop:45,paddingBottom:45 }}>
                  <div
                    style={{
                      position: "relative",
                      paddingTop:7,
                        paddingBottom:7,
                      paddingLeft:30,
                      paddingRight:30
                    }}
                  >
                    <input className="form-control"
                      style={{
                        borderColor: errorVal.name ? "red" : "grey",
                        paddingTop:14,
                        paddingBottom:14,
                        paddingLeft:10,
                        paddingRight:10,
                        fontSize: 16,
                        borderRadius: 6,
                      }}
                      
                      placeholder="Type your full name"
                      value={newaddress.name}
                      id="name"
                      onChange={onchange}
                    />
                    <span
                      style={{
                        position: "absolute",
                        left: 35,
                        color: errorVal.name ? "red" : "grey",
                        top: -5,
                        fontSize: 17,
                        backgroundColor: "white",
                        paddingLeft:10,
                        paddingRight:10,
                      }}
                    >
                      Name
                    </span>
                    <span
                      style={{
                        color: "red",
                        paddingTop: 3,
                        paddingLeft:5,
                        paddingRight:5,
                        visibility: errorVal.name ? "visible" : "hidden",
                      }}
                    >
                      Name value is invalid!
                    </span>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      paddingLeft:30,
                        paddingRight:30,
                      paddingTop:7,
                        paddingBottom:7,
                    }}
                  >
                    <input className="form-control"
                      style={{
                        borderColor: errorVal.mobile
                          ? "red"
                          : "grey",
                        borderWidth: 1,
                        paddingTop:14,
                        paddingBottom:14,
                        paddingLeft:10,
                        paddingRight:10,
                        fontSize: 16,
                        borderRadius: 6,
                      }}
                      type="number"
                      id="mobile"
                      placeholder="Your mobile number"
                      value={newaddress.mobile}
                      onChange={onchange}
                    />
                    <span
                      style={{
                        position: "absolute",
                        left: 35,
                        color: errorVal.mobile ? "red" : "grey",
                        top: -5,
                        fontSize: 17,
                        backgroundColor: "white",
                        paddingLeft:10,
                        paddingRight:10,
                      }}
                    >
                      Mobile
                    </span>
                    <span
                      style={{
                        color: "red",
                        paddingLeft:5,
                        paddingRight:5,
                        paddingTop: 3,
                        visibility: errorVal.mobile ? "visible" : "hidden",
                      }}
                    >
                      Mobile number is invalid 
                      
                      
                    </span>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      paddingLeft:30,
                        paddingRight:30,
                      paddingTop:7,
                        paddingBottom:7,
                    }}
                  >
                    <input className="form-control"
                      style={{
                        borderColor: errorVal.address1
                          ? "red"
                          : "grey",
                        borderWidth: 1,
                        paddingTop:14,
                        paddingBottom:14,
                        paddingLeft:10,
                        paddingRight:10,
                        fontSize: 16,
                        borderRadius : 6
                      }}
                      id="address1"
                      placeholder="House No./Flat No./Tower No."
                      value={newaddress.address1}
                      onChange={onchange}
                    />
                    <span
                      style={{
                        position: "absolute",
                        left: 35,
                        color: errorVal.address1 ? "red" : "grey",
                        top: -5,
                        fontSize: 17,
                        backgroundColor: "white",
                        paddingLeft:10,
                        paddingRight:10,
                      }}
                    >
                      Address 1
                    </span>
                    <span
                      style={{
                        color: "red",
                        paddingLeft:5,
                        paddingRight:5,
                        paddingTop: 3,
                        visibility: errorVal.address1 ? "visible" : "hidden",
                      }}
                    >
                      Address 1 value is invalid!
                    </span>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      paddingLeft:30,
                        paddingRight:30,
                      paddingTop:7,
                        paddingBottom:7,
                    }}
                  >
                    <input className="form-control"
                      style={{
                        borderColor: errorVal.address2
                          ? "red"
                          : "grey",
                        borderWidth: 1,
                        paddingTop:14,
                        paddingBottom:14,
                        paddingLeft:10,
                        paddingRight:10,
                        fontSize: 16,
                        borderRadius : 6
                      }}
                      id="address2"
                      placeholder="Society Name, Sector Name, Village Name"
                      value={newaddress.address2}
                      onChange={onchange}
                    />
                    <span
                      style={{
                        position: "absolute",
                        left: 35,
                        color: errorVal.address2 ? "red" : "grey",
                        top: -5,
                        fontSize: 17,
                        backgroundColor: "white",
                        paddingLeft:10,
                        paddingRight:10,
                      }}
                    >
                      Address 2
                    </span>
                    <span
                      style={{
                        color: "red",
                        paddingLeft:5,
                        paddingRight:5,
                        paddingTop: 3,
                        visibility: errorVal.address2 ? "visible" : "hidden",
                      }}
                    >
                      Address 2 value is invalid!
                    </span>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      paddingLeft:30,
                        paddingRight:30,
                      paddingTop:7,
                        paddingBottom:7,
                    }}
                  >
                    <input className="form-control"
                      style={{
                        borderColor: errorVal.landmark
                          ? "red"
                          : "grey",
                        borderWidth: 1,
                        paddingTop:14,
                        paddingBottom:14,
                        paddingLeft:10,
                        paddingRight:10,
                        fontSize: 16,
                        borderRadius : 6
                      }}
                      id="landmark"
                      placeholder="Famous place near you"
                      value={newaddress.landmark}
                      onChange={onchange}
                    />
                    <span
                      style={{
                        position: "absolute",
                        left: 35,
                        color: errorVal.landmark ? "red" : "grey",
                        top: -5,
                        fontSize: 17,
                        backgroundColor: "white",
                        paddingLeft:10,
                        paddingRight:10,
                      }}
                    >
                      Landmark
                    </span>
                    <span
                      style={{
                        color: "red",
                        paddingLeft:5,
                        paddingRight:5,
                        paddingTop: 3,
                        visibility: errorVal.landmark ? "visible" : "hidden",
                      }}
                    >
                      Landmark value is invalid!
                    </span>
                  </div>
                  <div className="simpleflex">
                  <span className="text-muted">You can't change pincode, city & state</span>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      paddingLeft:30,
                        paddingRight:30,
                      paddingTop:7,
                        paddingBottom:7,
                    }}
                  >
                    <input className="form-control"
                      style={{
                        borderColor: errorVal.pincode
                          ? "red"
                          : "grey",
                        borderWidth: 1,
                        paddingTop:14,
                        paddingBottom:14,
                        paddingLeft:10,
                        paddingRight:10,
                        fontSize: 16,
                        borderRadius : 6
  
                      }}
                      type="number"
                      value="122052"
                      disabled={true}
                      id="pincode"
                      onChange={onchange}
                    />
                    <span
                      style={{
                        position: "absolute",
                        left: 35,
                        color: errorVal.pincode ? "red" : "grey",
                        top: -5,
                        fontSize: 17,
                        backgroundColor: "white",
                        paddingLeft:10,
                        paddingRight:10,
                      }}
                    >
                      Pincode
                    </span>
                    <span
                      style={{
                        color: "red",
                        paddingLeft:5,
                        paddingRight:5,
                        paddingTop: 3,
                        visibility: errorVal.pincode ? "visible" : "hidden",
                      }}
                    >
                      Pincode value is invalid!
                    </span>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      paddingLeft:30,
                        paddingRight:30,
                      paddingTop:7,
                        paddingBottom:7,
                    }}
                  >
                    <input className="form-control"
                      style={{
                        borderColor: errorVal.city ? "red" : "grey",
                        borderWidth: 1,
                        paddingTop:14,
                        paddingBottom:14,
                        paddingLeft:10,
                        paddingRight:10,
                        fontSize: 16,
                        borderRadius : 6
  
                      }}
                      value="Gurugram"
                      disabled={true}
                      id='city'
                      onChange={onchange}
                    />
                    <span
                      style={{
                        position: "absolute",
                        left: 35,
                        color: errorVal.city ? "red" : "grey",
                        top: -5,
                        fontSize: 17,
                        backgroundColor: "white",
                        paddingLeft:10,
                        paddingRight:10,
                      }}
                    >
                      City
                    </span>
                    <span
                      style={{
                        color: "red",
                        paddingLeft:5,
                        paddingRight:5,
                        paddingTop: 3,
                        visibility: errorVal.city ? "visible" : "hidden",
                      }}
                    >
                      City value is invalid!
                    </span>
                  </div>
                  <div
                    style={{
                      position: "relative",
                      paddingLeft:30,
                        paddingRight:30,
                      paddingTop:7,
                        paddingBottom:7,
                    }}
                  >
                    <input className="form-control"
                      style={{
                        borderColor: errorVal.state
                          ? "red"
                          : "grey",
                        borderWidth: 1,
                        paddingTop:14,
                        paddingBottom:14,
                        paddingLeft:10,
                        paddingRight:10,
                        fontSize: 16,
                        borderRadius : 6
  
                      }}
                      value="Haryana"
                      disabled={true}
                      id="state"
                      onChange={onchange}
                    />
                    <span
                      style={{
                        position: "absolute",
                        left: 35,
                        color: errorVal.state ? "red" : "grey",
                        top: -5,
                        fontSize: 17,
                        backgroundColor: "white",
                        paddingLeft:10,
                        paddingRight:10,
                      }}
                    >
                      State
                    </span>
                    <span
                      style={{
                        color: "red",
                        paddingLeft:5,
                        paddingRight:5,
                        paddingTop: 3,
                        visibility: errorVal.state ? "visible" : "hidden",
                      }}
                    >
                      State value is invalid!
                    </span>
                  </div>
                  <div style={{ paddingLeft:30,
                        paddingRight:30, paddingTop:7,
                        paddingBottom:7, }}>
                    <div>
                      <button
                        style={{
                          backgroundColor: "red",
                        }}
                        className="btn w-100"
                        onClick={() => {
                          submit();
                        }}
                      >
                        {pageLoading && <Spinner borderColor="#ffffff" size="24px"/>}
                        {!pageLoading && <span style={{ color: "white", textAlign: "center" }}>
                          Save
                        </span>}
                      </button>
                    </div>
                    <div style={{ marginTop: 20 }}>
                      <button
                        className="btn btn-outline-dark w-100"
                        onClick={() => {
                          navigate('/auth/address');
                        }}
                      >
                        <span style={{ textAlign: "center" }}>Cancel</span>
                      </button>
                    </div>
                  </div>
                </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
