import React, { useState, useContext,useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../Navbar";
import AppContext from "../../../context/AppContext";
import Alert from "../../Alert"
import Spinner from "../../Spinner"
import MainOrder from "./MainOrder"
import { Helmet } from "react-helmet";

export default function PaymentPage(props) {
  const context = useContext(AppContext);
  const {  userdata,showAlert,alertValue,getUser,logError } = context;
  const [pageLoading, setPageLoading] = useState(false);
  const [submitLoading,setSubmitLoading] =  useState(false);
  const [order,setOrder] = useState({program_name : 'Not Available',order_rate : 0,total_amount : 0,total_weight : 0});
  let {id} = useParams();
  const navigate = useNavigate();
  const [alreadypaid,setALreadyPaid] = useState(false);

  useEffect(() => {
    setPageLoading(true);
    fetchOrders();
  }, []);
  
  const fetchOrders = async () => {
    try {
      if (userdata.userid) {
        const url = "https://expressv2.shortwash.com/combinedata/walletpayment.php";
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userid: userdata.userid,
            orderid: id,
          }),
        });
        const responseData = await response.json();
  
        if (responseData.response == "success") {
          if (responseData.message == "Already Paid") {
            setOrder(responseData.data[0]);
            setALreadyPaid(true);
          }else{
            setOrder(responseData.data[0]);
            setALreadyPaid(false);
          }
        }
        if (responseData.response == "error") {
          // console.log(responseData);
        }
      }else{
        let user = getUser();
        if (user) {
          const url = "https://expressv2.shortwash.com/combinedata/walletpayment.php";
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userid: user.userid,
              orderid: id,
            }),
          });
          const responseData = await response.json();
  
          if (responseData.response == "success") {
            if (responseData.message == "Already Paid") {
              setOrder(responseData.data[0]);
              setALreadyPaid(true);
            }else{
              setOrder(responseData.data[0]);
              setALreadyPaid(false);
            }
          }
          if (responseData.response == "error") {
            // console.log(responseData);
          }
        }
      }
    } catch (error) {
      logError(error,{cause:"fetchorders functions - paymentpage web",userdata});
    }
    setPageLoading(false);

  };
  const onsubmit = async()=>{
    setSubmitLoading(true);
    let user = getUser();
    try {
      if (userdata.userid && order.wallet >= (order.total_amount * 50)/100 && !alreadypaid) {
        const url = "https://expressv2.shortwash.com/combinedata/paywithwallet.php";
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userid: userdata.userid,
            orderid: id,
            walletpayment : (order.total_amount * 50)/100,
            walletbalance : order.wallet - (order.total_amount * 50)/100
          }),
        });
        const responseData = await response.json();
  
        if (responseData.response == "success") {
          showAlert('green',`Paid ₹${(order.total_amount * 50)/100} for order ${id} with wallet`,3000);
          fetchOrders();
        }
        if (responseData.response == "error") {
          showAlert('red','Something went wrong please report it at complaint@shortwash.com',3000);
        }
      }else{
        if (user) {
          const url = "https://expressv2.shortwash.com/combinedata/paywithwallet.php";
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userid: user.userid,
              orderid: id,
              walletpayment : (order.total_amount * 50)/100,
              walletbalance : order.wallet - (order.total_amount * 50)/100
            }),
          });
          const responseData = await response.json();
  
          if (responseData.response == "success") {
            showAlert('green',`Paid ₹${(order.total_amount * 50)/100} for order ${id} with wallet`,3000);
            fetchOrders();
          }
          if (responseData.response == "error") {
            showAlert('red','Something went wrong please report it at complaint@shortwash.com',3000);
          }
        }
      }
    } catch (error) {
      logError(error,{cause:"onsubmit functions - paymentpage web",userdata});
    }
    setSubmitLoading(false);
  }
  
  return (
    <React.Fragment>
    <div style={{ minHeight: "100vh", position: "relative" }}>
    <Helmet>
    <title>Shortwash - Pay for your order</title>
    </Helmet>
      <Navbar page="orders" />
      {alertValue && (
        <Alert theme={alertValue.theme} message={alertValue.message} />
      )}
      <div style={{ width: "100%", minheight: "100vh" }}>
      <div style={{ width: "100%", height: "100%" }}>
        <div
          style={{
            backgroundColor: "white",
            paddingBottom: 30,
            minheight:"calc(100vh - 125px)"
          }}
          className="simpleflex"
        >
            {pageLoading && <div className="simpleflex py-2" style={{height:'calc(100vh - 125px)'}}><Spinner borderColor="#000000" size="28px"/></div>}

            <div className="paymentpage-outer-container"
              style={{
                display: pageLoading == false ? "flex" : "none",
                paddingLeft:15,
                paddingRight:15,
                flexDirection:'column'
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: 30,
                }}
              >
                <span style={{ color: "grey", fontSize: 17,textAlign : 'center' }}>
                  Shortwash Wallet
                </span>
                <span
                  style={{ color: "black", fontSize: 17, fontWeight: "500",textAlign : 'center' }}
                >
                  {" "}
                  &#8377; {order.wallet}
                </span>
              </div>
              <div className="overview-table-wrapper">
                <div
                  className="table-wrapper"
                  style={{
                    borderRadius: 15,
                    overflow: "hidden",
                    borderColor: "rgb(225,225,225)",
                    borderWidth: 1,
                    borderStyle:'solid',
                    marginTop:20,
                    marginBottom:20
                  }}
                >
                  <div
                    className="table-heading"
                    style={{
                      backgroundColor: "rgb(240,240,240)",
                      paddingLeft:16,
                      paddingRight:16,
                      paddingTop:8,
                      paddingTop:8
                    }}
                  >
                    <span>Order Details</span>
                  </div>
                  <div
                    className="table-content"
                    style={{ display: "flex"}}
                  >
                  <div
                  className="table-particular"
                  style={{
                    flex: 1,
                    height: "100%",
                    borderBottomColor: "rgb(225,225,225)",
                    borderBottomWidth: 1,
                    borderBottomStyle:"solid",
                    flexDirection:'row'
                  }}
                >
                <div
                className="washprogram"
                style={{
                  flexDirection :'row',
                  borderBottomColor: "rgb(225,225,225)",
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid",
                  paddingTop:8,
                  paddingBottom:8,
                  display:'flex',
                  paddingLeft:16,
                  paddingRight:16
                }}
              >
                <div style={{flex:2}}>
                <span >Wash Program - </span>
                </div>
                <div style={{ textAlign: "center",flex : 3 }}>
                <span >
                  {" "}
                  {order.program_name}
                </span>
                </div>
              </div>
              <div
                className="washprogram"
                style={{
                  flexDirection :'row',
                  borderBottomColor: "rgb(225,225,225)",
                  borderBottomWidth: 1,
                  borderBottomStyle:'solid',
                  paddingTop:8,
                  display:'flex',
                  paddingBottom:8,
                  paddingLeft:16,
                  paddingRight:16
                }}
              >
                <span style={{flex : 2}}>Order Rate - </span>
                <span style={{ textAlign: "center",flex : 3 }}>
                  {" "}
                  &#8377; {order.order_rate}/kg{" "}
                </span>
              </div>
              <div
                className="washprogram"
                style={{
                  flexDirection : 'row',
                  borderBottomColor: "rgb(225,225,225)",
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid",
                  display:'flex',
                  paddingTop:8,
                  paddingBottom:8,
                  paddingLeft:16,
                  paddingRight:16
                }}
              >
                <span style={{flex : 2}}>Total Weight - </span>
                <span style={{ textAlign: "center",flex : 3 }}> {order.total_weight} kg </span>
              </div>
              <div
                className="washprogram"
                style={{
                  flexDirection : "row",
                  borderBottomColor: "rgb(225,225,225)",
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid",
                  display:'flex',
                  paddingTop:8,
                  paddingBottom:8,
                  paddingLeft:16,
                  paddingRight:16
                }}
              >
                <span style={{flex : 2}}>Order Amount - </span>
                <span style={{ textAlign: "center",flex : 3 }}>
                  {" "}
                  &#8377; {order.total_amount}{" "}
                </span>
                </div>
              <div
                className="washprogram"
                style={{
                  flexDirection :"row",
                  borderBottomColor: "rgb(225,225,225)",
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid",
                  paddingTop:8,
                  display:'flex',
                  paddingBottom:8,
                  paddingLeft:16,
                  paddingRight:16
                }}
              >
                <span style={{flex : 2}}>Shortwash Wallet - </span>
                <span style={{ textAlign: "center", color: "red",flex : 3 }}>
                  {" "}
                  &#8377;{(order.total_amount * 50)/100}{" "}
                </span>
              </div>
              <div
                className="washprogram"
                style={{
                  flexDirection : 'row',
                  borderBottomColor: "rgb(225,225,225)",
                  borderBottomWidth: 1,
                  borderBottomStyle: "solid",
                  paddingTop:8,
                  display:'flex',
                  paddingBottom:8,
                  paddingLeft:16,
                  paddingRight:16
                }}
              >
                <span style={{flex : 2}}>Final Amount - </span>
                <span style={{ textAlign: "center",flex : 3 }}>
                  {" "}
                  &#8377; {(order.total_amount * 50)/100}{" "}
                </span>
                </div>
                </div>
                  </div>
                  <div
                    className="table-footer"
                    style={{
                      backgroundColor: "rgb(240,240,240)",
                      paddingTop:8,
                      paddingBottom:8,
                      paddingLeft:16,
                      paddingRight:16
                    }}
                  >
                    <span style={{ textAlign: "center" }}>
                      You can only pay 50% of your order amount through
                      shortwash wallet and rest of the amount can be paid to
                      delivery executive via <strong>UPI</strong> or <strong>Cash</strong>.
                    </span>
                  </div>
                </div>
              </div>
              {((order.total_amount * 50)/100 > order.wallet && !alreadypaid) && <div style={{paddingLeft:16,textAlign : 'center',paddingRight:16}}>
                    <span style={{color : 'red'}}>You can't use this payment method due to insufficient balance</span>
              </div>}
              {alreadypaid && <div style={{textAlign : 'center',paddingLeft:16,paddingRight:16}}>
                    <span style={{color : 'green'}}>You had already paid &#8377;{(order.total_amount * 50)/100} for this order with wallet</span>
              </div>}
              {((order.total_amount * 50)/100 <= order.wallet && !alreadypaid) && <div
                style={{ marginTop : 20 }}
              >
              {!submitLoading && <div className="d-flex justify-content-around">
              <button
                style={{
                  width: 100,
                  paddingTop:7,
                  paddingBottom:7
                }}
                className="btn btn-outline-dark"
                onClick = {()=>{
                  navigate('/auth/orders/')
                }}
              >
                <span style={{ textAlign: "center" }}>Cancel</span>
              </button>
              <button
                style={{
                  backgroundColor: "red",
                  width: 100,
                  color : 'white',
                  paddingTop:7,
                  paddingBottom:7
                }}
                onClick={()=>{
                  console.log('logged');
                  onsubmit();
                }}
                className="btn"
              >
                <span style={{ textAlign: "center", color: "white" }}>
                  Pay
                </span>
              </button>
              </div>}
              {submitLoading && <div className="simpleflex px-2"><Spinner borderColor="#000000" size ="28px"/></div>}
              </div>}
            </div>
        </div>
      </div>

      </div>
    </div>
  </React.Fragment>
  );
}
