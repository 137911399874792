import React, { useState, useEffect } from "react";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useContext } from "react";
import AppContext from "../../context/AppContext";
import { useNavigate, useRouteLoaderData } from "react-router-dom";
import Alert from "../Alert";
import Spinner from "../Spinner";
import { Helmet } from "react-helmet";

export default function Newpassword() {
  const context = useContext(AppContext);
  const { fpUser, setFpUser, sendDataToApp, alertValue, showAlert,logError } = context;
  const [pageStage, setPageStage] = useState("loading");
  const navigate = useNavigate();
  const [nploader, setNploader] = useState(false);
  useEffect(() => {
    if (!fpUser) {
      setTimeout(() => {
        showAlert("red", "Something went wrong, please try again", 5000);
        console.log("no user found");
        navigate("/auth/forgotpassword");
      }, 1500);
    } else {
      setPageStage(1);
    }
  }, [fpUser]);

  const [npField, setNPField] = useState({
    newpassword: "",
    confirmpassword: "",
  });
  const [npError, setNPError] = useState({
    newpassword: false,
    confirmpassword: false,
  });
  const onchange = (e) => {
    setNPField((stat) => {
      return { ...stat, [e.target.id]: e.target.value };
    });
  };
  const onsubmit = async () => {
    setNploader(true);
    try {
      setNPError({
        newpassword: false,
        confirmpassword: false,
      });

      let npErr = false;
      let cnpErr = false;

      // validating new password field
      if (npField.newpassword.length < 6) {
        // enter a valid new password
        setNPError((stat) => {
          return { ...stat, newpassword: true };
        });
        npErr = true;
      } else {
        npErr = false;
      }
      // validating confirm password field
      if (npField.newpassword != npField.confirmpassword) {
        // enter a valid confirm password
        setNPError((stat) => {
          return { ...stat, confirmpassword: true };
        });
        cnpErr = true;
      } else {
        cnpErr = false;
      }
      if (!npErr && !cnpErr) {
        console.log("we are good to go!");
        let url = "https://expressv2.shortwash.com/login/updateuserdetails.php";
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: fpUser.userid,
            password: npField.newpassword,
          }),
        });
        const responseData = await response.json();

        if (responseData.response == "success") {
          setNploader(false);
          showAlert(
            "green",
            "Password changed successfully, please login with new password",
            3000
          );
          navigate("/auth/login");
        }
        if (responseData.response == "error") {
          setNploader(false);
          showAlert(
            "red",
            "Something went wrong while updating password, complaint at complaint@shortwash.com",
            3000
          );
          console.log("error happens while updating password");
          console.log(responseData);
        }
      }
    } catch (error) {
      logError(error,{data:fpUser,cause:"onsubmit function - newpassword web"})
    }

    setNploader(false);
  };
  function cancelValidation() {
    setNPField({
      newpassword: "",
      confirmpassword: "",
    });
    setNPError({
      newpassword: false,
      confirmpassword: false,
    });
    setFpUser(null);
    setPageStage("loading");
    setTimeout(() => {
      navigate("/auth/forgotpassword");
    }, 1500);
  }
  
  return (
    <React.Fragment>
    <Helmet>
    <title>Shortwash - Set your new password</title>
    </Helmet>
      {alertValue && (
        <Alert theme={alertValue.theme} message={alertValue.message} />
      )}
      {pageStage == 1 && (
        <div
          className="container simpleflex"
          style={{ height: "100vh", flexDirection: "column" }}
        >
          <div
            className="text-center"
            style={{
              marginBottom: 30,
            }}
          >
            <span style={{ fontSize: 32, fontWeight: "300" }}>
              Set new password
            </span>
          </div>
          <div style={{ position: "relative" }} className="w-75">
            <span
              style={{
                fontSize: 15,
                color: "grey",
                position: "absolute",
                backgroundColor: "white",
                paddingLeft: 5,
                paddingRight: 5,
                zIndex: 1,
                left: 12,
                top: -10,
                color: npError.newpassword ? "red" : "grey",
              }}
            >
              New Password
            </span>
            <input
              style={{
                borderColor: "rgb(225,225,225)",
                borderWidth: 1,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 15,
                paddingBottom: 15,
                color: "black",
                fontSize: 16,
                borderColor: npError.newpassword ? "red" : "rgb(225,225,225)",
                width: "100%",
              }}
              placeholder="Your extreme dangerous password"
              value={npField.newpassword}
              id="newpassword"
              type="password"
              className="form-control"
              onChange={onchange}
            />
            {/* errors*/}
            <div
              style={{
                visibility: npError.newpassword ? "visible" : "hidden",
                flexDirection: "row",
                color: "red",
                marginTop: 5,
                display:"flex",
                alignItems: "center",
              }}
            >
              <img
                style={{
                  height: 12,
                  width: 12,
                  marginRight: 5,
                }}
                src={require("../../assets/signup/info_red.png")}
              />
              <span style={{ color: "red", fontSize: 14 }}>
                Password should be of atleast 6 characters
              </span>
            </div>
          </div>

          <div style={{ position: "relative", marginTop: 30 }} className="w-75">
            <span
              style={{
                fontSize: 15,
                color: "grey",
                position: "absolute",
                backgroundColor: "white",
                paddingLeft: 5,
                paddingRight: 5,
                zIndex: 1,
                left: 12,
                top: -10,
                color: npError.confirmpassword ? "red" : "grey",
              }}
            >
              Confirm New Password
            </span>
            <input
              style={{
                borderColor: "rgb(225,225,225)",
                borderWidth: 1,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 15,
                paddingBottom: 15,
                color: "black",
                fontSize: 16,
                borderColor: npError.confirmpassword
                  ? "red"
                  : "rgb(225,225,225)",
                width: "100%",
              }}
              placeholder="Confirm your password"
              value={npField.confirmpassword}
              id="confirmpassword"
              type="password"
              className="form-control"
              onChange={onchange}
            />
            {/* errors*/}
            <div
              style={{
                visibility: npError.confirmpassword ? "visible" : "hidden",
                flexDirection: "row",
                color: "red",
                marginTop: 5,
                display:'flex',
                alignItems: "center",
              }}
            >
              <img
                style={{
                  height: 12,
                  width: 12,
                  marginRight: 5,
                }}
                src={require("../../assets/signup/info_red.png")}
              />
              <span style={{ color: "red", fontSize: 14 }}>
                Confirm password doesn't matched with new password
              </span>
            </div>
          </div>

          <div className="w-75" style={{ marginTop: 30 }}>
            <button
              className="btn w-100"
              style={{ backgroundColor: "red", color: "white" }}
              onClick={onsubmit}
            >
            {nploader && <Spinner borderColor="#ffffff" size="24px"/>}
            {!nploader && 'Update password'}
            </button>
            <button
              className="btn w-100"
              style={{
                backgroundColor: "white",
                color: "black",
                borderColor: "black",
                borderWidth: 1,
                marginTop: 15,
              }}
              onClick={cancelValidation}
            >
              Cancel
            </button>
          </div>
        </div>
      )}
      {/*loading screen*/}
      {pageStage == "loading" && (
        <div className="simpleflex" style={{ height: "100vh" }}>
          <Spinner borderColor="#000000" size="28px" />
        </div>
      )}
    </React.Fragment>
  );
}
