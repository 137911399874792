import React, { useState, useContext,useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../Navbar";
import Footer from "../Footer";
import AppContext from "../../context/AppContext";
import Alert from "../Alert"
import Spinner from "../Spinner"
import MainOrder from "./OrdersComponent/MainOrder"
import { Helmet } from "react-helmet";

export default function Orders(props) {
  const navigate = useNavigate();
  const context = useContext(AppContext);
  const {alertValue,showAlert,userdata,getUser,logError} = context;
  let [loading, setLoading] = useState(false);
  let [navLoader,setNavLoader] = useState(false);
  const [windowSize, setWindowSize] = useState({ size: {} });
  const [allorders,setAllOrders] = useState([]);
  const [pageLoading,setPageLoading] = useState(false); 
  const [loadmoreorders,setLoadmoreorders]= useState(false);
  const [pageNo,setPageNo] = useState(1); 
  const [maximumOrders,setMaximumOrders] = useState(false);

  useEffect(()=>{
    fetchOrders();
    setPageNo(1);
  },[])

  // orders
  const fetchOrders = async()=>{
    setPageLoading(true);
    try {
      if (userdata.userid) {
        const url = "https://expressv2.shortwash.com/orders/getorder.php";
        const response = await fetch(url,{
          method : "POST",
          headers : {
            "Content-Type" : "application/json"
          },
          body : JSON.stringify({
            userid : userdata.userid
          })
        });
        const responseData = await response.json();
        if (responseData.response == 'success') {
          setAllOrders(responseData.data);
        }
      }else{
        let user = getUser();
        if (user) {
          const url = "https://expressv2.shortwash.com/orders/getorder.php";
          const response = await fetch(url,{
            method : "POST",
            headers : {
              "Content-Type" : "application/json"
            },
            body : JSON.stringify({
              userid : user.userid
            })
          });
          const responseData = await response.json();
          if (responseData.response == 'success') {
            setAllOrders(responseData.data);
          }
        }
      }
    } catch (error) {
      logError(error,{data:userdata,cause:"fetchorders function - orders web"});
    }
    setPageLoading(false);
  }
  
  const fetchMoreOrders = async () => {
    setLoadmoreorders(true);
    try {
      if (userdata.userid) {
        const url = "https://expressv2.shortwash.com/orders/getmoreorder.php";
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userid: userdata.userid,
            offset : pageNo*10
          }),
        });
        const responseData = await response.json();
        if (responseData.response == "success") {
          if (responseData.data.length > 0) {
            if (responseData.data.length < 10) {
              setMaximumOrders(true);
            }
            setAllOrders(allorders.concat(responseData.data));
            setPageNo(pageNo+1);
          }
        }
        if (responseData.response == 'error') {
          if (responseData.message == 'out of orders') {
            setMaximumOrders(true);
          }
        }
      }
    } catch (error) {
      logError(error,{data:userdata,cause:"fetchmoreorders function - orders web"});
    }
    setLoadmoreorders(false);
  };
  useEffect(()=>{
    if (!userdata.loggedin) {
      let getUserData = getUser();
      if (!getUserData) {
        setNavLoader(true);
        setTimeout(() => {
          navigate('/auth/login');
        }, 3000);
      }
    }
  },[userdata])
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
      <Helmet>
      <title>Shortwash - Orders</title>
      </Helmet>
        <Navbar page="auth" />
        {alertValue && <Alert theme={alertValue.theme} message={alertValue.message}/>}
        
        {navLoader && <div className="simpleflex" style={{height: "calc(100vh - 86px)"}}>
        <Spinner borderColor="#000000" size="28px"/>
        </div>}

        {!navLoader && <div >
          <div
            style={{
              backgroundColor: "white",
              position : 'relative'
            }}
            className="simpleflex"
          >
            <div className="order-outer-wrapper" style={{paddingBottom : 40}}>
            
              <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: 20,
                  marginBottom: 20,
                  textAlign:'center'
                }}
              >
                <span style={{
                  fontSize:32,
                  fontWeight:'300'
                }}>Orders</span>
              </div>
              <div className="order-wrapper">
              {/* no orders */}
              {(allorders.length == 0 && !pageLoading) && <div style={{ paddingTop: 20,paddingBottom : 20,height:'calc(100vh - 125px - 86px)' }} className="simpleflex">
              <span style={{ textAlign: "center", fontSize: 16,color : 'grey' }}>
                You don't have any recent orders.
              </span>
            </div>}
            {/* loading screen */}
            {pageLoading && <div className="simpleflex py-1" style={{backgroundColor:"white"}}><Spinner borderColor="#000000" size="28px"/></div>}
            {/* all orders */}  
            {allorders.map((item) => {
                return (
                  <MainOrder
                    order={{ status: item.washing_stage, order: item }}
                    key ={item.orderid}
                  />
                );
              })}
              {/* load more */}
              {
                (allorders.length >= 10 && !pageLoading && !maximumOrders) && <div
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop : 20,
                  display:'flex',
                  paddingBottom:40,
                  width: "100%",
                  cursor:'pointer'
                }}
                onClick={() => {
                  fetchMoreOrders();
                }}
              >
                <div style={{ flexDirection: "row",justifyContent : 'center',alignItems : 'center',display:'flex' }}>
                  {!loadmoreorders && <div><span>Load more orders</span>
                  <img
                    style={{ width: 16, height: 16 }}
                    src={require("../../assets/orders/next.png")}
                  /></div>}
                  {loadmoreorders && <Spinner borderColor="#000000" size="30px"/>}
                </div>
              </div>
              }
              </div>
            </div>
          </div>
        </div>}
      </div>
    </React.Fragment>
  );
}
