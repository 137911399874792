import React, { useContext, useEffect, useState } from "react";
import AppContext from "../context/AppContext";

export default function Alert(props) {
  const context = useContext(AppContext);
  const { setAlertValue } = context;
  const [theme, setTheme] = useState({
    background: "green",
    text: "white",
  });
  function themeSet() {
    switch (props.theme) {
      case "dark":
        setTheme({
          background: "black",
          text: "white",
        });
        break;
      case "light":
        setTheme({
          background: "white",
          text: "black",
        });
        break;
      case "green":
        setTheme({
          background: "green",
          text: "white",
        });
        break;
      case "red":
        setTheme({
          background: "red",
          text: "white",
        });
        break;

      default:
        setTheme({
          background: "green",
          text: "white",
        });
        break;
    }
  }
  useEffect(() => {
    themeSet();
  }, []);
  return (
    <div
      style={{ position: "fixed", bottom: 45, right: 0, zIndex: 99999 }}
      className="simpleflex alert-outer-wrapper"
    >
      <div
        className="py-3 px-3 d-flex justify-content-between alert-wrapper"
        style={{
          backgroundColor: theme.background,
          borderRadius: 6,
          minWidth: "300px",
          borderColor: "black",
          borderWidth: theme.background == "white" ? 1 : 0,
          borderStyle: "solid",
        }}
      >
        <span style={{ fontSize: 15, color: theme.text }}>{props.message}</span>
        <span
          style={{
            fontSize: 15,
            color: theme.text,
            cursor: "pointer",
            marginLeft: 20,
          }}
          onClick={() => {
            setAlertValue(null);
          }}
          className="simpleflex"
        >
          x
        </span>
      </div>
    </div>
  );
}
