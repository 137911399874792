import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../../../context/AppContext"
export default function MainAddress({ address,deleteAddress }) {
  const context = useContext(AppContext);
  const navigate = useNavigate();
  const {   getspecificAddress } = context;

  

  return (
    <div
      className="addresswrapper"
      style={{
        width: "92%",
        borderColor: "#E1E1E1",
        borderWidth: 2,
        paddingLeft: 10,
        paddingRight: 10,
        paddingTop: 10,
        marginTop: 10,
        borderRadius: 15,
        marginTop: 10,
        marginBottom: 10,
        marginLeft: "4%",
        marginRight: "4%",
        display:'flex',
        flexDirection:'column',
        borderStyle:'solid',

      }}
    >
      <span>{address.name}</span>
      <span>
        {address.address1}, {address.address2}
      </span>
      <span>{address.landmark}</span>
      <span>
        {address.city}, {address.state} - {address.pincode}
      </span>
      <span>{address.mobile}</span>
      <div
        style={{
          flexDirection: "row",
          borderTopColor: "#E1E1E1",
          borderTopWidth: 1,
          marginTop: 10,
          display:'flex'
        }}
      >
        <div
          style={{ width: "50%", paddingTop: 10, paddingBottom: 10 }}
          onClick={() => {
            navigate(`/auth/address/editaddress/${address.addressid}`);
          }}
        >
          <div style={{ justifyContent: "center", alignItems: "center",display:'flex',cursor:'pointer' }}>
            <img src={require("../../../assets/address/edit.png")} />
          </div>
        </div>
        <div
          style={{ width: "50%", paddingTop: 10, paddingBottom: 10 }}
          onClick={() => {
            deleteAddress(address.addressid);
          }}
        >
          <div style={{ justifyContent: "center", alignItems: "center",display:'flex',cursor:'pointer' }}>
            <img src={require("../../../assets/address/delete.png")} />
          </div>
        </div>
      </div>
    </div>
  );
}
