import React, { useState } from "react";
import { useEffect } from "react";
import Context from "./AppContext";

export default function AppState(props) {
  const [washprogram, setWashProgram] = useState(null);
  const [timeslot, setTimeSlot] = useState("05:00 - 09:00 PM");
  const [pickupAdd, setPickupAdd] = useState(null);
  const [userdata, setUserData] = useState({loggedin : false});
  const [pageLoading, setPageLoading] = useState(false); 
  const [allAddress, setAllAddress] = useState([]);
  const [allwashPrograms, setAllWashPrograms] = useState([]);
  const [washprogramLoading, setWashprogramLoading] = useState(false);
  const [washprogramError, setWashProgramError] = useState(false);
  const [hreviewError, setHreviewError] = useState(false);
  const [offerTransaction, setOfferTransaction] = useState([]);
  const [offerValid, setOfferValid] = useState(false);
  const [activeOffer, setActiveOffer] = useState({ offer_rate: 0 });
  const [allReviews,setAllReviews] = useState([]);
  const [hreviewloading,sethreviewloading] = useState(null);
  const [addingReviewLoading,setAddingReviewLoading] = useState(false);
  const [guestAddress, setGuestAddress] = useState({
    name: "",
    mobile: "",
    address1: "",
    address2: "",
    landmark: "",
    pincode: "122052",
    city: "Gurugram",
    state: "Haryana",
  });
  const [guestMode, setGuestMode] = useState(false);
  const [activeWashProgram,setActiveWashProgram] = useState({program_rate : 0});
  let [signupFieldDetails, setSignupFieldDetails] = useState({
    name: "",
    mobile: "",
    email: "",
    password: "",
    referral: "",
    verification:""
  });
  let [signupErrorDetails, setSignupErrors] = useState({
    password: false,
    name: false,
    mobileLen: false,
    mobileExists: false,
    email: false,
    verification: false,
  });
  const [signupconfirmRes,setSignupConfirmRes] = useState(null);
  const [fpUser,setFpUser] = useState(null);
  const [alertValue,setAlertValue] = useState(null);

  const [selectedProgram, setSelectedProgram] = useState({program_name : null});
  const [variations,setVariations] = useState([]);
  const [programRate, setProgramRate] = useState(0);
  const [detergent, setDetergent] = useState(null);
  const [conditioner, setConditioner] = useState(null);
  const [ironType, setIronType] = useState(null);
  const [orderStage, setOrderStage] = useState(0);
  const [area, setArea] = useState("none");





  const showPageLoading = (val) => {
    setPageLoading(val);
  };
  const showAlert = (theme,message,duration=3000) => {
    setAlertValue({
      theme,message
    });
    setTimeout(() => {
      setAlertValue(null);
    },duration);
  };

  // authentication
  const loginUser = async(mobile,password)=>{
    try {
      let response = await fetch("https://expressv2.shortwash.com/login/login.php",{
      method : "POST",
      headers : {
        "Content-Type" : 'application/json'
      },
      body : JSON.stringify({mobile,password})
    });
    let responseData = await response.json();
    return responseData;
    } catch (error) {
      logError(error,{data:{mobile,password},cause:"loginUser function - appstate web"})
    }
  }
  const signupUser = async(data)=>{
    try {
      let response = await fetch("https://expressv2.shortwash.com/login/signup.php",{
      method : "POST",
      body : JSON.stringify(data)
    });
    let responseData = await response.json();
    return responseData;
    } catch (error) {
      logError(error,{data,cause:"signupUser function - appstate web"})
    }
  }
  const updateUser = async(data)=>{
    try {
      let response = await fetch("https://expressv2.shortwash.com/login/updateuserdetails.php",{
      method : "POST",
      headers : {
        "Content-Type" : 'application/json'
      },
      body : JSON.stringify(data)
    });
    let responseData = await response.json();
    fetchUser();
    return responseData;
    } catch (error) {
      logError(error,{data,cause:"updateUser function - appstate web"})
    }
  }
  const fetchUser = async()=>{
    try {
      let url = "https://expressv2.shortwash.com/login/getuserdetails.php";
      if (userdata.loggedin) {
        let data = userdata;
        const response = await fetch(url,{
          method : "POST",
          headers : {
            'Content-Type' : 'application/json'
          },
          body : JSON.stringify({token : data.userid})
        });
        const responseData = await response.json();
        if (responseData.response == 'success') {
          if (responseData.message == 'User Data') {
            setUserData({
              loggedin : true,
              name : responseData.data.name,
              email : responseData.data.email,
              mobile : responseData.data.mobile,
              userid : responseData.data.userid,
              wallet : responseData.data.wallet
            });
          }
        }        
      }else{
        let user = getUser();
        if (user) {
        const response = await fetch(url,{
          method : "POST",
          headers : {
            'Content-Type' : 'application/json'
          },
          body : JSON.stringify({token : user.userid})
        });
        const responseData = await response.json();
        if (responseData.response == 'success') {
          if (responseData.message == 'User Data') {
            setUserData({
              loggedin : true,
              name : responseData.data.name,
              email : responseData.data.email,
              mobile : responseData.data.mobile,
              userid : responseData.data.userid,
              wallet : responseData.data.wallet
            });
          }
        } 
        }
      }      
    } catch (error) {
      logError(error,{data:userdata,cause:"fetchUser functions - appstate web"})
    }
    
  }




  
  const getallproandrev = async () => {
    try {
      setWashprogramLoading(true);
      sethreviewloading(true);
      let url = "https://expressv2.shortwash.com/combinedata/proandrev.php";

    const response = await fetch(url, {
      method: "GET",
    });
    const responseData = await response.json();
    if (responseData.response === "success") {
      setAllWashPrograms(responseData.data.program);
      setAllReviews(responseData.data.reviews);
      setWashProgramError(false);
      setHreviewError(false);
      sethreviewloading(false);
      setVariations(responseData.data.variations);

    }
    if (responseData.response === "error") {
      setWashProgramError(true);
      setHreviewError(true);
    }
    setWashprogramLoading(false);
    sethreviewloading(false);
    } catch (error) {
      setWashProgramError(true);
      setHreviewError(true); 
      setWashprogramLoading(false);
      sethreviewloading(false);     
      logError(error,{cause:"getallproandrev function - appstate web"})
    }
    

  };

  // address functions
  const addAddress = async (data) => {
    try {
      if (userdata.loggedin !== false) {
        if (userdata.userid) {
          let url = "https://expressv2.shortwash.com/address/address.php";
          let reqData = { ...data, userid: userdata.userid };
  
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(reqData),
          });
  
          const responseData = await response.json();
          if (responseData.response === "success") {
            fetchAddress();
            showAlert(
              "green",
              `Address added successfully`,
              3000
            );
          }
          return responseData;
        }
      }else{
        let user = getUser();
        if (user) {
          let url = "https://expressv2.shortwash.com/address/address.php";
          let reqData = { ...data, userid: user.userid };
  
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(reqData),
          });
  
          const responseData = await response.json();
          if (responseData.response === "success") {
            fetchAddress();
          }
          return responseData;
        }
      }
    } catch (error) {
      logError(error,{data:{userdata,data},cause:"addaddress function - appstate web"})
    }
    
  };
  async function logError(error, data = null) {
    if (error.message != "Failed to fetch") {
      
      let jsonData = JSON.stringify({
        message: error.message,
        stack: error.stack,
        dependencies: { data },
      });
      const response = await fetch(
        "https://expressv2.shortwash.com/error/adderror.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({error : jsonData}),
        }
      );
      const responseData = await response.json();
      if (responseData.response == "success") {
        showAlert("red", `Error - '${error.message}' posted to developers`, 5000);
      }
    }
  }
  const getAllAddress = async () => {
    try {
      if (userdata.loggedin !== false) {
        if (userdata.userid) {
          let url = "https://expressv2.shortwash.com/address/getaddress.php";
          let reqData = { userid: userdata.userid };
  
          const response = await fetch(url, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(reqData),
          });
          const responseData = await response.json();
          return responseData;
        }
      }else{
        let user = getUser();
        if (user) {
            let url = "https://expressv2.shortwash.com/address/getaddress.php";
            let reqData = { userid: user.userid };
    
            const response = await fetch(url, {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(reqData),
            });
            const responseData = await response.json();
            return responseData;
        }else{
  
  
          setAllAddress([]);
        }
      }
    } catch (error) {
      logError(error,{data:userdata,cause:"getalladdress functions - appstate web"})
    }
    
  };
  const fetchAddress = async () => {
    setPageLoading(true);
    try {
      if (userdata.loggedin !== false) {
        let reqData = await getAllAddress();
        if (reqData.response === "success") {
          setAllAddress(reqData.data);
        }
        if (reqData.response === "error") {
          setAllAddress([]);
        }
      }else{
        let user = getUser();
        if (user) {
          let reqData = await getAllAddress();
          if (reqData.response === "success") {
            setAllAddress(reqData.data);
          }
          if (reqData.response === "error") {
            setAllAddress([]);
          }
        }else{
          setAllAddress([]);
        }
      }
    } catch (error) {
      logError(error,{cause:"fetchAddress function - appstate web"})
    }
    
    setPageLoading(false);
  };
  

  // user offers
  const getuserOffers = async () => {
    try {
      if (userdata.userid) {
        let url = "https://expressv2.shortwash.com/offers/getoffertransaction.php";
        let reqData = { userid: userdata.userid };
  
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reqData),
        });
  
        const responseData = await response.json();
        if (responseData.response === "success") {
          setOfferTransaction(responseData.data);
        }else{
          setOfferTransaction([]);
        setActiveOffer({offer_rate : 0});
        setOfferValid(false);
        }
      }else{
        let user  = getUser();
        if (user) {
          let url = "https://expressv2.shortwash.com/offers/getoffertransaction.php";
        let reqData = { userid: user.userid };
  
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(reqData),
        });
  
        const responseData = await response.json();
        if (responseData.response === "success") {
          setOfferTransaction(responseData.data);
        }
        }else{
          setOfferTransaction([]);
        setActiveOffer({offer_rate : 0});
        setOfferValid(false);
        }
      }
    } catch (error) {
      logError(error,{data:userdata,cause:"getuseroffers functions - appstate web"})
    }
    
  };

  // reviews 
  const getAllReviews = async () => {
    sethreviewloading(true);
    try {
      let url = "https://expressv2.shortwash.com/reviews/getreviews.php";
      const response = await fetch(url, {
        method: "GET"
      });

      const responseData = await response.json();
      if (responseData.response === "success") {
        setAllReviews(responseData.data);
      }  
    } catch (error) {
      logError(error,{cause:"getallreviews functions - appstate web"})
    }
    sethreviewloading(false);
  };
  const addReview = async(star,message)=>{
    setAddingReviewLoading(true);
    try {
      if (userdata.userid) {
        let data = {
          userid : userdata.userid,
          star,
          message
        }
        let url = "https://expressv2.shortwash.com/reviews/addreview.php";
        const response = await fetch(url,{
          method : 'POST',
          headers : {
            'Content-Type' : 'application/json'
          },
          body : JSON.stringify(data)
        });
        const responseData = await response.json();
        if (responseData.response ==='success') {
          await getAllReviews();
          setAddingReviewLoading(false);
          return responseData;
        }else{
          setAddingReviewLoading(false);
          return responseData;
        }
      }else{
        let user = getUser();
        if (user) {
          let data = {
            userid : user.userid,
            star,
            message
          }
          let url = "https://expressv2.shortwash.com/reviews/addreview.php";
          const response = await fetch(url,{
            method : 'POST',
            headers : {
              'Content-Type' : 'application/json'
            },
            body : JSON.stringify(data)
          });
          const responseData = await response.json();
          if (responseData.response ==='success') {
            await getAllReviews();
            setAddingReviewLoading(false);
            return responseData;
          }else{
            setAddingReviewLoading(false);
            return responseData;
          }
        }
      }
    } catch (error) {
      logError(error,{data:{star,message,userdata},cause:"addreview functions - appstate web"})
    }
    
  }

  // order
  const addOrder = async()=>{
    try {
      if (userdata.userid && pickupAdd && timeslot && selectedProgram) {
        const url = "https://expressv2.shortwash.com/orders/addorder.php";
        let data = {
          userid : userdata.userid,
          addressid : pickupAdd,
          timeslot,
          program : selectedProgram.programid,
          programrate : selectedProgram.program_rate,
          detergent : detergent ? detergent : 'null',
          conditioner : conditioner ? conditioner : 'null',
          iron : ironType ? ironType : 'null'
        }
        if (offerValid) {
          let od_rate = activeWashProgram.program_rate * (100 - activeOffer.offer_rate)/100;
          data = {...data,offerid : activeOffer.offerid,offerrate : activeOffer.offer_rate,orderrate : od_rate}
        }else {
          data = {...data,orderrate : programRate};
        }
        const response = await fetch(url,{
          method : 'POST',
          headers : {
            'Content-Type' : "application/json"
          },
          body : JSON.stringify(data)
        })
        const responseData = await response.json();
        return responseData;
        // setOrderStatus(responseData);
      }else {
        return {response : 'error'};
        // setOrderStatus({response : 'error'});
      }
    } catch (error) {
      logError(error,{data:{userdata,pickupAdd,timeslot,selectedProgram},cause:"addOrder function - appstate webview"})
    }
    
  }

  async function sendDataToApp(data) {
    window.ReactNativeWebView.postMessage(JSON.stringify(data));
  } 


  // updating address and user specific offers 
  useEffect(() => {
    getuserOffers();
    fetchAddress();
    if (userdata.loggedin) {
      setGuestMode(false);
      setGuestAddress({
        name: "",
        mobile: "",
        address1: "",
        address2: "",
        landmark: "",
        pincode: "122052",
        city: "Gurugram",
        state: "Haryana",
      });
    }
  }, [userdata]);

  // componentdidmount
  useEffect(() => {
    getallproandrev();
  },[]);

  // offer transaction
  useEffect(() => {
    for (let i = 0; i < offerTransaction.length; i++) {
      const element = offerTransaction[i];

      if (element.offer_validity.includes("days")) {
        let validity = parseInt(element.offer_validity.replace(" days", ""));
        let otdate = new Date(element.ot_date);
        let dump_date = new Date(element.ot_date);
        let expiry_date = dump_date;
        expiry_date.setDate(dump_date.getDate() + validity);
        let current_date = new Date();
        
        if (
          current_date.getTime() <= expiry_date.getTime() &&
          current_date.getTime() > otdate.getTime()
        ) {
          setOfferValid(true);
          setActiveOffer(element);
        } 
      }
    }
  }, [offerTransaction]);

  useEffect(()=>{
    let jsonUserData = window.sessionStorage.getItem('userdata');
    if (jsonUserData) {
      setUserData(JSON.parse(jsonUserData));
    }    
  },[]);
  function getUser(){
    let jsonUserData = window.sessionStorage.getItem('userdata');
    if (jsonUserData) {
      setUserData(JSON.parse(jsonUserData));
      return JSON.parse(jsonUserData);
    }else{
      return null;
    }
  }
  return (
    <Context.Provider
      value={{
        washprogram,
        getUser,
        setWashProgram,
        timeslot,
        setTimeSlot,
        userdata,
        logError,
        setUserData,
        pageLoading,
        allAddress,
        addAddress,
        pickupAdd,
        setPickupAdd,
        sendDataToApp,
        allwashPrograms,
        washprogramLoading,
        washprogramError,
        offerTransaction,
        activeOffer,
        setActiveOffer,
        setAllAddress,
        offerValid,
        setOfferValid,
        allReviews,
        hreviewloading,
        addReview,
        addingReviewLoading,
        getAllReviews,
        guestAddress,
        setGuestAddress,
        setGuestMode,
        guestMode,
        activeWashProgram,
        setActiveWashProgram,
        addOrder,
        fetchAddress,
        hreviewError,
        setAllReviews,
        sethreviewloading,
        signupUser,
        signupFieldDetails,
        setSignupFieldDetails,
        signupErrorDetails,
        setSignupErrors,
        signupconfirmRes,
        setSignupConfirmRes,
        fpUser,
        setFpUser,
        alertValue,
        showAlert,
        setAlertValue,
        loginUser,
        updateUser,
        selectedProgram,
        setSelectedProgram,
        variations,
        programRate,
        setProgramRate,
        conditioner,
        setConditioner,
        ironType,
        setIronType,
        detergent,
        setDetergent,
        orderStage,
        setOrderStage,
        area,
        setArea
      }}
    >
      {props.children}
    </Context.Provider>
  );
}
