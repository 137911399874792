import React from "react";

export default function Address(props) {
  return (
    <div
      className={`d-flex`}
      style={{ flexDirection: "column", fontSize: 14,cursor:'pointer' }}
      onClick={()=>{
        props.setpickup(props.data.addressid);
      }}
    >
      <span>{props.data.name}</span>
      <span>
        {props.data.address1}, {props.data.address2}, {props.data.landmark}, {props.data.city}, {props.data.state} - {props.data.pincode}
      </span>
      <span>{props.data.mobile}</span>
    </div>
  );
}
