import React, { useState, useContext,useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Navbar from "../../Navbar";
import AppContext from "../../../context/AppContext";
import Alert from "../../Alert"
import Spinner from "../../Spinner"
import MainOrder from "./MainOrder"
import { Helmet } from "react-helmet";

export default function ViewOrder(props) {
  const context = useContext(AppContext);
  const { userdata,alertValue,showAlert,getUser,logError } = context;
  const [windowSize, setWindowSize] = useState({ size: {} });
  const [pageLoading,setPageLoading] = useState(false);
  const [order,setOrder] = useState({
    orderid : ''
  });
  const navigate = useNavigate();
  const [eta,setETA] = useState(null);
  const [formattedDate,setOrderDate] = useState(null);
  const [orderstatus,setOrderStatus] = useState(null);
  const [wprogram,setWashProgram] = useState(null);
  const [totalweight,setTotalWeight] = useState(null);
  const [totalitems,setTotalItems] = useState(null);
  const [programRate,setProgramRate] = useState(null);
  const [offerrate,setOfferRate] = useState(null);
  const [totalamount,setTotalAmount] = useState(null);
  const [savetime,setSaveTime] = useState(null);
  const {id} = useParams();
  const [error,setError] = useState(null);
  
  useEffect(()=>{
    fetchOrders();
  },[])

  const fetchOrders = async()=>{
    setPageLoading(true);
    try {
      if (userdata.userid) {
        const url = "https://expressv2.shortwash.com/orders/getspecificorder.php";
        const response = await fetch(url,{
          method : "POST",
          headers : {
            "Content-Type" : "application/json"
          },
          body : JSON.stringify({
            userid : userdata.userid,
            orderid : id
          })
        });
        const responseData = await response.json();
  
        if (responseData.response == 'success') {
          setOrder(responseData.data[0
          ]);
          formatDate(responseData.data[0].order_date);
          etaCalc(responseData.data[0].washing_stage);
          orderStatusCalc(responseData.data[0].washing_stage);
          washprogramCalc(responseData.data[0].program_name);
          setProgramRate(responseData.data[0].program_rate);
          setTotalWeight(responseData.data[0].total_weight);
          setTotalItems(responseData.data[0].total_items);
          setOfferRate(responseData.data[0].offer_rate);
          setTotalAmount(responseData.data[0].program_rate * responseData.data[0].total_weight);
          calcSaveTime(responseData.data[0].total_weight);
        }
        if (responseData.response== 'error') {
          setError(`No order found with order number ${id}`);
        }
      }else{
        let user = getUser();
        if (user) {
          const url = "https://expressv2.shortwash.com/orders/getspecificorder.php";
          const response = await fetch(url,{
            method : "POST",
            headers : {
              "Content-Type" : "application/json"
            },
            body : JSON.stringify({
              userid : user.userid,
              orderid : id
            })
          });
          const responseData = await response.json();
    
          if (responseData.response == 'success') {
            setOrder(responseData.data[0
            ]);
            formatDate(responseData.data[0].order_date);
            etaCalc(responseData.data[0].washing_stage);
            orderStatusCalc(responseData.data[0].washing_stage);
            washprogramCalc(responseData.data[0].program_name);
            setProgramRate(responseData.data[0].program_rate);
            setTotalWeight(responseData.data[0].total_weight);
            setTotalItems(responseData.data[0].total_items);
            setOfferRate(responseData.data[0].offer_rate);
            setTotalAmount(responseData.data[0].program_rate * responseData.data[0].total_weight);
            calcSaveTime(responseData.data[0].total_weight);
          }
          if (responseData.response== 'error') {
            setError(`No order found with order number ${id}`);
          } 
        }else{
          setError('User not found');
        }
      }
    } catch (error) {
      logError(error,{cause:"fetchOrders functions - vieworders web",userdata});
    }
    
    setPageLoading(false);
  }
  
  function calcSaveTime(time){
    let savetime = Math.ceil(time/3);
    setSaveTime(savetime);
  }
  function formatDate(ordDate) {
    let date = ordDate.substr(0,10);
    let dateArr = date.split("-");
    let month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let finalDate = `${month[dateArr[1] - 1]} ${dateArr[2]}, ${dateArr[0]}`;
    setOrderDate(finalDate);
  }
  function etaCalc(washStage) {
    let washingstage = washStage;
    let eta;
    // pickup = 3,queue = 2, washing = 6,delivery = 3
    switch (washingstage) {
      case "pickup":
        eta = "Not Updated";
        break;
      case "queue":
        eta = "14-16 Hour";
        break;
      case "washing":
        eta = "11-13 Hour";
        break;
      case "delivery":
        eta = "3-5 Hour";
        break;
      case "delivered":
        eta = "Delivered";
        break;
      default:
        eta = "Not Updated"
        break;
    }
    setETA(eta);
  }
  function orderStatusCalc(stat){
    let status ;
    switch (stat) {
      case 'pickup':
        status = "Pickup Pending"
        break;
        case 'queue':
        status = "In-Queue"
        break;
        case 'washing':
        status = "In-Washing/Drying"
        break;
        case 'delivery':
        status = "Out For Delivery"
        break;
        case 'delivered':
        status = "Delivered"
        break;
      default:
        status = "Not Updated"
        break;
    }
    setOrderStatus(status);
  }
  function washprogramCalc(program){
    let washProgram;
    switch (program) {
      case 'Regular Wash':
        washProgram = 'Regular Wash'
        break;
    
      default:
        washProgram = "Unknown"
        break;
    }
    setWashProgram(washProgram);
  }
  useEffect(()=>{
    if (!userdata.loggedin) {
      let getUserData = getUser();
      if (!getUserData) {
        setPageLoading(true);
        setTimeout(() => {
          navigate('/auth/login');
          showAlert('red','Please login first',3000);
        }, 2000); 
      }
    }
  },[userdata])
  return (
    <React.Fragment>
    <div style={{ minHeight: "100vh", position: "relative" }}>
    <Helmet>
    <title>Shortwash - View your order</title>
    </Helmet>
      <Navbar page="orders" />
      {alertValue && (
        <Alert theme={alertValue.theme} message={alertValue.message} />
      )}
      <div style={{ width: "100%", minheight: "100vh" }}>
      <div style={{ width: "100%",minheight:'100%' }} className="simpleflex">
        <div className="vieworder-outer-wrapper"
          style={{
            backgroundColor: "white",
            paddingBottom: 30,
            height:'100%'
          }}
        >
          
          {pageLoading && <div className="simpleflex py-3" style={{height: "calc(100vh - 125px)"}}><Spinner borderColor="#000000" size="28px"/></div>}
          
          {!error && <div className ="outer-container container" style={{display : pageLoading == false ? "block" : "none"}}>
          
          <div className="order-heading text-center" style={{marginTop:20,marginBottom:20}}>
            <span
              style={
                { fontSize:32,fontWeight:'300' }}
            >
              Order Information
            </span>
          </div>

          <div className="order-container" style={{ marginTop: 10 }}>
            <div className="ordernoanddate"
              style={{
                flexDirection: "row",
                justifyContent: "space-between",
                display:'flex',
                marginBottom:5,
                marginTop:5
              }}
            >
              <div style={{display:'flex',flexDirection:'column'}}>
                <span
                  style={{
                    color: "grey",
                    fontSize: 16,
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  Order No
                </span>
                <span
                  style={{
                    color: "black",
                    fontSize: 16,
                    fontWeight: "500",
                    textAlign: "center",
                    marginTop: 2,
                  }}
                >
                {order.orderid}
                </span>
              </div>
              <div style={{display:'flex',flexDirection:'column'}}>
                <span
                  style={{
                    color: "grey",
                    fontSize: 16,
                    fontWeight: "500",
                    textAlign: "center",
                  }}
                >
                  Order Date
                </span>
                <span
                  style={{
                    color: "black",
                    fontSize: 16,
                    fontWeight: "500",
                    textAlign: "center",
                    marginTop: 2,
                  }}
                >
                 {formattedDate} 
                </span>
              </div>
            </div>

            <div style={{ marginTop: 20 }} className="delivery-address">
              <div
                className="delivery-heading text-center"
                style={{ marginBottom: 10 }}
              >
                <span
                  style={{
                    color: "grey",
                    fontSize: 16,
                    fontWeight: "500",
                  }}
                >
                  Delivery Address
                </span>
              </div>

              <div
                style={{
                  borderColor: "#E1e1e1",
                  borderWidth: 1,
                  borderRadius: 5,
                  borderStyle:'solid',
                  display:'flex',
                  flexDirection: "row",
                  paddingLeft:10,
                  paddingRight:10
                }}
              >
                <div
                  className="map-img"
                  style={{
                    display:'flex',
                    justifyContent: "center",
                    flex: 1,
                    flexWrap: "wrap",
                    alignContent: "center",
                  }}
                >
                  <img
                    style={{ width: 64 }}
                    src={require("../../../assets/orders/map.png")}
                  />
                </div>
                <div
                  className="address"
                  style={{
                    display:'flex',
                    justifyContent: "center",
                    flexDirection:'column',
                    paddingLeft: 15,
                    flex: 3,
                  }}
                >
                  <div
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      display:'flex',
                      marginTop:7,
                      marginBottom:7,
                      flexShrink: 1,
                    }}
                  >
                    <img
                      style={{ width: 11, height: 11, marginRight: 5 }}
                      src={require("../../../assets/orders/location.png")}
                    />
                    <span style={{}}>
                      {order.address1}, {order.address2}
                    </span>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      display:'flex',
                      marginBottom:7,
                      marginTop:7
                    }}
                  >
                    <img
                      style={{ width: 11, height: 11, marginRight: 5 }}
                      src={require("../../../assets/orders/user.png")}
                    />
                    <span
                      style={{
                        color: "grey",
                        fontSize: 15,
                      }}
                    >
                      {order.name}
                    </span>
                  </div>
                  <div
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginBottom:7,
                      marginTop:7,
                      display:'flex',

                    }}
                  >
                    <img
                      style={{ width: 11, height: 11, marginRight: 5 }}
                      src={require("../../../assets/orders/phone.png")}
                    />
                    <span style={{ color: "grey" }}>{order.mobile}</span>
                  </div>
                </div>
              </div>
            </div>

            <div className="eta-heading"
              style={{
                backgroundColor: "rgba(0,0,0,0.7)",
                borderRadius: 5,
                paddingBottom:10,
                paddingTop:10,
                marginBottom:20,
                marginTop:20,
                textAlign:'center'
              }}
            >
              <span style={{ color: "white" }}>
                Estimated Time : {eta}
              </span>
            </div>

            <div className="washprogramandorderstatus" style={{flexDirection : 'row',justifyContent : 'space-between',marginTop:20,marginBottom:20,display:'flex'}}>
            <div className="text-center d-flex" style={{flexDirection:'column'}}>
              <span
                style={{
                  color: "grey",
                  fontSize: 16,
                  fontWeight: "500",
                }}
              >
                Wash Program
              </span>
              <span
                style={{
                  color: "black",
                  fontSize: 16,
                  fontWeight: "500",
                  textAlign: "center",
                  marginTop: 2,
                }}
              >
                {wprogram}
              </span>
            </div>
            <div className="d-flex" style={{flexDirection:'column'}}>
              <span
                style={{
                  color: "grey",
                  fontSize: 16,
                  fontWeight: "500",
                  textAlign: "center",
                }}
              >
                Order Status
              </span>
              <span
                style={{
                  color: "black",
                  fontSize: 16,
                  fontWeight: "500",
                  textAlign: "center",
                  marginTop: 2,
                }}
              >
                {orderstatus}
              </span>
            </div>
            </div>

            <div className="orderoverview"
              style={{
                borderColor: "#E1e1e1",
                borderStyle:'solid',
                borderWidth: 1,
                padding:10,
                borderRadius: 5,
                marginTop: 20,
              }}
            >
              <div className="products" style={{ flexDirection: "row",display:'flex' }}>
                <div
                  className="simpleflex img-container"
                  style={{
                    height: 50,
                    width: 50,
                  }}
                
                >
                  <img
                    style={{ width: 32, height: 32 }}
                    src={require("../../../assets/orders/laundry.png")}
                  />
                </div>
                <div
                  className="product-name "
                  style={{
                    paddingLeft: 5,
                    height: 50,
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    flexDirection:'column'
                  }}
                >
                  <span style={{ fontWeight: "600" }}>Garments</span>
                  <div onClick={()=>{

                  }}>
                  {/*<span style={{color : '#007BFF',fontSize : 13}}>View Garments</span>*/}
                  </div>
                </div>
              </div>
              <div className="product-values">
                <div
                  style={{
                    flexDirection: "row",
                    display:'flex',
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: 16,marginTop:4,marginBottom:4 }}>
                    Items
                  </span>
                  <span style={{ fontSize: 16,marginTop:4,marginBottom:4 }}>{order.total_items}</span>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display:'flex',
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: 16,marginTop:4,marginBottom:4 }}>
                    Weight (kg)
                  </span>
                  <span style={{ fontSize: 16, marginTop:4,marginBottom:4 }}>
                    {order.total_weight}
                  </span>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    display:'flex'
                  }}
                >
                  <span style={{ fontSize: 16, marginTop:4,marginBottom:4 }}>
                    {wprogram} (&#8377;{`${order.program_rate}/kg)`}
                  </span>
                  <span style={{ fontSize: 16, marginTop:4,marginBottom:4}}>
                    &#8377; {order.program_rate * order.total_weight}
                  </span>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    display : order.offerid ? 'flex' : 'none'
                  }}
                >
                  <span style={{ fontSize: 16, marginTop:4,marginBottom:4 }}>
                    Offer Applied ({offerrate}% Off)
                  </span>
                  <span
                    style={{
                      fontSize: 16,
                      marginTop:4,marginBottom:4,
                      color: "red",
                    }}
                  >
                    {" "}
                    - &#8377; {(totalamount*offerrate)/100}
                  </span>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-between",
                    display:'flex',
                    paddingTop:7,
                    paddingBottom:7,
                    borderTopWidth:1,
                    borderTopColor:"#e1e1e1",
                    borderTopStyle:'solid',
                  }}
                >
                  <span
                    style={{
                      fontSize: 17,
                      marginTop:4,marginBottom:4,
                      fontWeight: "600",
                    }}
                  >
                    Final Amount
                  </span>
                  <span style={{ fontSize: 17, marginTop:4,marginBottom:4 }}>
                    {" "}
                    &#8377; {totalamount - (totalamount*offerrate)/100}
                  </span>
                </div>
              </div>
            </div>

            <div style={{marginTop : 20,justifyContent : "center",alignItems : "center",display:'flex',flexDirection:'column',paddingBottom:30}}>
              <span style={{color : "grey",fontSize : 16,fontStyle : 'italic',fontWeight : '600'}}>
              Congratulations, you had saved almost {savetime}-{savetime+1} hours of your precious time & during that time we hope you had gathered bunch of memories
              </span>
              <span style={{color : "grey",fontSize : 16,fontStyle : 'italic',fontWeight : '600',marginTop : 10}}>
              Happy to see you again!
              </span>
            </div>
          </div>
          </div>}
          {error && <div className="simpleflex px-2 text-center" style={{height:'calc(100vh - 125px)'}}><span>Something went wrong while getting your order details, CAUSE - {error}</span></div>}
        </div>
      </div>
      </div>

    </div>
  </React.Fragment>
  );
}
