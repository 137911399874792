import React, { useContext, useEffect, useState } from "react";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Alert from "./Alert";
import AppContext from "../context/AppContext";
import Spinner from "./Spinner";
import { Helmet } from "react-helmet";
import { useParams, useSearchParams } from "react-router-dom";
import PgNavbar from "./PgNavbar";

export default function Payment() {
  const context = useContext(AppContext);
  const { alertValue } = context;
  const { code, tid, amount } = useParams();
  let [orderDetails, setOrderDetails] = useState(null);
  let [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    let fetchOrder = async () => {
      let response = await fetch(
        "https://expressv2.shortwash.com/payments/getorderdetails.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            tid,
          }),
        }
      );
      let responseData = await response.json();
      if (responseData.response == "success") {
        setOrderDetails(responseData.data[0]);
      } else {
        setOrderDetails(null);
      }
      setLoading(false);
    };
    fetchOrder();
  }, []);
  function formatDate(ordDate) {
    let date = ordDate.substr(0, 10);
    let dateArr = date.split("-");
    let month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let finalDate = `${month[dateArr[1] - 1]} ${dateArr[2]}, ${dateArr[0]}`;
    return finalDate;
  }
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <Helmet>
          <meta name="robots" content="noindex"></meta>
        </Helmet>
        <PgNavbar />
        {alertValue && (
          <Alert theme={alertValue.theme} message={alertValue.message} />
        )}
        {loading && (
          <div
            className="simpleflex py-3"
            style={{ height: "calc(100vh - 125px)" }}
          >
            <Spinner borderColor="#000000" size="28px" />
          </div>
        )}
        {orderDetails && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              minHeight: "calc(100vh - 86px - 40px)",
              flexDirection: "column",
            }}
          >
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <img
                src={require("../assets/payment/check.png")}
                alt="Payment Received"
                style={{ height: "100px", width: "100px" }}
              />
            </div>
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <h3>Payment received</h3>
            </div>
            <div style={{ marginBottom: "20px", textAlign: "center" }}>
              <span className="text-muted text-center px-3">
                We've received your{" "}
                <span style={{ fontWeight: 700 }}>
                  &#8377;{orderDetails.total_amount}
                </span>{" "}
                payment , {orderDetails.name}
              </span>
            </div>
            <div
              className="orderoverview"
              style={{
                borderColor: "#E1e1e1",
                borderStyle: "solid",
                borderWidth: 1,
                padding: 10,
                borderRadius: 5,
                marginTop: 20,
                width: "80%",
              }}
            >
              <div
                className="products"
                style={{ flexDirection: "row", display: "flex" }}
              >
                <div
                  className="product-name "
                  style={{
                    paddingLeft: 5,
                    height: 50,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                >
                  <span style={{ fontWeight: "600" }}>Payment Details</span>
                </div>
              </div>
              <div className="product-values">
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: 16, marginTop: 4, marginBottom: 4 }}>
                    Order ID
                  </span>
                  <span style={{ fontSize: 16, marginTop: 4, marginBottom: 4 }}>
                    {orderDetails.orderid}
                  </span>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: 16, marginTop: 4, marginBottom: 4 }}>
                    Total Items
                  </span>
                  <span style={{ fontSize: 16, marginTop: 4, marginBottom: 4 }}>
                    {orderDetails.total_items}
                  </span>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: 16, marginTop: 4, marginBottom: 4 }}>
                    Total Weight
                  </span>
                  <span style={{ fontSize: 16, marginTop: 4, marginBottom: 4 }}>
                    {orderDetails.total_weight} KG
                  </span>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: 16, marginTop: 4, marginBottom: 4 }}>
                    Payment Amount
                  </span>
                  <span style={{ fontSize: 16, marginTop: 4, marginBottom: 4 }}>
                    &#8377; {orderDetails.total_amount}
                  </span>
                </div>
                <div
                  style={{
                    flexDirection: "row",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <span style={{ fontSize: 16, marginTop: 4, marginBottom: 4 }}>
                    Payment Date
                  </span>
                  <span style={{ fontSize: 16, marginTop: 4, marginBottom: 4 }}>
                    {formatDate(orderDetails.pg_date)}
                  </span>
                </div>
                <div
                  style={{
                    flexDirection: "column",
                    justifyContent: "space-between",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  className="border-top"
                >
                  <span style={{ fontSize: 16, marginTop: 4 }}>
                    Transaction ID
                  </span>
                  <span style={{ fontSize: 16, marginTop: 4, marginBottom: 4 }}>
                    {orderDetails.pg_transactionid}
                  </span>
                </div>
              </div>
            </div>
          </div>
        )}
        
        {!orderDetails && !loading && (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "calc(100vh - 86px - 40px)",
              flexDirection: "column",
            }}
          >
            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <img
                src={require("../assets/payment/stopwatch.png")}
                alt="Payment Received"
                style={{ height: "100px", width: "100px" }}
              />
            </div>
            <div style={{ marginTop: "10px", marginBottom: "10px" }}>
              <h3>Payment pending</h3>
            </div>
            <div
              style={{
                textAlign: "center",
                display: "flex",
                flexDirection: "column",
                marginTop: 20,
              }}
              className="px-2"
            >
              <span style={{ textAlign: "center" }}>
                We cannot confirm your payment right now, it may take 24 hours
                to update your transaction
              </span>
              <span className="text-muted">
                Stay calm your money is in safe hands!
              </span>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </React.Fragment>
  );
}
