import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import AppContext from "../context/AppContext";
import Navbar from "./Navbar";
import Alert from "./Alert";
import Spinner from "./Spinner";
import { Helmet } from "react-helmet";

export default function Confirm() {
  const context = useContext(AppContext);
  const {
    sendDataToApp,
    washprogram,
    timeslot,
    activeWashProgram,
    offerValid,
    setUserData,
    addOrder,
    pickupAdd,
    setWashProgram,
    setTimeSlot,
    setPickupAdd,
    guestMode,
    alertValue,
    showAlert,
    selectedProgram,
    setSelectedProgram,
    programRate,
    setProgramRate,
  } = context;
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [orderResponse, setOrderResponse] = useState(null);
  const [wpprogram, setwpprogram] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    async function placeOrder() {
      if (selectedProgram && timeslot && pickupAdd) {
        setwpprogram({ ...selectedProgram, programRate });
        let resData = await addOrder();
        setSelectedProgram({ program_name: "" });
        setPickupAdd(null);
        setProgramRate(0);

        if (resData.response === "success") {
          setLoading(false);
          showAlert("green", "Order placed successfully", 5000);
          setOrderResponse(resData.data);
        } else {
          showAlert(
            "red",
            "Some error occured while placing order, please report it at complaint@shortwash.com or try again",
            5000
          );
          navigate("/");
        }
      }
    }
    placeOrder();
    if (!selectedProgram || !timeslot || !pickupAdd) {
      setTimeout(() => {
        navigate("/");
      }, 2000);
    }
  }, []);
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <Helmet>
          <title>Shortwash - Order placed successfully</title>
        </Helmet>
        <Navbar page="home" />
        {alertValue && (
          <Alert theme={alertValue.theme} message={alertValue.message} />
        )}
        {loading && (
          <div className="simpleflex confirm-loading-screen">
            <Spinner borderColor="#000000" size="28px" />
          </div>
        )}
        {!loading && (
          <div style={{ position: "relative" }}>
            <div
              className="simpleflex px-3  py-2"
              onClick={() => {
                navigate("/");
              }}
              style={{
                position: "absolute",
                backgroundColor: "rgb(240,240,240)",
                cursor: "pointer",
              }}
            >
              <span>&#8592;</span>
            </div>
            <div className="container">
              <div
                className="top-half pt-4 simpleflex"
                style={{ flexDirection: "column" }}
              >
                <span style={{ color: "black", fontSize: 23 }}>Thank you</span>
                <span className="mb-5" style={{ color: "black", fontSize: 23 }}>
                  We are glad to serve you
                </span>
                <img
                  src={require("../assets/orderplaced/shopping-bag.png")}
                  style={{ height: 90, width: 90 }}
                  alt="Order Placed Icon"
                />
                <span className="text-muted mt-2">
                  Your order placed successfully
                </span>
              </div>
              <div className="overview-table-wrapper">
                <div
                  className="table-wrapper border my-4"
                  style={{ borderRadius: 15, overflow: "hidden" }}
                >
                  <div
                    className="table-heading px-2 py-1"
                    style={{ backgroundColor: "rgb(240,240,240)" }}
                  >
                    <span>Order Details</span>
                  </div>
                  <div className="table-content d-flex">
                    <div
                      className="table-particular border-end"
                      style={{ flex: 2, height: "100%" }}
                    >
                      <div className="washprogram py-1 px-2 border-bottom">
                        <span>Wash Program - </span>
                      </div>
                      <div className="washprogram py-1 px-2 border-bottom">
                        <span>Pickup Time - </span>
                      </div>
                      <div className="washprogram py-1 px-2 border-bottom">
                        <span>Program Rate - </span>
                      </div>
                      {offerValid && (
                        <div className="washprogram py-1 px-2 border-bottom">
                          <span>Offer/Discount - </span>
                        </div>
                      )}
                      {offerValid && (
                        <div className="washprogram py-1 px-2 border-bottom">
                          <span>Final Rate - </span>
                        </div>
                      )}
                    </div>
                    <div
                      className="table-amount"
                      style={{ flex: 3, height: "100%" }}
                    >
                      <div className="washprogram py-1 px-2 border-bottom text-center">
                        <span>
                          {" "}
                          {wpprogram.program_name ||
                            "Not Available"}{" "}
                        </span>
                      </div>
                      <div className="washprogram py-1 px-2 border-bottom text-center">
                        <span>
                          {" "}
                          {orderResponse.timeslot || "Not Available"}{" "}
                        </span>
                      </div>
                      <div className="washprogram py-1 px-2 border-bottom text-center">
                        <span>
                          {" "}
                          &#8377; {wpprogram.programRate} per{" "}
                          {wpprogram.unit}{" "}
                        </span>
                      </div>
                      {offerValid && (
                        <div className="washprogram py-1 px-2 border-bottom text-center">
                          <span> {orderResponse.offerrate}% OFF </span>
                        </div>
                      )}
                      {offerValid && (
                        <div className="washprogram py-1 px-2 border-bottom text-center">
                          <span>
                            {" "}
                            &#8377; {orderResponse.orderrate} per{" "}
                            {wpprogram.unit}{" "}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                  <div
                    className="table-footer text-center px-2 py-1"
                    style={{ backgroundColor: "rgb(240,240,240)" }}
                  >
                    <span>
                      Clothes weight and total number of items will be
                      calculated at the time of order pickup
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}
