import React, { useEffect, useState } from "react";

export default function TransactionItem(props) {
  const [ttype, setTtype] = useState(null);
  const [tIcon, setTIcon] = useState(null);
  const [tDate,setTDate] = useState(null);

  useEffect(() => {
    formatType();
    setIcon();
    formatDate();
  }, []);
  function formatType() {
    let type = props.item.t_type;
    let finalOutput;
    finalOutput = type.slice(0, 1).toUpperCase();
    finalOutput += type.slice(1);
    setTtype(finalOutput);
  }
  function setIcon() {
    let type = props.item.t_type;
    let icon;
    switch (type) {
      case "promotion":
        icon = require("../../../assets/wallet/promotion.png");
        break;
      case "compensation":
        icon = require("../../../assets/wallet/compensation.png");
        break;
      case "change":
        icon = require("../../../assets/wallet/changemoney.png");
        break;
      case "cashback":
        icon = require("../../../assets/wallet/cashback.png");
        break;
      case "paid":
        icon = require("../../../assets/wallet/paid.png");
        break;
      default:
        icon = require("../../../assets/wallet/promotion.png");
        break;
    }
    setTIcon(icon);
  }
  function formatDate() {
    let date = props.item.t_date.substr(0,10);
    let dateArr = date.split("-");
    let month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let finalDate = `${month[dateArr[1] - 1]} ${dateArr[2]}, ${dateArr[0]}`;
    setTDate(finalDate);
  }
  return (
    <div
      className="transaction"
      style={{
        flexDirection: "row",
        height: 50,
        display:'flex',
        marginTop:5,
        marginBottom:5
      }}
    >
      <div
        style={{
          justifyContent: "center",
          alignItems: "center",
          height: 50,
          width: 50,
          flex: 1,
          display:'flex'
        }}
      >
        <img src={tIcon} />
      </div>
      <div
        style={{
          flex: 4,
          height: 50,
          justifyContent: "center",
          paddingLeft: 10,
          display:'flex',
          flexDirection:'column'
        }}
      >
        <span style={{ fontWeight: "500", color: "grey",fontSize:15 }}>{tDate}</span>
        <span style={{ fontSize: 17, fontWeight: "400" }}>{ttype}</span>
      </div>
      <div
        style={{
          flex: 2,
          justifyContent: "center",
          alignItems: "center",
          display:'flex'
        }}
      >
        <span
          style={{
            fontSize: 17,
            fontWeight: "500",
            color: props.item.t_type == "paid" ? "red" : "green",
          }}
        >
          {props.item.t_type == "paid" ? "-" : "+"} &#8377;{" "}
          {props.item.t_amount}
        </span>
      </div>
    </div>
  );
}
