import React, { useContext, useState } from "react";

import AppContext from "../context/AppContext";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import Navbar from "../component/Navbar";
import Footer from "../component/Footer";
import Alert from "./Alert";
import { Helmet } from "react-helmet";

export default function Refer() {
  const context = useContext(AppContext);
  const {
    setWashProgram,
    setUserData,
    userdata,
    allwashPrograms,
    washprogramLoading,
    washprogramError,
    allReviews,
    hreviewloading,
    hreviewError,
    alertValue,
    getUser,
    showAlert,
  } = context;
  const navigate = useNavigate();
  let hashVal = ["7", "r", "5", "9", "l", "a", "4", "m", "e", "k"];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!userdata.loggedin) {
      getUser();
    }
  }, []);
  const [mobile, setMobile] = useState("YOUR_MOBILE_NUMBER");
  useEffect(() => {
    if (userdata.loggedin) {
    }
    if (userdata.loggedin) {
      let hash = "";
      for (let i = 0; i < 10; i++) {
        hash += hashVal[userdata.mobile.charAt(i)];
      }
      setMobile(hash);
    }
  }, [userdata]);
  return (
    <React.Fragment>
      <div style={{ minHeight: "100vh", position: "relative" }}>
        <Helmet>
          <title>
            Shortwash - Refer your friend and get &#8377;50 in your wallet
          </title>
        </Helmet>
        <Navbar page="refer" />
        {alertValue && (
          <Alert theme={alertValue.theme} message={alertValue.message} />
        )}

        <div
          className="d-flex justify-content-end refer-container"
          style={{ height: "calc(100vh - 86px)" }}
        >
          <div className="refer-outer-wrapper" style={{ padding: 12 }}>
            <div className="simpleflex" style={{ marginTop: 20 }}>
              <span style={{ fontSize: 32, fontWeight: "300" }}>
                Refer karo, Earn karo
              </span>
            </div>
            <div
              className="refer-img-wrapper"
              style={{
                width: "100%",
                marginTop: 5,
                alignItems: "center",
                justifyContent: "center",
                marginBottom: 5,
              }}
            >
              <img
                style={{ width: "70%", height: 200 }}
                src={require("../assets/refer/refer.png")}
              />
            </div>
            <div className="simpleflex py-2 text-center">
              <span className="text-muted">
                Invite your friend or family member to shortwash & get &#8377;50
                in your wallet
              </span>
            </div>

            {userdata.loggedin && (
              <div className="referral-instruction" style={{ marginTop: 20 }}>
                <div className="text-center">
                  <span>
                    Ask them to enter your mobile number in referral field while
                    creating a new account
                  </span>
                </div>
                <div className="text-center py-4">
                  <span className="text-muted">or</span>
                </div>
                <div>
                  <span>1. Copy the below link</span>
                </div>
                <div className="d-flex justify-content-center py-3">
                  <div
                    className="simpleflex border"
                    style={{
                      borderRadius: 6,
                      flexDirection: "column",
                      backgroundColor: "rgb(225,225,225)",
                      cursor:'pointer',
                      userSelect:'none'
                    }}
                    onClick={() => {
                      if (userdata.loggedin) {
                        navigator.clipboard.writeText(
                          `https://shortwash.com/${mobile}`
                        );
                        showAlert("green", "Link copied to clipboard", 5000);
                      } else {
                        showAlert(
                          "red",
                          "To get your referral link, you have to login first",
                          5000
                        );
                      }
                    }}
                  >
                    <span style={{ fontSize: 15 }} className="px-2">
                      https://shortwash.com/{mobile}{" "}
                    </span>
                    <span
                      style={{
                        fontSize: 15,
                        marginTop: 5,
                      }}
                      
                    >
                      Tap to copy
                    </span>
                  </div>
                </div>
                <div>
                  <span>2. Ask them to signup using your link</span>
                </div>
                <div>
                  <span>
                    3. You will get &#8377;50 in your wallet
                  </span>
                </div>
              </div>
            )}
            {!userdata.loggedin && (
              <div
                className="simpleflex my-4"
                style={{ flexDirection: "column" }}
              >
                <span className="text-muted">
                  You are not logged in, please login and comeback later
                </span>
                <span
                  className="text-primary pt-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/auth/login");
                  }}
                >
                  Login/Signup
                </span>
              </div>
            )}
          </div>
        </div>
        <Footer/>
      </div>
    </React.Fragment>
  );
}
