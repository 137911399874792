import React from "react";
import { useNavigate } from "react-router-dom";
import OrderStage from "./OrderStage"

export default function MainOrder(props) {
  const navigate = useNavigate();
  function formatDate() {
    let date = props.order.order.order_date.substr(0, 10);
    let dateArr = date.split("-");
    let month = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    let finalDate = `${month[dateArr[1] - 1]} ${dateArr[2]}, ${dateArr[0]}`;
    return finalDate;
  }
  function etaCalc() {
    let washingstage = props.order.status;
    let eta;
    // pickup = 3,queue = 2, washing = 6,delivery = 3
    switch (washingstage) {
      case "pickup":
        eta = "Not Updated";
        break;
      case "queue":
        eta = "14-16 Hour";
        break;
      case "washing":
        eta = "11-13 Hour";
        break;
      case "delivery":
        eta = "3-5 Hour";
        break;
      case "delivered":
        eta = "Delivered";
        break;
      default:
        eta = "Not Updated"
        break;
    }
    return eta;
  } 
  return (
    <div
      style={{
        width: "92%",
        marginLeft:'4%',
        marginRight:'4%',
        borderColor: "#E1E1E1",
        borderWidth: 2,
        padding: "2%",
        borderStyle:'solid',
        marginTop:10,
        marginBottom:10,
        borderRadius: 15,
      }}
      className="ordercontainer"
    >
    {(props.order.status != 'pickup' && props.order.status != 'delivered') && <div
        style={{
          flexDirection: "row",
          borderColor: "#e1e1e1",
          borderBottomWidth: 1,
          paddingBottom: "1%",
          marginBottom : 5
        }}
      >
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            paddingTop:5,
            paddingBottom:5,
            borderColor: "#e1e1e1",
            width: "100%",
            cursor:'pointer'
          }}
          onClick={() => {
            navigate(`/auth/orders/payment/${props.order.order.orderid}`);
          }}
        >
          <div style={{ flexDirection: "row",justifyContent : 'center',alignItems : 'center',display:'flex' }}>
            <span>Pay with shortwash wallet</span>
            <img
              style={{ width: 16, height: 16,marginLeft : 3 }}
              src={require("../../../assets/orders/payforthisorder.png")}
            />
          </div>
        </div>
      </div>}
      <div style={{ flexDirection: "row", justifyContent: "space-between" }}>
        <span>
          <span style={{ color: "grey", fontWeight: "600" }}>Date - </span>
          {formatDate()}
        </span>
      </div>
      <div>
        <span style={{marginTop:5,marginBottom:5 }}>
          <span style={{ color: "grey", fontWeight: "600" }}>Expected Time - </span>
           {etaCalc()}
        </span>
      </div>

      <OrderStage order={props.order} />

      <div
        style={{
          flexDirection: "row",
          borderColor: "#e1e1e1",
          borderTopWidth: 1,
          paddingTop: "1%",
        }}
      >
        <div
          style={{
            justifyContent: "center",
            alignItems: "center",
            borderColor: "#e1e1e1",
            width: "100%",
            display:'flex',
            paddingTop:5,
            paddingBottom:5,
            cursor:'pointer'
          }}
          onClick={() => {
            navigate(`/auth/orders/vieworder/${props.order.order.orderid}`);
          }}
        >
          <div style={{ flexDirection: "row" }}>
            <span>View Details</span>
            <img
              style={{ width: 16, height: 16 }}
              src={require("../../../assets/orders/next.png")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
