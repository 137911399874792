// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// abhiydv1844@gmail.com
const firebaseConfig = {
  apiKey: "AIzaSyCBGOc-9n3vzlzQuHyesinfs5lGMRv3FnU",
  authDomain: "shortwash-be1c1.firebaseapp.com",
  projectId: "shortwash-be1c1",
  storageBucket: "shortwash-be1c1.appspot.com",
  messagingSenderId: "207639370419",
  appId: "1:207639370419:web:257aa1911b3a72bc72aec6",
  measurementId: "G-VGF2RJMH48"
};
// abhisales111@gmail.com
// const firebaseConfig = {
//   apiKey: "AIzaSyDb2NG1Saz87SJ8Npsoi7ZerDZEkGLOSUA",
//   authDomain: "tempshortwash.firebaseapp.com",
//   projectId: "tempshortwash",
//   storageBucket: "tempshortwash.appspot.com",
//   messagingSenderId: "240155338931",
//   appId: "1:240155338931:web:204d839ca37044f81ae8c6",
//   measurementId: "G-6GL3GGFKX0"
// };
// Initialize Firebase
const app = initializeApp(firebaseConfig);

export default app;