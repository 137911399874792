import React, { useState,useEffect } from "react";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import { useContext } from "react";
import AppContext from "../../context/AppContext"
import { useNavigate } from "react-router-dom";
import app from "../../firebaseConfig";
import Spinner from '../Spinner'


export default function WForgotPassword() {
  const auth = getAuth(app);
  const context = useContext(AppContext);
  const {sendDataToApp,fpUser,setFpUser} = context;
  const navigate = useNavigate();
  const [resendTime,setResendTime] = useState('30');
  const [validateCodeError,setValidateCodeError] = useState(false);
  const [verificatioAttempt,setVerificationAttempt] = useState(3);
  const [resendState,setResendState] = useState(false);
  const [fpLoader,setFPLoader] = useState(false);
  const [verificationLoader,setVerificationLoader] = useState(false);
  const [fpFields, setFPFields] = useState({
    mobile: "",
    verification : ''
  });
  const [fpError, setFPError] = useState({
    mobileErr: false,
    mobileLen : false,
    verification : false
  });
  const [pageStage,setPageStage] = useState(1);
  const [fpConfirmRes,setFPConfirmRes] = useState(null);
  const [tempUser,setTempUser] = useState(null);
  // fp stage 1
  const onchange = (e) => {
    if (e.target.id == 'mobile') {
      if (e.target.value.length <= 10) {
        setFPFields((stat) => {
          return { ...stat, mobile: e.target.value };
        });
      }
    }else{
      setFPFields((stat) => {
        return { ...stat, [e.target.id]: e.target.value };
      });
    }
  };
  const searchMobile = async()=>{
    setFPLoader(true);
    try {
    setFPError({
      mobileLen: false,
      mobileErr: false,
      verification : false
    });

    let mobileErr = false;

    // validating mobile field
    if (fpFields.mobile.length < 10 || fpFields.mobile.length > 10) {
      // enter a valid mobile
      setFPError((stat) => {
        return { ...stat, mobileLen: true };
      });
      mobileErr = true;
    } else {
      mobileErr = false;
    }
    
    if (!mobileErr) {
      let mobile = fpFields.mobile;
      
      let sqlQuery = `SELECT mobile,userid from users where mobile = ${mobile}`;
      let dbUrl = "https://express.shortwash.com/getters/getsingle.php";
      let response = await fetch(dbUrl,{
        method : 'POST',
        headers : {
          'Content-Type' : "applicaiton/json"
        },
        body : JSON.stringify({
          query : sqlQuery
        })
      });
      let responseData = await response.json();
      
      if (responseData.response == 'success') {
        setTempUser(responseData.data);
        setPageStage('loading');
        signInWithPhoneNumber(
          auth, 
          `+91${mobile}`,
          window.recaptchaVerifier
        )
          .then((confirmationResult) => {
            setFPConfirmRes(confirmationResult);
            setTimeout(() => {
              console.log('hello sms sent');
              sendDataToApp({alert : {
                type : "green",
                message : `SMS sent successfully on +91${mobile}`,
                duration : 5000
              }})
              console.log({alert : {
                type : "green",
                message : `SMS sent successfully on +91${mobile}`,
                duration : 5000
              }});
              setPageStage(2);
              setFPLoader(false);
            }, 1000);
          })
          .catch((error) => {
            sendDataToApp({alert : {
              type : "red",
              message : `Something went wrong while sending SMS on +91${mobile}, please try again`,
              duration : 5000
            }})
            logError(error,{data:fpFields.mobile,cause:"searchmobile function signinwithphonenumber - wforgotpassword webview"})
            console.log('sms not sent - ' + JSON.stringify(error.message));
            pageReset();
            // we had to reset recaptcha to use it again
          });
      }else {
        setTempUser(null);
        setFPError((stats)=>{
          return {...stats,mobileErr : true}
        });
      }  
    }
    } catch (error) {
      logError(error,{data:fpFields.mobile,cause:"searchmobile function - wforgotpassword webview"});
    }
    setFPLoader(false);

  }
  const pageReset = async()=>{
    // setting confirm response to null
    setFPConfirmRes(null)
    // getting back to stage 1
    setPageStage(1);
    // resetting fields from stage 1
    setFPFields({
      mobile: "",
      verification:""
    });
    setFPError({
      mobileLen: false,
      mobileErr: false,
      verification: false,
    });
    window.recaptchaVerifier.render().then(function(widgetId) {
      window.grecaptcha.reset(widgetId);
    });
  }

  // verification stage 2
  const cancelValidation = async()=>{
    setPageStage('loading');    
    restartTimer();
    setVerificationAttempt(3);
    setTimeout(() => {
      pageReset();
    }, 1000);
  }
  const restartTimer = ()=>{
    setResendTime('30');
    setResendState(false);
  }
  const validateCode = async()=>{
    try {
      let res = await fpConfirmRes.confirm(fpFields.verification);
      return res;      
    } catch (error) {
      if (error.message.includes('auth/code-expired')) {
        setValidateCodeError(true);
        cancelValidation(); 
        sendDataToApp({
          alert : {
            type : "red",
            message : "Out of attempts, try again later",
            duration : 5000
          }
        })
      }
      logError(error,{cause:"validatecode function - wforgotpassword webview"});
      return null;
    }
  }
  const validateFunc = async()=>{
    setVerificationLoader(true);
    setFPError((stats)=>{
      return {...stats,verification : false}
    });
    
    let verificationErr = false

    // validating verification field
    if (fpFields.verification.length < 6) {
      // enter a valid name
      setFPError((stat) => {
        return { ...stat, verification: true };
      });
      verificationErr = true;
    } else {
      verificationErr = false;
    }
    if (!verificationErr && verificatioAttempt != 0) {
      setVerificationAttempt((stat)=>{
        return stat-1;
      });
      let userCred = await validateCode();

      if (userCred) {
        setPageStage('loading');
        setFpUser(tempUser);
        setTimeout(() => {
          navigate('/webview/newpassword');
          setPageStage(2);
        }, 1500);
        console.log('code verified')
      }else {
        setFpUser(null);
        setFPError((stat) => {
          return { ...stat, verification: true };
        });
      }
      
      
      
    }
    setVerificationLoader(false);
  }
  const resendOTP = async()=>{
    setVerificationLoader(true);

    window.recaptchaVerifier.render().then(function(widgetId) {
      window.grecaptcha.reset(widgetId);
    });
    signInWithPhoneNumber(auth,`+91${fpFields.mobile}`,window.recaptchaVerifier).then((confirmationResult)=>{
      setFPConfirmRes(confirmationResult);
      setVerificationLoader(false);
      restartTimer();
      sendDataToApp({alert : {
        type : "green",
        message : `SMS sent successfully on +91${fpFields.mobile}`,
        duration : 5000
      }})
      setVerificationAttempt(3);
    }).catch((error)=>{
      logError(error,{data:fpFields.mobile,cause:"resendOtp function signinwithphonenumber - wforgotpassword webview"});
      restartTimer();
      sendDataToApp({
        alert : {
          type : "red",
          message : `Something went wrong while sending SMS on +91${fpFields.mobile}, resend again`,
          duration : 5000
        }
      })
      setVerificationLoader(false);

    })
  }

  useEffect(()=>{
    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "forgot-password-recaptcha",
        {
          size: "invisible",
          callback: (response) => {
          },
          "expired-callback": (res) => {
            sendDataToApp({
              alert : {
                type : "red",
                message : "This is a development error, complaint it at complaint@shortwash.com or restart the app",
                duration : 5000
              }
            })
            console.log('recaptch expired');
          },
        },
        auth
      )
    } catch (error) {
      sendDataToApp({
        alert : {
          type : "red",
          message : "This is a development error, complaint it at complaint@shortwash.com or restart the app",
          duration : 5000
        }
      })
      console.log(JSON.stringify(error));
      // console.log('recaptch error - ' + JSON.stringify(error.message));
    }
  },[])
  async function logError(error, data = null) {
    if (error.message != "Failed to fetch") {
      
      let jsonData = JSON.stringify({
        message: error.message,
        stack: error.stack,
        dependencies: { data },
      });
      const response = await fetch(
        "https://express.shortwash.com/error/adderror.php",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({error : jsonData}),
        }
      );
    }
  }
  // verification useeffects
  useEffect(()=>{
    if (!resendState) {
      const timer = setInterval(()=>{
        if (resendTime <= 10) {
          setResendTime((stat)=>{
            return `0${stat-1}`;
          })
        }else{
          setResendTime((stat)=>{
            return stat-1;
          })
        }
      },1000);
  
      return ()=>clearInterval(timer);
    }
  },[resendTime]) 
  useEffect(()=>{
    if (resendTime <= 0) {
      setResendState(true);
    }
  },[resendTime])
  return (
    <React.Fragment>

    {/*forgot password screen*/}
      {pageStage == 1 && <div
        className="container simpleflex"
        style={{ height: "100vh", flexDirection: "column" }}
      >
        <div className="heading-container simpleflex w-100">
          <span
            className="display-3"
            style={{
              marginLeft: 20,
              marginRight: 20,
              fontSize: 32,
              marginBottom: 30,
              fontWeight: "300",
            }}
          >
            Forgot password
          </span>
        </div>
        <div style={{ position: "relative" }} className="w-75">
          <span
            style={{
              fontSize: 15,
              color: "grey",
              position: "absolute",
              backgroundColor: "white",
              paddingLeft: 5,
              paddingRight: 5,
              zIndex: 1,
              left: 12,
              top: -10,
              color: fpError.mobileErr || fpError.mobileLen ? "red" : "grey",
            }}
          >
            Mobile
          </span>
          <input
            style={{
              borderColor: "rgb(225,225,225)",
              borderWidth: 1,
              paddingLeft: 10,
              paddingRight: 10,
              paddingTop: 15,
              paddingBottom: 15,
              color: "black",
              fontSize: 16,
              borderColor: fpError.mobileErr || fpError.mobileLen ? "red" : "rgb(225,225,225)",
              width: "100%",
            }}
            placeholder="Your mobile number"
            value={fpFields.mobile}
            id="mobile"
            type="number"
            className="form-control"
            onChange={onchange}
          />
        </div>
        {/* errors*/}
        <div
          style={{
            visibility: fpError.mobileLen || fpError.mobileErr ? "visible" : "hidden",
            flexDirection: "row",
            color: "red",
            marginTop: 5,
            alignItems: "center",
          }}
          className="d-flex"
        >
          <img
            style={{
              height: 12,
              width: 12,
              marginRight: 5,
              marginTop: 5,
              marginBottom: 4
            }}
            src={require("../../assets/signup/info_red.png")}
          />
          {fpError.mobileLen && <span style={{ color: "red", fontSize: 14 }}>
            Mobile number should be of 10 digits
          </span>}
          {fpError.mobileErr && <span style={{ color: "red", fontSize: 14 }}>
            No user found, you should signup
          </span>}
        </div>
        <div className="btn-container w-75" style={{ marginTop: 30 }}>
          <button
            className="btn w-100"
            style={{ backgroundColor: "red", color: "white" }}
            onClick={searchMobile}
          >
            {!fpLoader && 'Proceed'}
            {fpLoader && <Spinner borderColor="#ffffff" size="24px"/>}
          </button>
          <div className="text-center" style={{marginTop:15,marginBottom:15}}>
            
          <span className="text-muted">or</span>
            </div>
          <button
            className="btn btn-outline-dark w-100"
            onClick={()=>{
              sendDataToApp({
                login : true
              })
            }}
          >
            Login
          </button>
        </div>
      </div>}


      {/*loading screen*/}
      {pageStage == 'loading' && <div className="simpleflex forgot-loading-screen" style={{height : '100vh'}}>
      <Spinner borderColor="#000000" size="28px"/>
      </div>}




        {/*verification screen*/}
        {pageStage == 2 && <div className="container simpleflex" style={{height : '100vh',flexDirection : "column"}}>
        <div className="text-center" style={{marginBottom: 25}}>
        <span style={{fontSize : 23}}>Enter verification code</span>
        </div>
        <div style={{ position: "relative", marginTop: 20 }} className="w-75">
              <span
                style={{
                  fontSize: 15,
                  color: fpError.verification ? 'red' : 'grey',
                  position: "absolute",
                  backgroundColor: 'white',
                  paddingLeft: 5,
                  paddingRight: 5,
                  zIndex: 1,
                  left: 12,
                  top: -4,
                }}
              >
                Verification Code
              </span>
              <input
                style={{
                  borderColor: "rgb(225,225,225)",
                  borderWidth: 1,
                  marginTop: 6,
                  paddingLeft: 10,
                  paddingRight: 10,
                  paddingTop: 15,
                  paddingBottom: 15,
                  width: "100%",
                  color: "black",
                  fontSize: 16,
                  borderColor: fpError.verification
                    ? "red"
                    : "rgb(225,225,225)",
                }}
                placeholder="One time password"
                className="form-control"
                type="number"
                id="verification"
                value={fpFields.verification}
                onChange={onchange}
              />
              <div
                style={
                  { visibility: fpError.verification ? "visible" : "hidden",flexDirection : 'row',color : 'red',marginTop : 5,alignItems : 'center' }
                }
                className="d-flex"
              >
                <img
                  style={{
                    height: 12,
                    width: 12,
                    marginRight: 5
                  }}
                  src={require("../../assets/signup/info_red.png")}
                />
                <span style={{color : 'red',fontSize : 14}}>
                
                Verification OTP is invalid
                </span>
              </div>
              {!resendState && <div className="d-flex justify-content-end" style={{marginTop : 8}}>
              <span style={{fontSize : 14}} >resend otp in <span style={{color : 'red'}}>00:{resendTime}</span></span>
              </div>}
              {resendState && <div className="d-flex justify-content-end" style={{marginTop : 8}}>
              <span style={{fontSize : 14}} onClick={resendOTP}>resend otp?</span>
              </div>}

            </div>
            {/*error*/}
            
          <div className="w-75" style={{marginTop:25}}>
          
          <button className="btn w-100" style={{backgroundColor:'red',color:'white'}} onClick={validateFunc}>
          {verificationLoader && <Spinner borderColor="#ffffff" size="24px"/>}
          {!verificationLoader && `Validate (${verificatioAttempt} Attempts)`}
          
          </button>
          <button className="btn btn-outline-dark w-100 mt-3" onClick={cancelValidation}>Cancel</button>
          </div>
        </div>}
      <div id="forgot-password-recaptcha">
      
      </div>
    </React.Fragment>
  );
}
